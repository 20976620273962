import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BaseCSS } from 'styled-bootstrap-grid';
import SimpleReactLightbox from 'simple-react-lightbox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <SimpleReactLightbox>
          <AppProvider>
            <Routes />

            <GlobalStyle />
            <BaseCSS />
          </AppProvider>
        </SimpleReactLightbox>
      </DndProvider>
    </Router>
  );
};

export default App;
