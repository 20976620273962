import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';

// import { Container } from './styles';
interface StudentRegistrationFormData {
  first_name: string;
  last_name: string;
  email: string;
  cpf: string;
  phone_number: string;
}

const StudentRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: StudentRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('CPF obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('students', {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          cpf: data.cpf,
          phone_number: data.phone_number,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O aluno foi cadastrado com sucesso!',
        });

        history.push('/alunos');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const error = err.response.data.error || '';

        switch (error) {
          case 'student-already-exists':
            addToast({
              title: 'Aluno já existe',
              description:
                'Um aluno já está cadastrado com este e-mail ou CPF.',
              type: 'error',
            });
            break;

          default:
            addToast({
              type: 'error',
              title: 'Erro no cadastro',
              description: 'Ocorreu um erro no cadastro, verifique os campos.',
            });
        }
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar aluno"
          items={[
            { title: 'Alunos', link: '/alunos' },
            { title: 'Cadastrar aluno' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      mask="(99) 9999-99999"
                      maskChar={null}
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar aluno
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default StudentRegistration;
