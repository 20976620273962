import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ArticleBoxProps {
  isPromoted: boolean;
}

export const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ArticleBox = styled(Link)<ArticleBoxProps>`
  text-decoration: none;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  text-align: left;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow};
  border: 2px solid transparent;

  > div {
    font-size: 14px;
    color: ${({ theme }) => theme.title};
    top: 16px;
    right: 16px;
    position: absolute;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }

    ${({ isPromoted }) =>
      isPromoted &&
      css`
        border-color: #f1e9f5;
      `}
  }

  ${({ isPromoted }) =>
    isPromoted &&
    css`
      background: ${({ theme }) => theme.colors.primary};
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    ${({ isPromoted }) =>
      isPromoted &&
      css`
        border-color: #f1e9f5;
      `}
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    font-size: 28px;

    ${({ isPromoted }) =>
      isPromoted &&
      css`
        color: #f1e9f5;
      `}
  }

  h4 {
    margin-top: 8px;
    color: ${({ theme }) => theme.title};
    font-weight: bold;
    font-size: 16px;

    ${({ isPromoted }) =>
      isPromoted &&
      css`
        color: #f1e9f5;
      `}
  }

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.text};

    ${({ isPromoted }) =>
      isPromoted &&
      css`
        color: #f1e9f5;
      `}
  }
`;
