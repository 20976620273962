import styled from 'styled-components';

export const AchievementItem = styled.div`
  display: flex;

  img {
    border-radius: 0px !important;
  }

  span {
    margin-left: 8px;
  }
`;
