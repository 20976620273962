import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { ThemeProvider } from 'styled-components';

import { lightTheme, darkTheme } from '../styles/themes';

interface DarkModeContextState {
  darkMode: number;
  switchDarkMode(): void;
}

const DarkModeContext = createContext<DarkModeContextState>(
  {} as DarkModeContextState,
);

const DarkModeProvider: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useState(0);

  const switchDarkMode = useCallback(() => {
    const newDarkModeMode = darkMode ? 0 : 1;

    localStorage.setItem('@PoxaluluSystem:darkMode', String(newDarkModeMode));

    setDarkMode(newDarkModeMode);
  }, [darkMode]);

  useEffect(() => {
    const newDarkModeMode = Number(
      localStorage.getItem('@PoxaluluSystem:darkMode'),
    );

    setDarkMode(newDarkModeMode);
  }, []);

  return (
    <DarkModeContext.Provider value={{ darkMode, switchDarkMode }}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {children}
      </ThemeProvider>
    </DarkModeContext.Provider>
  );
};

function useDarkMode(): DarkModeContextState {
  const context = useContext(DarkModeContext);

  if (!context) {
    throw new Error('useDarkMode must be user within an DarkModeProvider');
  }

  return context;
}

export { DarkModeProvider, useDarkMode };
