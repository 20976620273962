import styled from 'styled-components';
import { shade } from 'polished';

interface MenuProps {
  active: boolean;
}

export const Container = styled.div<MenuProps>`
  display: inline;

  & + div {
    margin-left: 40px;
  }

  > li {
    display: inline-block;
    position: relative;

    > a,
    > span {
      color: ${({ theme }) => theme.text};
      font-weight: bold;
      font-size: 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 40px 0;
      transition: color 0.2s;
      color: ${props =>
        props.active ? props.theme.colors.primary : props.theme.text};

      svg {
        margin-right: 8px;
      }
    }

    > span {
      cursor: default;
    }
  }
`;

export const SubMenu = styled.div<MenuProps>`
  position: absolute;
  z-index: 9;
  top: 100%;
  left: Calc(50% - 100px);
  width: 200px;
  padding-top: 16px;
  transition: visibility 0.2s, opacity 0.2s;

  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  opacity: ${props => (props.active ? 1 : 0)};

  &::before {
    content: '';
    position: absolute;
    top: Calc(16px - 6px);
    left: Calc(50% - 4px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid ${({ theme }) => theme.backgroundCard};
  }

  &::after {
    content: '';
    border: solid ${({ theme }) => theme.colors.primary};
    border-width: 0 2px 2px 0;
    position: absolute;
    top: -27px;
    left: Calc(50% - 2px);
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
  }

  ul {
    border-radius: 8px;
    padding: 8px 0;
    list-style: none;
    background: ${({ theme }) => theme.backgroundCard};

    li {
      a,
      > button {
        border: none;
        background: none;
        padding: 8px 16px;
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        display: flex;
        width: 100%;
        align-items: center;
        transition: padding 0.2s;

        &:hover {
          background: ${({ theme }) =>
            shade(theme.hoverPercentage, theme.backgroundCard)};
          padding-left: 24px;
        }

        svg {
          margin-right: 8px;
        }

        span {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
`;
