import styled from 'styled-components';
import ReactModal from 'react-modal';

export const Container = styled(ReactModal)`
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.background};
  }

  position: absolute;
  padding: 20px;
  top: 50%;
  left: Calc(50% - 16px);
  max-height: 80vh;
  margin: 0 16px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  border: none;
  overflow-y: auto;
  max-width: 736px;
  width: Calc(100% - 32px);
  background: ${({ theme }) => theme.backgroundCard};
`;

export const ModalHeader = styled.div`
  position: relative;

  > div {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 28.8px;
    color: ${({ theme }) => theme.title};
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.title};
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
