import styled from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

interface LoadingProps {
  isLoading: boolean;
}

export const Container = styled.div<ContainerProps>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  position: fixed;
  background: ${({ theme }) => theme.backdrop};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  transition: all 0.2s;

  display: flex;
  flex-direction: column;

  > header {
    margin-bottom: 40px;
    align-self: flex-end;

    > button {
      background: #fff;
      width: 40px;
      height: 40px;

      margin-top: 30px;
      margin-right: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
    }
  }

  > div {
    position: relative;
    flex: 1;
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    background: ${({ theme }) => theme.colors.primary};
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;

    > div {
      display: flex;

      > div {
        display: flex;
        align-items: center;

        & + div {
          margin-left: 32px;
        }

        > span {
          font-weight: bold;
          color: #ffffff;
          margin-right: 16px;
        }

        input[type='range'] {
          appearance: none;
          width: 100%;
          background: none;

          &::-webkit-slider-runnable-track {
            background: #ffffff;
            height: 8px;
            border-radius: 4px;

            &::after {
              content: '';
              width: 20px;
              height: 20px;
              background: red;
            }
          }

          &::-webkit-slider-thumb {
            margin-top: -4px;
            appearance: none;
            cursor: pointer;
            background: #1d1a1f;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }
      }
    }

    > button {
      margin-left: 56px;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      > div {
        margin-bottom: 16px;
      }

      > button {
        margin-left: 0;
      }
    }

    @media (max-width: 425px) {
      > div {
        display: none;
      }
    }
  }
`;
