import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { subYears } from 'date-fns';
import * as Yup from 'yup';

import { MdEvent } from 'react-icons/md';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';
import formatDate from '../../../utils/formatDate';
import states from '../../../utils/getStates';
import genders from '../../../utils/getGenders';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import DatePicker from '../../../components/Datepicker';
import Select from '../../../components/Select';

import WordingsDatatable from './WordingsDatatable';

import {
  Profile,
  DataBox,
  TransactionItem,
  StatusBadge,
  BreadcrumbsItems,
} from './styles';
import Badge from '../../../components/Badge';
import UserLogs from '../../User/UserPreview/UserLogs';
import UseCan from '../../../utils/UseCan';

interface StudentData {
  created_at: string;
  show_name: string;
  avatar_url: string;
  cover_url: string;
  is_public: string;
  last_privacy_update?: string;
}

interface TransactionData {
  id: string;
  start_date?: string;
  end_date?: string;
  warranty_date?: string;
  amout: string;
  product: {
    id: string;
    name: string;
  };
  status_info: {
    name: string;
    color: string;
    background: string;
  };
}

interface StudentPreviewFormData {
  university_id: string;
  career_id: string;
  first_name: string;
  last_name: string;
  email: string;
  cpf: string;
  password: string;
  gender: string;
  nickname: string;
  phone_number: string;
  birthday: Date;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
}

const StudentPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [studentLoading, setStudentLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);

  const [student, setStudent] = useState<StudentData>({} as StudentData);
  const [transactions, setTransactions] = useState<TransactionData[]>([]);

  const [universities, setUniversities] = useState([]);
  const [careers, setCareers] = useState([]);

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData() {
      try {
        setTransactionsLoading(true);

        const response = await api.get(`/students/${id}/transactions`);

        setTransactions(response.data);

        setTransactionsLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O aluno não foi encontrado!',
        });
      }
    }

    loadData();
  }, [id, addToast]);

  useEffect(() => {
    async function loadData() {
      setStudentLoading(true);

      try {
        const [universitiesResponse, careersResponse] = await Promise.all([
          api.get('/universities/list'),
          api.get('/careers/list'),
        ]);

        setUniversities(universitiesResponse.data);
        setCareers(careersResponse.data);

        const response = await api.get(`/students/${id}`);

        setStudent(response.data);

        formRef.current?.setData({
          university_id: response.data.university_id,
          career_id: response.data.career_id,
          cpf: response.data.cpf,
          cep: response.data.cep,
          state: response.data.state,
        });

        setStudentLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O aluno não foi encontrado!',
        });

        history.push('/alunos');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleSubmit = useCallback(
    async (data: StudentPreviewFormData) => {
      setStudentLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          university_id: Yup.string().nullable(),
          career_id: Yup.string().nullable(),
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('CPF obrigatório'),
          birthday: Yup.date()
            .max(subYears(new Date(), 14), 'Mínimo de 14 anos')
            .nullable(),
          gender: Yup.string().nullable(),
          nickname: Yup.string().nullable(),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          cep: Yup.string(),
          street: Yup.string(),
          number: Yup.string(),
          complement: Yup.string(),
          neighborhood: Yup.string(),
          city: Yup.string(),
          state: Yup.string().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`students/${id}`, {
          university_id: data.university_id,
          career_id: data.career_id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          gender: data.gender,
          nickname: data.nickname,
          phone_number: data.phone_number,
          birthday: data.birthday,
          cpf: data.cpf,
          cep: data.cep,
          street: data.street,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O aluno foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setStudentLoading(false);
      }
    },
    [addToast, id],
  );

  return (
    <>
      <Header cover={student.cover_url} />

      <Container style={{ flex: 1 }}>
        <Row>
          <Col md={4}>
            <Card style={{ marginTop: '-157px' }}>
              {studentLoading && <Loader />}

              <Profile>
                <img src={student.avatar_url} alt={student.show_name} />

                <h2>{student.show_name}</h2>

                <div>
                  {student.is_public ? (
                    <Badge
                      color="success"
                      title="Perfil público"
                      tooltip={
                        student.last_privacy_update &&
                        formatDate(
                          student.last_privacy_update,
                          "dd/MM/yyyy 'às' HH:mm",
                        )
                      }
                    />
                  ) : (
                    <Badge
                      color="error"
                      title="Perfil privado"
                      tooltip={
                        student.last_privacy_update &&
                        formatDate(
                          student.last_privacy_update,
                          "dd/MM/yyyy 'às' HH:mm",
                        )
                      }
                    />
                  )}
                </div>

                <span>
                  Membro desde
                  {student.created_at &&
                    formatDate(student.created_at, " MMMM 'de' yyyy")}
                </span>
              </Profile>
            </Card>

            <Row>
              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Redações enviadas
                  <span>0</span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Aulas assistidas
                  <span>0</span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Respostas no fórum
                  <span>0</span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Chamados abertos
                  <span>0</span>
                </DataBox>
              </Col>
            </Row>
          </Col>

          <Col md={8}>
            <BreadcrumbsItems>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to="/alunos">Alunos</Link>
              </li>
              <li>Visualizar aluno</li>
            </BreadcrumbsItems>

            <Card>
              {studentLoading && <Loader />}
              <h2>Dados pessoais</h2>

              <Form
                ref={formRef}
                initialData={student}
                onSubmit={handleSubmit}
                noValidate
              >
                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="nickname" label="Nickname" />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>

                  <Col md={12}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Select
                      name="university_id"
                      label="Universidade"
                      options={universities}
                    />
                  </Col>

                  <Col md={6}>
                    <Select
                      name="career_id"
                      label="Carreira"
                      options={careers}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Select name="gender" label="Gênero" options={genders} />
                  </Col>

                  <Col md={4}>
                    <DatePicker
                      icon={MdEvent}
                      name="birthday"
                      label="Data de Nascimento"
                      maxDate={subYears(new Date(), 18)}
                    />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      name="phone_number"
                      label="Telefone"
                      mask=""
                      isPhone
                      maskChar={null}
                    />
                  </Col>
                </Row>

                <UseCan roles={['administrator']}>
                  <h2>Endereço</h2>

                  <Row>
                    <Col md={12}>
                      <InputMask
                        type="text"
                        mask="99999-999"
                        maskChar={null}
                        name="cep"
                        label="CEP"
                      />
                    </Col>

                    <Col md={8}>
                      <Input type="text" name="street" label="Logradouro" />
                    </Col>

                    <Col md={4}>
                      <Input type="text" name="number" label="Número" />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="complement"
                        label="Complemento"
                      />
                    </Col>

                    <Col md={6}>
                      <Input type="text" name="neighborhood" label="Bairro" />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Input type="text" name="city" label="Cidade" />
                    </Col>

                    <Col md={6}>
                      <Select label="Estado" name="state" options={states} />
                    </Col>
                  </Row>
                </UseCan>

                <UseCan roles={['administrator', 'coordinator']}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ marginTop: '16px' }}
                  >
                    Atualizar aluno
                  </Button>
                </UseCan>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              {transactionsLoading && <Loader />}
              <h2>Transações</h2>

              {!transactions.length && <p>Nenhuma transação encontrada</p>}

              {transactions.map(transaction => (
                <TransactionItem key={transaction.id}>
                  <div>
                    <small>Plano</small>
                    <span>
                      <Link to={`/planos/visualizar/${transaction.product.id}`}>
                        {transaction.product.name}
                      </Link>
                    </span>
                  </div>

                  <div>
                    <small>Data</small>
                    <span>
                      {transaction.start_date &&
                        formatDate(transaction.start_date, 'dd/MM/yyyy')}
                    </span>
                  </div>

                  <div>
                    <small>Validade</small>
                    <span>
                      {transaction.end_date &&
                        formatDate(transaction.end_date, 'dd/MM/yyyy')}
                    </span>
                  </div>

                  <div>
                    <small>Garantia</small>
                    <span>
                      {transaction.warranty_date &&
                        formatDate(transaction.warranty_date, 'dd/MM/yyyy')}
                    </span>
                  </div>

                  <StatusBadge
                    background={transaction.status_info.background}
                    color={transaction.status_info.color}
                  >
                    {transaction.status_info.name}
                  </StatusBadge>
                </TransactionItem>
              ))}
            </Card>

            <UseCan
              roles={[
                'administrator',
                'coordinator',
                'corrector',
                'super-corrector',
                'hyper-corrector',
              ]}
            >
              <WordingsDatatable student_id={id} />
            </UseCan>
          </Col>

          <UseCan roles={['administrator']}>
            <Col md={12}>
              <Card>
                <UserLogs user_id={id} />
              </Card>
            </Col>
          </UseCan>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default StudentPreview;
