import React, { useRef, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';
import { AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';

import { MdErrorOutline } from 'react-icons/md';
import { Container, ReactSelectElement, Error } from './styles';

export interface Option {
  label: string;
  value: string;
}

interface Props extends AsyncProps<OptionTypeBase> {
  name: string;
  label: string;
  isMulti?: boolean;
  isClearable?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
}

const AsyncSelect: React.FC<Props> = ({
  name,
  label,
  isMulti = false,
  isClearable = false,
  icon: Icon,
  loadOptions,
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (isMulti) {
          if (!ref.select.state.value) {
            return [];
          }

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }

        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, isMulti]);

  return (
    <Container hasError={!!error}>
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          <ReactSelectElement
            ref={selectRef}
            defaultValue={defaultValue}
            cacheOptions
            defaultOptions
            placeholder="Selecione..."
            classNamePrefix="react-select"
            noOptionsMessage={() => 'Nenhuma opção encontrada'}
            loadOptions={loadOptions}
            loadingMessage={() => 'Carregando...'}
            isMulti={isMulti}
            isClearable={isClearable}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};
export default AsyncSelect;
