import React, { useRef, useState, useEffect } from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import { MdFileDownload } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useMark } from '../../../hooks/mark-photo';

import CorrectionGrades from '../../../containers/CorrectionGrades';
import WordingPhotoContent from '../../../containers/WordingPhotoContent';

import Loader from '../../../components/Loader';
import TextArea from '../../../components/TextArea';

import { Container, WordingTitle } from './styles';
import Card from '../../../components/Card';

interface LastCorrectionPhotoContentProps {
  wording_id: string;
  corrected_in?: number;
  photo_url: string;
  theme?: {
    name: string;
    material_url: string;
    exam_name: string;
  };
  grades: {
    competence_1: number;
    competence_2: number;
    competence_3: number;
    competence_4: number;
    competence_5: number;
  };
  note?: string;
  initialMarks: any[];
  active?: boolean;
  marginTop?: number;
}

const LastCorrectionPhotoContent: React.FC<LastCorrectionPhotoContentProps> = ({
  theme,
  initialMarks,
  note,
  grades,
  photo_url,
  active = false,
  marginTop = 106,
}) => {
  const { setInitialMarks } = useMark();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInitialMarks(initialMarks);
  }, [initialMarks, setInitialMarks]);

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    formRef.current.setData({ note, ...grades });
  }, [grades, note]);

  return (
    <Container active={active} marginTop={marginTop}>
      {loading && <Loader isFixed />}

      <Row>
        <Col md={12}>
          <Card>
            <WordingTitle>
              <div>
                <h2>{theme?.name}</h2>
                <ul>
                  <li>
                    <a target="blank" href={theme?.material_url}>
                      <MdFileDownload size={16} />
                      Material de apoio
                    </a>
                  </li>
                </ul>
              </div>
            </WordingTitle>

            <WordingPhotoContent photo_url={photo_url} readOnly />
          </Card>
        </Col>

        <Col md={12}>
          <Form ref={formRef} onSubmit={() => false} noValidate>
            <CorrectionGrades disabled />

            <TextArea
              name="note"
              label="Comentários"
              required
              placeholder="Insira um breve comentário final sobre a redação e o que pode ser melhorado."
              disabled
              rows={8}
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LastCorrectionPhotoContent;
