import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import {
  MdAttachMoney,
  MdCheck,
  MdClear,
  MdContentCopy,
  MdPeopleOutline,
} from 'react-icons/md';
import ReactDatePicker from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { endOfMonth, startOfMonth } from 'date-fns';

import api from '../../../services/api';

import formatDate from '../../../utils/formatDate';
import formatPrice from '../../../utils/formatPrice';

import Datatable from '../../../components/Datatable';
import Card from '../../../components/Card';
import Button from '../../../components/Button';

import Footer from '../../../containers/Footer';
import Header from '../../../containers/Header';

import { useAuth } from '../../../hooks/auth';
import {
  CardTitle,
  Welcome,
  WelcomeMessage,
} from '../CorrectorDashboard/styles';

import {
  AffiliateLinks,
  Filter,
  FooterCardContainer,
  HeaderCardContainer,
} from './styles';
import BoxInfo from '../../../components/BoxInfo';
import { StatusBadge } from '../../Transaction/styles';
import { useToast } from '../../../hooks/toast';
import UseCan from '../../../utils/UseCan';

interface Status {
  name: string;
  background: string;
  color: string;
}

interface Student {
  show_name: string;
  phone_number: string;
}

interface TransactionData {
  created_at: string;
  approved_at: string | null;
  payment_ext: string;
  student: Student;
  price: number;
  comission_percentage: number | null;
  comission_amount: number | null;
  status_info: Status;
}

interface columnData {
  label: string;
  field:
    | 'created_at'
    | 'approved_at'
    | 'payment_ext'
    | 'student'
    | 'price'
    | 'comission_percentage'
    | 'comission_amount'
    | 'status_info';
  transform?(
    value: string | null | Student | number | Status,
    row?: TransactionData,
  ): string | React.ReactElement;
}

const AffiliateDashboard: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [transactionsLoading, setTransactionsLoading] = useState(false);

  const [filterDate, setFilterDate] = useState(new Date());

  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [canceledSales, setCanceledSales] = useState(0);
  const [paidSales, setPaidSales] = useState(0);
  const [balance, setBalance] = useState(0);
  const [pendingBalance, setPendingBalance] = useState(0);

  const [data, setData] = useState<TransactionData[]>([]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, 'dd/MM/yy HH:mm:ss');
        },
      },
      {
        label: 'Venda',
        field: 'approved_at',
        transform: (approved_at: string | null) => {
          if (approved_at) {
            return formatDate(approved_at, 'dd/MM/yy HH:mm:ss');
          }

          return <span style={{ color: 'red' }}>Não concretizado</span>;
        },
      },
      {
        label: 'Transação',
        field: 'payment_ext',
      },
      {
        label: 'Aluno',
        field: 'student',
        transform: (student: Student) => {
          return student.show_name;
        },
      },
      {
        label: 'Telefone',
        field: 'student',
        transform: (student: Student) => {
          return student.phone_number;
        },
      },
      {
        label: 'valor da venda',
        field: 'price',
        transform: (comission_amount: number) => {
          return formatPrice(comission_amount / 100);
        },
      },
      {
        label: 'Comissão (%)',
        field: 'comission_percentage',
        transform: (comission_amount: number | null) => {
          if (user.role === 'seller') {
            return '?';
          }

          if (comission_amount) {
            return `${comission_amount / 100}%`;
          }

          return '-';
        },
      },
      {
        label: 'Comissão (R$)',
        field: 'comission_amount',
        transform: (comission_amount: number | null) => {
          if (user.role === 'seller') {
            return '?';
          }

          if (comission_amount) {
            return formatPrice(comission_amount / 100);
          }

          return '-';
        },
      },
      {
        label: 'Status',
        field: 'status_info',
        transform: (status: Status) => {
          return (
            <StatusBadge background={status.background} color={status.color}>
              {status.name}
            </StatusBadge>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setTransactionsLoading(true);

        const response = await api.get('/affiliates/statistics', {
          params: {
            start_date: startOfMonth(filterDate),
            end_date: endOfMonth(filterDate),
          },
        });

        setCanceledSales(response.data.canceledSales);
        setPaidSales(response.data.paidSales);
        setBalance(response.data.balance);
        setPendingBalance(response.data.pendingBalance);
      } catch (error) {
        // console.log(error);
      } finally {
        setTransactionsLoading(false);
      }
    }

    loadData();
  }, [filterDate]);

  useEffect(() => {
    async function loadData() {
      try {
        setTransactionsLoading(true);

        const response = await api.get('/affiliates', {
          params: {
            pageIndex,
            start_date: startOfMonth(filterDate),
            end_date: endOfMonth(filterDate),
          },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setTransactionsLoading(false);
      }
    }

    loadData();
  }, [pageIndex, filterDate]);

  const handleCopy = useCallback(
    (text: string) => {
      const permissionName = 'clipboard-write' as PermissionName;

      navigator.permissions
        .query({ name: permissionName })
        .then(({ state }) => {
          if (state === 'granted' || state === 'prompt') {
            navigator.clipboard.writeText(text).then(
              () => {
                addToast({
                  title: 'Sucesso!',
                  description: 'O cupom foi copiado com sucesso.',
                  type: 'success',
                });
              },
              () => {
                addToast({
                  title: 'Erro!',
                  description: 'Ocorreu um erro ao copiar o cupom.',
                  type: 'error',
                });
              },
            );
          }
        });
    },
    [addToast],
  );

  return (
    <>
      <Header />

      <Container>
        <Welcome>
          <div>
            <h1>Dashboard</h1>
            <WelcomeMessage>
              {`Seja bem-vindo, ${user.show_name}!`}
              <span
                role="img"
                aria-label="Coração roxo"
                style={{ marginLeft: '4px' }}
              >
                💜
              </span>
            </WelcomeMessage>
          </div>
        </Welcome>

        <AffiliateLinks>
          <Card style={{ display: 'flex', flexDirection: 'column' }}>
            <HeaderCardContainer>
              <header>Plano 3 meses</header>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  handleCopy(
                    `https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/3`,
                  );
                }}
              >
                <MdContentCopy />
                Copiar link
              </Button>
            </HeaderCardContainer>
            <FooterCardContainer>
              <input
                type="text"
                value={`https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/3`}
              />
            </FooterCardContainer>
          </Card>

          <Card style={{ display: 'flex', flexDirection: 'column' }}>
            <HeaderCardContainer>
              <header>Plano 6 meses</header>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  handleCopy(
                    `https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/6`,
                  );
                }}
              >
                <MdContentCopy />
                Copiar link
              </Button>
            </HeaderCardContainer>
            <FooterCardContainer>
              <input
                type="text"
                value={`https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/6`}
              />
            </FooterCardContainer>
          </Card>

          <Card style={{ display: 'flex', flexDirection: 'column' }}>
            <HeaderCardContainer>
              <header>Plano 12 meses</header>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  handleCopy(
                    `https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/12`,
                  );
                }}
              >
                <MdContentCopy />
                Copiar link
              </Button>
            </HeaderCardContainer>
            <FooterCardContainer>
              <input
                type="text"
                value={`https://checkout.poxalulu.com.br/cupom/${user.affiliate_coupon}/12`}
              />
            </FooterCardContainer>
          </Card>
        </AffiliateLinks>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <h2 style={{ flex: 1 }}>Estatísticas</h2>

          <Filter>
            <div>
              <ReactDatePicker
                name="filterDate"
                selected={filterDate}
                onChange={(date: Date) => setFilterDate(date)}
                dateFormat="LLLL 'de' yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                locale={ptBR}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
          </Filter>
        </div>

        <Row style={{ justifyContent: 'center' }}>
          <Col md={3}>
            <BoxInfo
              loading={false}
              title="Oportunidades"
              value={totalRecords}
              icon={MdPeopleOutline}
            />
          </Col>

          <Col md={3}>
            <BoxInfo
              loading={false}
              title="Vendas concretizadas"
              value={paidSales}
              icon={MdCheck}
            />
          </Col>

          <Col md={3}>
            <BoxInfo
              loading={false}
              title="Devoluções"
              value={canceledSales}
              icon={MdClear}
            />
          </Col>

          <UseCan roles={['affiliate']}>
            <Col md={3}>
              <BoxInfo
                loading={false}
                title="Saldo"
                subtitle={formatPrice(pendingBalance / 100)}
                value={balance / 100}
                decimals={2}
                prefix="R$"
                icon={MdAttachMoney}
              />
            </Col>
          </UseCan>
        </Row>

        <Row>
          <Col lg={12}>
            <CardTitle>Minhas vendas</CardTitle>

            <Card>
              <Datatable
                loading={transactionsLoading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
              >
                {data.map(row => (
                  <tr key={row.payment_ext}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhuma venda encontrada</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default AffiliateDashboard;
