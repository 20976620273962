import React from 'react';

import { Container } from './styles';

interface BadgeProps {
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'error';
  title: string;
  tooltip?: string;
}

const Badge: React.FC<BadgeProps> = ({ title, color, tooltip }) => {
  return (
    <Container color={color}>
      {tooltip && <div>{tooltip}</div>}
      {title}
    </Container>
  );
};

export default Badge;
