import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { differenceInDays } from 'date-fns';
import { MdEvent } from 'react-icons/md';
import { ptBR } from 'date-fns/locale';
import ReactDatePicker from 'react-datepicker';

import { Link } from 'react-router-dom';
import Breadcrumbs from '../../containers/Breadcrumbs';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

import Card from '../../components/Card';
import Chart, { Series } from '../../components/Chart';

import { Table, FilterDate } from './styles';
import api from '../../services/api';
import formatPrice from '../../utils/formatPrice';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  price_per_wording: number;
  total_price: number;
  corrections_per_day: number;
  corrections: number;
  average_grade: number;
  average_rating: number;
}

const CorrectionStatistics: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [categories, setCategories] = useState<string[]>([]);
  const [series, setSeries] = useState<Series[]>([]);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get('/corrections/statistics/day', {
          params: {
            days: 15,
          },
        });

        setSeries(response.data.series);
        setCategories(response.data.categories);
      } catch (err) {
        console.log(err);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        if (!startDate || !endDate) {
          return;
        }

        const response = await api.get('/corrections/users', {
          params: {
            from: startDate,
            to: endDate,
          },
        });

        const days = differenceInDays(endDate, startDate) + 1;

        setUsers(
          response.data.map((d: any) => ({
            ...d,
            total_price: (d.corrections * d.price_per_wording) / 100,
            corrections_per_day: d.corrections / days,
          })),
        );
      } catch (err) {
        console.log(err);
      }
    }

    loadData();
  }, [startDate, endDate]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Correções" items={[{ title: 'Correções' }]} />

        <Row>
          <Col md={12}>
            <Card>
              <Chart
                id="corrections"
                categories={categories}
                colors={['#6b5be2', '#e3175b']}
                series={series}
                type="line"
                height="300"
              />

              <FilterDate>
                <div>
                  De
                  <div>
                    <MdEvent />
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date: Date | null) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      locale={ptBR}
                    />
                  </div>
                </div>

                <div>
                  Até
                  <div>
                    <MdEvent />
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date: Date | null) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      locale={ptBR}
                    />
                  </div>
                </div>
              </FilterDate>

              <Table>
                <li>
                  <span>Nome</span>
                  <span>Correções</span>
                  <span>Valor</span>
                  <span>Correções/dia</span>
                  <span>Nota média</span>
                  <span>Avaliação</span>
                </li>
                {users.length === 0 && (
                  <p>Nenhum registro encontrado nesses critérios 🥲</p>
                )}
                {users.map(user => (
                  <li>
                    <span>
                      <Link
                        to={`/usuarios/visualizar/${user.id}`}
                        target="blank"
                      >
                        {`${user.first_name} ${user.last_name.split(' ')[0]}`}
                      </Link>
                    </span>
                    <span>{user.corrections}</span>
                    <span>{formatPrice(user.total_price)}</span>
                    <span>{Number(user.corrections_per_day).toFixed(1)}</span>
                    <span>{Number(user.average_grade).toFixed(1)}</span>
                    <span>
                      {Number((user.average_rating / 10) * 2).toFixed(1) || 0}
                    </span>
                  </li>
                ))}
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default CorrectionStatistics;
