import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Chart, { Series } from '../../../components/Chart';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';
import Loader from '../../../components/Loader';

const StatisticsChart: React.FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<string[]>([]);
  const [series, setSeries] = useState<Series[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        if (!user.id) {
          return;
        }

        setLoading(true);

        const response = await api.get(`/corrections/statistics/${user.id}`);

        const responseSeries = response.data.corrections.map(
          (correction: any) => Number(correction.value),
        );
        const responseCategories = response.data.corrections.map(
          (correction: any) => format(new Date(correction.month), 'MM/yyyy'),
        );

        setSeries([{ data: responseSeries, name: 'Correções' }]);
        setCategories(responseCategories);
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  return (
    <div>
      {loading && <Loader />}

      <Chart
        id="corrections"
        categories={categories}
        colors={['#e3175b']}
        series={series}
        type="line"
        height="300"
      />
    </div>
  );
};

export default StatisticsChart;
