import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};
  margin-bottom: 40px;

  h2 {
    font-size: 24px;
    margin-bottom: 1.2em;
    color: ${({ theme }) => theme.title};
  }
`;
