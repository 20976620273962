import styled from 'styled-components';
import { shade } from 'polished';

interface MobileMenuProps {
  active: boolean;
}

export const Container = styled.div<MobileMenuProps>`
  padding: 8px 0;

  > li {
    > a,
    > span {
      cursor: pointer;
      line-height: 54px;
      padding: 0 16px;
      color: ${({ theme }) => theme.text};
      background: ${props =>
        props.active &&
        shade(props.theme.hoverPercentage, props.theme.backgroundCard)};
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      transition: color 0.2s;
      color: ${props =>
        props.active ? props.theme.colors.primary : props.theme.text};

      svg {
        margin-right: 8px;
      }
    }

    > span {
      &::after {
        content: '';
        position: absolute;
        right: 16px;
        border: solid
          ${props =>
            props.active ? props.theme.colors.primary : props.theme.text};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transition: transform 0.2s;
        transform: ${props =>
          props.active ? 'rotate(45deg)' : 'rotate(-135deg)'};
      }
    }
  }
`;

export const SubMenu = styled.div<MobileMenuProps>`
  display: ${props => (props.active ? 'block' : 'none')};

  ul {
    list-style: none;
    background: ${({ theme }) => theme.backgroundCard};

    li {
      a,
      > button {
        border: none;
        background: none;
        padding: 8px 32px;
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        display: flex;
        width: 100%;
        align-items: center;

        svg {
          margin-right: 8px;
        }

        span {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
`;
