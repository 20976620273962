import React, { useState, useEffect } from 'react';
import { IconBaseProps } from 'react-icons';
import { Container } from 'styled-bootstrap-grid';
import { MdMenu, MdDashboard } from 'react-icons/md';

import { useAuth } from '../../../hooks/auth';

import MenuItem from './MenuItem';
import MobileMenuItem from './MobileMenuItem';

import { Background, MobileButton, MobileMenu } from './styles';

interface MenuProps {
  hasMargin?: boolean;
}

interface MenuItemProps {
  title: string;
  link?: string;
  icon: React.ComponentType<IconBaseProps>;
  subitems?: Array<{
    title: string;
    link: string;
    roles?: string[];
  }>;
  roles: string[];
}

const Menu: React.FC<MenuProps> = ({ hasMargin = true }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () =>
        setWindowWidth(window.innerWidth),
      );
    };
  }, []);

  const menuItems: MenuItemProps[] = [
    {
      title: 'Dashboard',
      link: '/',
      icon: MdDashboard,
      roles: [],
    },
    {
      title: 'Administrativo',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Alunos',
          link: '/alunos',
        },
        {
          title: 'Afiliados',
          link: '/afiliados',
        },
        {
          title: 'Correções',
          link: '/correcoes/estatisticas',
        },
        {
          title: 'Usuários',
          link: '/usuarios',
        },
      ],
      roles: ['administrator', 'coordinator'],
    },
    {
      title: 'Plataforma',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Créditos',
          link: '/creditos',
        },
        {
          title: 'Cursos',
          link: '/cursos',
        },
        {
          title: 'Eventos',
          link: '/eventos',
        },
        {
          title: 'Materiais',
          link: '/materiais',
        },
        {
          title: 'Redações',
          link: '/redacoes',
        },
        {
          title: 'Temas',
          link: '/temas',
        },
      ],
      roles: ['administrator', 'coordinator'],
    },
    {
      title: 'Comunidade',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Fóruns',
          link: '/foruns',
        },
        {
          title: 'Denúncias',
          link: '/denuncias',
        },
      ],
      roles: ['administrator', 'coordinator'],
    },
    {
      title: 'Suporte',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Artigos e Seções',
          link: '/secoes',
        },
        {
          title: 'Chamados',
          link: '/chamados',
        },
      ],
      roles: ['administrator', 'coordinator'],
    },
    {
      title: 'Financeiro',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Cupons',
          link: '/cupons',
          roles: ['administrator'],
        },
        {
          title: 'Parceiros',
          link: '/parceiros',
          roles: ['administrator'],
        },
        {
          title: 'Planos',
          link: '/planos',
          roles: ['administrator'],
        },
        {
          title: 'Vendas',
          link: '/vendas',
          roles: ['administrator', 'seller'],
        },
      ],
      roles: ['administrator', 'seller'],
    },
    {
      title: 'Configurações',
      icon: MdDashboard,
      subitems: [
        {
          title: 'Carreiras',
          link: '/carreiras',
        },
        {
          title: 'Conquistas',
          link: '/conquistas',
        },
        {
          title: 'Exames',
          link: '/exames',
        },
        {
          title: 'Marcações',
          link: '/marcacoes',
        },
        {
          title: 'Universidades',
          link: '/universidades',
        },
      ],
      roles: ['administrator'],
    },
  ];

  return (
    <Background active={active} hasMargin={hasMargin}>
      <Container>
        {windowWidth > 991 ? (
          <ul>
            {menuItems.map(item => {
              if (item.roles.length > 0 && !item.roles.includes(user.role)) {
                return null;
              }

              return (
                <MenuItem
                  key={item.title}
                  icon={item.icon}
                  title={item.title}
                  subitems={item.subitems}
                  link={item.link}
                />
              );
            })}
          </ul>
        ) : (
          <>
            <MobileButton type="button" onClick={() => setActive(!active)}>
              <MdMenu size={24} />
            </MobileButton>

            <MobileMenu active={active}>
              <ul>
                {menuItems.map(item => {
                  if (
                    item.roles.length > 0 &&
                    !item.roles.includes(user.role)
                  ) {
                    return null;
                  }

                  return (
                    <MobileMenuItem
                      key={item.title}
                      icon={item.icon}
                      title={item.title}
                      subitems={item.subitems}
                      link={item.link}
                    />
                  );
                })}
              </ul>
            </MobileMenu>
          </>
        )}
      </Container>
    </Background>
  );
};

export default Menu;
