import React, { createContext, useContext, useMemo } from 'react';
import { io } from 'socket.io-client';
import formatDate from '../utils/formatDate';
import { useAuth } from './auth';
import { useToast } from './toast';

interface RegisterProps {
  event: string;
  listener(props: any): any;
}

interface SocketContextState {
  register(props: RegisterProps): void;
}

const SocketContext = createContext<SocketContextState>(
  {} as SocketContextState,
);

const SocketProvider: React.FC = ({ children }) => {
  const { user, logout } = useAuth();
  const { addToast } = useToast();

  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_API_URL || 'http://localhost:3333', {
        query: { user_id: user.id },
      }),
    [user.id],
  );

  const register = ({ event, listener }: RegisterProps): void => {
    socket.on(event, listener);
  };

  register({
    event: 'simultaneous-login',
    listener: (login: any) => {
      const refreshToken = localStorage.getItem('@PoxaluluSystem:refreshToken');

      if (!refreshToken) {
        return;
      }

      if (refreshToken !== login.refreshToken) {
        const date = formatDate(login.date, "dd/MM/yyyy 'às' HH:mm");

        addToast({
          title: 'Alguém logou na sua conta!',
          description: `O login ocorreu às ${date} e, por isso, você foi deslogado.`,
          type: 'info',
        });

        logout();
      }
    },
  });

  return (
    <SocketContext.Provider value={{ register }}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): SocketContextState {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be user within an SocketProvider');
  }

  return context;
}

export { SocketProvider, useSocket };
