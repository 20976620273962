import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';

interface GradesContextState {
  finalGrade: number;
  setCompetenceGrade(competence: string, value: number): void;
}

interface GradesProviderProps {
  initialFinalGrade?: number;
  initialGrades?: {
    competence_1: number;
    competence_2: number;
    competence_3: number;
    competence_4: number;
    competence_5: number;
  };
}

const GradesContext = createContext<GradesContextState>(
  {} as GradesContextState,
);

const GradesProvider: React.FC<GradesProviderProps> = ({
  children,
  initialFinalGrade,
  initialGrades,
}) => {
  const [finalGrade, setFinalGrade] = useState(0);
  const [grades, setGrades] = useState({
    competence_1: 0,
    competence_2: 0,
    competence_3: 0,
    competence_4: 0,
    competence_5: 0,
  });

  const setCompetenceGrade = useCallback(
    (competence: string, value: number) => {
      setGrades(oldGrades => ({ ...oldGrades, [competence]: value }));
    },
    [],
  );

  useEffect(() => {
    initialGrades && setGrades(initialGrades);
    initialFinalGrade && setFinalGrade(initialFinalGrade);
  }, [initialGrades, initialFinalGrade]);

  useEffect(() => {
    const sumOfGrades = Object.values(grades).reduce((a, b) => a + b);

    setFinalGrade(sumOfGrades);
  }, [grades]);

  return (
    <GradesContext.Provider value={{ finalGrade, setCompetenceGrade }}>
      {children}
    </GradesContext.Provider>
  );
};

function useGrades(): GradesContextState {
  const context = useContext(GradesContext);

  if (!context) {
    throw new Error('useGrades must be user within an GradesProvider');
  }

  return context;
}

export { GradesProvider, useGrades };
