import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { MdOndemandVideo } from 'react-icons/md';

import Profile from './Profile';
import Notifications from './Notifications';
import Menu from './Menu';

import { Background, Cover } from './styles';

import Logo from '../../components/Logo';
import UseCan from '../../utils/UseCan';

interface HeaderProps {
  showMenu?: boolean;
  cover?: string;
}

const Header: React.FC<HeaderProps> = ({ showMenu = true, cover }) => {
  return (
    <>
      <Background>
        <Container>
          <Logo />

          <UseCan
            roles={[
              'administrator',
              'coordinator',
              'corrector',
              'super-corrector',
              'hyper-corrector',
            ]}
          >
            <a
              href="https://poxalulu-api.s3.sa-east-1.amazonaws.com/manual-dos-corretores.pdf"
              target="blank"
            >
              <MdOndemandVideo size={20} />
              Manual do corretor
            </a>
          </UseCan>

          <Notifications />

          <Profile />
        </Container>
      </Background>

      {showMenu && <Menu hasMargin={!cover} />}

      {cover && <Cover url={cover} />}
    </>
  );
};

export default Header;
