import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';
import Modal, { ModalHeader } from '../../components/Modal';
import Loader from '../../components/Loader';
import ModalConfirm from '../../components/Modal/Confirm';
import Input from '../../components/Input';

// import { Container } from './styles';

interface MarkData {
  id: string;
  name: string;
  competence: number;
  active: boolean;
  expire_at: string;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'name' | 'competence' | 'active' | 'expire_at' | 'created_at';
  transform?(
    value: string | boolean | number,
    row: MarkData,
  ): string | React.ReactElement;
}

interface MarkRegistrationFormData {
  name: string;
  competence: string;
}

interface MarkUpdationFormData {
  name: string;
  competence: string;
}

interface SelectedMarkData {
  id: string;
  name: string;
  competence: number;
}

const Mark: React.FC = () => {
  const { addToast } = useToast();

  const formAddMarkRef = useRef<FormHandles>(null);
  const formEditMarkRef = useRef<FormHandles>(null);

  const [selectedMark, setSelectedMark] = useState<SelectedMarkData>(
    {} as SelectedMarkData,
  );

  const [loading, setLoading] = useState(false);
  const [loadingAddMark, setLoadingAddMark] = useState(false);
  const [loadingDelMark, setLoadingDelMark] = useState(false);
  const [loadingEditMark, setLoadingEditMark] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [modalAddMark, setModalAddMark] = useState(false);
  const [modalDelMark, setModalDelMark] = useState(false);
  const [modalEditMark, setModalEditMark] = useState(false);

  const [data, setData] = useState<MarkData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/marks', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (!modalAddMark && !modalDelMark && !modalEditMark) {
      loadData();
    }
  }, [pageIndex, search, modalAddMark, modalDelMark, modalEditMark]);

  const handleAddMark = useCallback(
    async (formData: MarkRegistrationFormData) => {
      setLoadingAddMark(true);

      formAddMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          competence: Yup.number()
            .oneOf([1, 2, 3, 4, 5], 'A competência precisa estar entre 1 e 5')
            .required('Competência obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.post('marks', {
          name: formData.name,
          competence: formData.competence,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O marcação foi cadastrada com sucesso!',
        });

        setModalAddMark(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingAddMark(false);
      }
    },
    [addToast],
  );

  const handleEditMark = useCallback(
    async (formData: MarkUpdationFormData) => {
      setLoadingEditMark(true);

      formEditMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          competence: Yup.number()
            .oneOf([1, 2, 3, 4, 5], 'A competência precisa estar entre 1 e 5')
            .required('Competência obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`marks/${selectedMark.id}`, {
          name: formData.name,
          competence: formData.competence,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O marcação foi atualizado com sucesso!',
        });

        setModalEditMark(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingEditMark(false);
      }
    },
    [addToast, selectedMark.id],
  );

  const handleDelMark = useCallback(async () => {
    setLoadingDelMark(true);

    try {
      await api.delete(`/marks/${selectedMark.id}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O marcação foi excluído com sucesso!',
      });

      setModalDelMark(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingDelMark(false);
    }
  }, [addToast, selectedMark.id]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Competência',
        field: 'competence',
      },
      {
        label: 'Status',
        field: 'active',
        transform: (used: boolean) => {
          return used ? 'Ativo' : 'Inativo';
        },
      },
      {
        label: '',
        field: 'id',
        transform: (id: string, row) => {
          const { name, competence } = row;

          return (
            <DropdownButton>
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedMark({
                        id,
                        name,
                        competence,
                      });
                      setModalEditMark(true);
                    }}
                  >
                    <MdKeyboardArrowRight size={18} />
                    Alterar
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedMark({
                        id,
                        name,
                        competence,
                      });
                      setModalDelMark(true);
                    }}
                  >
                    <MdKeyboardArrowRight size={18} />
                    Excluir
                  </button>
                </li>
              </ul>
            </DropdownButton>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Marcações" items={[{ title: 'Marcações' }]}>
          <Button color="secondary" onClick={() => setModalAddMark(true)}>
            Cadastrar marcação
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddMark}
        setIsOpen={() => setModalAddMark(!modalAddMark)}
      >
        {loadingAddMark && <Loader />}
        <ModalHeader>
          <div>Cadastrar marcação</div>

          <button type="button" onClick={() => setModalAddMark(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddMarkRef} onSubmit={handleAddMark} noValidate>
          <Input type="text" label="Nome" name="name" />

          <Input
            type="number"
            label="Competência"
            name="competence"
            min={1}
            max={5}
          />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar marcação
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditMark}
        setIsOpen={() => setModalEditMark(!modalEditMark)}
      >
        {loadingEditMark && <Loader />}
        <ModalHeader>
          <div>Atualizar marcação</div>

          <button type="button" onClick={() => setModalEditMark(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formEditMarkRef}
          initialData={selectedMark}
          onSubmit={handleEditMark}
          noValidate
        >
          <Input type="text" label="Nome" name="name" />

          <Input
            type="number"
            label="Competência"
            name="competence"
            min={1}
            max={5}
          />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar marcação
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir marcação"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir a marcação? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelMark}
        isOpen={modalDelMark}
        isLoading={loadingDelMark}
        setIsOpen={() => setModalDelMark(!modalDelMark)}
      />
    </>
  );
};

export default Mark;
