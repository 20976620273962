import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdLock, MdArrowBack } from 'react-icons/md';
import * as Yup from 'yup';

import api from '../../services/api';

import logo from '../../assets/logo-poxalulu.svg';

import { Container, Content } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

interface ResetFormData {
  password: string;
  password_confirmation: string;
}

const Reset: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tokenParam = urlParams.get('token');

    if (!tokenParam) {
      history.push('/');
      return;
    }

    setToken(tokenParam);
  }, [location.search, history]);

  const handleSubmit = useCallback(
    async (data: ResetFormData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Senha obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const { password, password_confirmation } = data;

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Sua senha foi alterada com sucesso.',
        });

        history.push('/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const error = err.response.data.error || '';

        switch (error) {
          case 'token-not-found':
            addToast({
              title: 'Token inválido',
              description:
                'O token informado é inválido. Por favor, gere um novo token e tente novamente.',
              type: 'error',
            });
            break;

          case 'token-expired':
            addToast({
              title: 'Token expirado',
              description:
                'O token informado expirou. Por favor, gere um novo token e tente novamente.',
              type: 'error',
            });
            break;

          default:
            addToast({
              title: 'Erro ao resetar senha',
              description:
                'Ocorreu um erro ao resetar sua senha. Por favor, tente novamente mais tarde.',
              type: 'error',
            });
        }
      }
    },
    [addToast, history, token],
  );

  return (
    <Container>
      <img src={logo} alt="Logo Poxalulu" />

      <Content>
        <h1>Resetar senha</h1>

        <Form ref={formRef} onSubmit={handleSubmit} noValidate>
          <Input
            icon={MdLock}
            type="password"
            name="password"
            label="Nova senha"
            placeholder="Nova senha"
          />

          <Input
            icon={MdLock}
            type="password"
            name="password_confirmation"
            label="Confirmação da senha"
            placeholder="Confirmação da senha"
          />

          <Button type="submit" color="primary" loading={loading}>
            Alterar senha
          </Button>
        </Form>

        <Link to="/">
          <MdArrowBack size={16} />
          Voltar para o login
        </Link>
      </Content>
    </Container>
  );
};

export default Reset;
