import styled, { css } from 'styled-components';

interface PendingWordingsProps {
  color: 'success' | 'danger' | 'warning';
}

export const PendingWordings = styled.span<PendingWordingsProps>`
  color: ${({ color, theme }) => theme.colors[color]};
`;

export const CardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.title};
`;

export const Welcome = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1;

    > h3 {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.title};
    }

    > span {
      font-weight: bold;
    }
  }

  > div:first-child {
    flex: 1.5;
  }

  h1 {
    font-size: 32px;
    line-height: 1.2;
    color: ${({ theme }) => theme.title};
  }

  @media (max-width: 976px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const WelcomeMessage = styled.div`
  @media (max-width: 991px) {
    & {
      margin-bottom: 16px;
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.text};

  > div {
    max-width: 111px;
    border-radius: 4px;
    border: ${({ theme }) => theme.input.border};
    background: ${({ theme }) => theme.input.background};
    width: 100%;
    margin-left: 16px;

    &:hover,
    &:focus-within {
      border-color: ${({ theme }) => theme.input.border};
    }

    input {
      padding: 8px 12px;
      text-align: left;
      flex: 1;
      width: 100%;
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.input.text};

      &::placeholder {
        color: ${({ theme }) => theme.input.placeholder};
      }

      &:disabled {
        cursor: not-allowed;
        color: ${({ theme }) => `${theme.input.text}60`};
      }
    }
  }

  .react-datepicker-popper {
    top: 21px !important;
    left: -70px !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;
