import React, { useState, useRef, useEffect } from 'react';
import { IconBaseProps } from 'react-icons';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, SubMenu } from './styles';
import { useAuth } from '../../../../hooks/auth';

interface MenuItemProps {
  title: string;
  link?: string;
  icon: React.ComponentType<IconBaseProps>;
  subitems?: Array<{
    title: string;
    link: string;
    roles?: string[];
  }>;
}

const MenuItem: React.FC<MenuItemProps> = ({
  link,
  title,
  icon: Icon,
  subitems,
}) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const [active, setActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!menuItemRef.current || !event.target) {
        return;
      }

      if (menuItemRef.current.contains(event.target as HTMLDivElement)) {
        setActive(true);
      } else {
        setActive(false);
      }
    }

    document.addEventListener('mouseover', handleClickOutside);

    return () => {
      document.removeEventListener('mouseover', handleClickOutside);
    };
  }, []);

  return (
    <Container active={active} ref={menuItemRef}>
      <li>
        {link ? (
          <Link to="/">
            {Icon && <Icon size={20} />}
            {title}
          </Link>
        ) : (
          <span>
            {Icon && <Icon size={20} />}
            {title}
          </span>
        )}

        {subitems && (
          <SubMenu active={active}>
            <ul>
              {subitems.map(subitem => {
                const SubItem = (
                  <li key={subitem.title}>
                    <Link to={subitem.link}>
                      <MdKeyboardArrowRight size={18} />
                      {subitem.title}
                    </Link>
                  </li>
                );

                if (!subitem.roles) {
                  return SubItem;
                }

                if (subitem.roles.includes(user.role)) {
                  return SubItem;
                }
                return <></>;
              })}
            </ul>
          </SubMenu>
        )}
      </li>
    </Container>
  );
};

export default MenuItem;
