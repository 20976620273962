import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { MdAttachMoney } from 'react-icons/md';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import AsyncSelect from '../../../components/Select/Async';

import Breadcrumbs from '../../../containers/Breadcrumbs';
import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';

// import { Container } from './styles';

interface ProductRegistrationFormData {
  name: string;
  course_ids: string[];
  duration_in_months: number;
  wordings_per_month: number;
  price: number;
}

const ProductRegistration: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const coursesOptions = (
    inputValue: string,
    callback: (options: any) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/courses/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  const handleSubmit = useCallback(
    async (data: ProductRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          course_ids: Yup.array()
            .of(Yup.string())
            .min(1, 'Selecione pelo menos um curso'),
          duration_in_months: Yup.number()
            .required('Duração obrigatória')
            .positive()
            .nullable(),
          wordings_per_month: Yup.number()
            .required('Créditos por mês obrigatório')
            .positive()
            .nullable(),
          price: Yup.number()
            .required('Valor obrigatório')
            .positive('Valor mínimo de R$0,10')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        const price = data.price ? data.price * 100 : null;

        await api.post('products', {
          price,
          name: data.name,
          course_ids: data.course_ids,
          duration_in_months: data.duration_in_months,
          wordings_per_month: data.wordings_per_month,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O plano foi cadastrado com sucesso!',
        });

        history.push('/planos');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar plano"
          items={[
            { title: 'Planos', link: '/planos' },
            { title: 'Cadastrar plano' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}

              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={6}>
                    <Input type="text" name="name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <AsyncSelect
                      name="course_ids"
                      label="Cursos"
                      loadOptions={debounce(coursesOptions, 500)}
                      isMulti
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Input
                      type="number"
                      name="duration_in_months"
                      label="Duração (em meses)"
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      type="number"
                      name="wordings_per_month"
                      label="Créditos por mês"
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="price"
                      label="Valor"
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar plano
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default ProductRegistration;
