import styled from 'styled-components';
import { shade } from 'polished';

interface PaginationButtonProps {
  isActive: boolean;
}

export const Container = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 2px solid ${({ theme }) => theme.background};

        th {
          padding: 8px 16px;
          text-align: left;
          color: ${({ theme }) => theme.title};
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid ${({ theme }) => theme.background};

        td {
          padding: 12px 16px;

          &[colspan] {
            text-align: center;
          }

          > div {
            display: flex;
            align-items: center;

            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
        }
      }
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      transition: color 0.2s;

      display: flex;
      align-items: center;

      &:hover {
        color: ${({ theme }) => shade(0.1, theme.colors.primary)};
      }

      svg {
        margin-right: 8px;
      }
    }
  }
`;

export const SearchInput = styled.div`
  margin-left: auto;
  border-radius: 4px;
  border: ${({ theme }) => theme.input.border};
  background: ${({ theme }) => theme.input.background};
  padding: 12px 16px;
  margin-bottom: 16px;
  max-width: 240px;

  display: flex;
  align-items: center;

  &:hover,
  &:focus-within {
    border-color: ${({ theme }) => theme.input.border};
  }

  svg {
    margin-right: 16px;
    color: ${({ theme }) => theme.input.icon};
  }

  input {
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.input.text};

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder};
    }
  }
`;

export const Pagination = styled.div`
  font-size: 14px;
  margin-top: 16px;

  display: flex;
  align-items: center;

  p {
    flex: 1;
  }
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
  border: 0;
  background: none;
  color: ${props =>
    props.isActive ? props.theme.colors.primary : props.theme.text};

  & + button {
    margin-left: 16px;
  }
`;
