import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { AxiosError } from 'axios';

import api from '../services/api';

interface IFailedRequest {
  onSuccess(token: string): void;
  onFailure(err: AxiosError): void;
}
interface User {
  id: string;
  show_name: string;
  role_name: string;
  avatar_url: string;
  role: string;
  affiliate_coupon: string;
}

interface AuthState {
  token: string;
  refreshToken: string;
  user: User;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthContextState {
  user: User;
  isAuthenticated: boolean;
  updateUser(userData: User): void;
  login(credentials: LoginCredentials): Promise<void>;
  logout(): void;
}

let authChannel: BroadcastChannel | undefined;

// eslint-disable-next-line no-restricted-globals
if ('BroadcastChannel' in self) {
  authChannel = new BroadcastChannel('auth');
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('@PoxaluluSystem:token'),
  );

  const clearStorage = () => {
    localStorage.removeItem('@PoxaluluSystem:token');
    localStorage.removeItem('@PoxaluluSystem:refreshToken');

    setUser({} as User);
    setIsAuthenticated(false);
  };

  const logout = useCallback(() => {
    clearStorage();

    authChannel?.postMessage('logout');
  }, []);

  useEffect(() => {
    if (!authChannel) {
      return;
    }

    authChannel.onmessage = event => {
      switch (event.data) {
        case 'logout':
          clearStorage();
          break;
        case 'login':
          window.location.reload();
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@PoxaluluSystem:token');

    if (token) {
      api
        .get('/profile/me')
        .then(response => {
          const userData = response.data;

          setUser({
            ...userData.user,
            courses: userData.courses,
            forums: userData.forums,
          });

          setIsAuthenticated(true);
        })
        .catch(() => {
          logout();
        });
    }
  }, [logout]);

  const login = useCallback(async ({ email, password }) => {
    const response = await api.post<AuthState>('sessions', {
      email,
      password,
    });

    const { token, refreshToken } = response.data;

    localStorage.setItem('@PoxaluluSystem:token', token);
    localStorage.setItem('@PoxaluluSystem:refreshToken', refreshToken);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const profileResponse = await api.get('/profile/me');

    setUser({
      ...profileResponse.data.user,
      courses: profileResponse.data.courses,
      forums: profileResponse.data.forums,
    });

    setIsAuthenticated(true);

    authChannel?.postMessage('login');
  }, []);

  const updateUser = useCallback((userData: User) => {
    setUser(userData);
  }, []);

  let refreshToken = localStorage.getItem('@PoxaluluSystem:refreshToken');
  let isRefreshing = false;
  let failedRequestsQueue: IFailedRequest[] = [];

  api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.data?.error === 'token-expired') {
          refreshToken = localStorage.getItem('@PoxaluluSystem:refreshToken');
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post('/refresh-tokens', { token: refreshToken })
              .then(response => {
                localStorage.setItem(
                  '@PoxaluluSystem:token',
                  response.data.token,
                );
                localStorage.setItem(
                  '@PoxaluluSystem:refreshToken',
                  response.data.refreshToken,
                );

                api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

                failedRequestsQueue.forEach(request =>
                  request.onSuccess(response.data.token),
                );
                failedRequestsQueue = [];
              })
              .catch(err => {
                failedRequestsQueue.forEach(request => request.onFailure(err));
                failedRequestsQueue = [];
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (newToken: string) => {
                originalConfig.headers.Authorization = `Bearer ${newToken}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }

        logout();
      }

      return Promise.reject(error);
    },
  );

  return (
    <AuthContext.Provider
      value={{ user, updateUser, login, logout, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be user within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
