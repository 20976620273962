import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { MdModeEdit, MdDelete, MdClose } from 'react-icons/md';

import { SRLWrapper } from 'simple-react-lightbox';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Modal, { ModalHeader } from '../../../components/Modal';

import IconButton from '../../../components/IconButton';
import InputColor from '../../../components/InputColor';
import Dropzone from '../../../components/Dropzone';

import { CategoryItem } from './styles';

interface ForumData {
  id: string;
  title: string;
  slug: string;
  course: {
    id: string;
    title: string;
  };
  categories: Array<{
    id: string;
    title: string;
    description: string;
    thumbnail_url: string;
  }>;
  created_at: string;
}

interface ForumCategoryRegistrationFormData {
  title: string;
  description: string;
  background: string;
}

const ForumPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const history = useHistory();

  const formForumCategoryRef = useRef<FormHandles>(null);

  const [forum, setForum] = useState<ForumData>({} as ForumData);

  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');

  const [modalAddForumCategory, setModalAddForumCategory] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const response = await api.get(`forums/${id}`);

        setForum(response.data);

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O fórum não foi encontrado!',
        });

        history.push('/foruns');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleAddForumCategory = useCallback(
    async (data: ForumCategoryRegistrationFormData) => {
      setLoadingModal(true);

      formForumCategoryRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          background: Yup.string().required('Background obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('background', data.background);
        formData.append('thumbnail', thumbnailFile);

        const response = await api.post(`forums/${id}/categories`, formData);

        setForum(oldForum => ({
          ...oldForum,
          categories: [...oldForum.categories, response.data],
        }));

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A categoria foi cadastrada com sucesso!',
        });

        setModalAddForumCategory(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formForumCategoryRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast, id, thumbnailFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        {loading && <Loader />}

        <Breadcrumbs
          title={forum.title}
          items={[{ title: 'Fóruns', link: '/foruns' }, { title: forum.title }]}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => setModalAddForumCategory(true)}
          >
            Cadastrar categoria
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <SRLWrapper
              options={{
                settings: {
                  autoplaySpeed: 0,
                  disableKeyboardControls: true,
                  disableWheelControls: true,
                  disablePanzoom: true,
                  hideControlsAfter: true,
                  overlayColor: 'rgba(18, 18, 20, 0.9)',
                },
                buttons: {
                  showAutoplayButton: false,
                  showCloseButton: false,
                  showDownloadButton: false,
                  showFullscreenButton: false,
                  showNextButton: false,
                  showPrevButton: false,
                  showThumbnailsButton: false,
                },
                thumbnails: {
                  showThumbnails: false,
                },
                progressBar: {
                  showProgressBar: false,
                },
                caption: {
                  showCaption: false,
                },
              }}
            >
              {forum.categories?.map(category => (
                <CategoryItem key={category.id}>
                  <img src={category.thumbnail_url} alt={category.title} />

                  <h2>{category.title}</h2>

                  <span>{category.description}</span>

                  <aside>
                    <IconButton icon={MdModeEdit} />
                    <IconButton icon={MdDelete} />
                  </aside>
                </CategoryItem>
              ))}
            </SRLWrapper>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddForumCategory}
        setIsOpen={() => setModalAddForumCategory(false)}
      >
        {loadingModal && <Loader />}

        <ModalHeader>
          <div>Cadastrar categoria</div>

          <button type="button" onClick={() => setModalAddForumCategory(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formForumCategoryRef}
          onSubmit={handleAddForumCategory}
          noValidate
        >
          <Row>
            <Col md={12}>
              <Input name="title" label="Nome" />
            </Col>

            <Col md={12}>
              <InputColor name="background" label="Background" />
            </Col>

            <Col md={12}>
              <Input name="description" label="Descrição" />
            </Col>

            <Col md={12}>
              <Dropzone
                label="Thumbnail"
                accept="image/*"
                maxSize={3000000}
                onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
              />
            </Col>
          </Row>

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ForumPreview;
