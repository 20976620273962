import React from 'react';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import AffiliateDashboard from './AffiliateDashboard';
import CorrectorDashboard from './CorrectorDashboard';

const Dashboard: React.FC = () => {
  const { user, logout } = useAuth();
  const { addToast } = useToast();

  switch (user.role) {
    case 'affiliate':
    case 'seller':
      return <AffiliateDashboard />;

    case 'administrator':
    case 'coordinator':
    case 'corrector':
    case 'super-corrector':
    case 'hyper-corrector':
      return <CorrectorDashboard />;

    case 'newbie':
    case 'student':
      addToast({
        title: 'Não autorizado',
        type: 'error',
        description: 'Você não possui autorização!',
      });
      logout();
      return <></>;

    default:
      return <></>;
  }
};

export default Dashboard;
