import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link, useHistory } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { addMonths, subMonths, setDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import {
  MdStarBorder,
  MdDoneAll,
  MdTimeline,
  MdAttachMoney,
} from 'react-icons/md';

import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';
import formatDate from '../../../utils/formatDate';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';

import Button from '../../../components/Button';
import BoxInfo from '../../../components/BoxInfo';
import Card from '../../../components/Card';
import ProgressBar from '../../../components/ProgressBar';
import Datatable from '../../../components/Datatable';
import Badge from '../../../components/Badge';
import StatisticsChart from '../StatisticsChart';

import {
  CardTitle,
  Filter,
  Welcome,
  WelcomeMessage,
  PendingWordings,
} from './styles';

interface ThemeData {
  id: string;
  name: string;
  thumbnail_url: string;
}

interface WordingData {
  theme: ThemeData;
}

interface CorrectionData {
  id: string;
  wording: WordingData;
  status: boolean;
  disapprove_reason: string;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'wording' | 'status' | 'created_at' | 'disapprove_reason';
  transform?(
    value: string | WordingData | boolean,
    row?: CorrectionData,
  ): string | React.ReactElement;
}

interface ProfileStats {
  averageGradeInMonth: number;
  averageRatingInMonth: number;
  countCorrectionsInMonth: number;
  balanceInMonth: number;
}

const CorrectorDashboard: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [pendingColor, setPendingColor] = useState<
    'success' | 'warning' | 'danger'
  >('success');

  const [startDate, setStartDate] = useState<Date>(
    subMonths(setDate(new Date(), 27), 1),
  );
  const [endDate, setEndDate] = useState<Date>(setDate(new Date(), 26));
  const [filter, setFilter] = useState('');

  const [correctionLoading, setCorrectionLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<CorrectionData[]>([]);
  const [profileStats, setProfileStats] = useState<ProfileStats>({
    averageGradeInMonth: 0,
    averageRatingInMonth: 0,
    countCorrectionsInMonth: 0,
    balanceInMonth: 0,
  });

  useEffect(() => {
    const formattedDate = formatDate(endDate.toISOString(), "LLLL 'de' yyyy");

    setFilter(formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1));
  }, [endDate]);

  useEffect(() => {
    async function loadData() {
      try {
        setProfileLoading(true);

        const response = await api.get<ProfileStats>(
          `/corrections/statistics/profile`,
          { params: { from: startDate, to: endDate } },
        );

        setProfileStats(response.data);
      } catch (err) {
        // console.log(err);
      } finally {
        setProfileLoading(false);
      }
    }

    loadData();
  }, [startDate, endDate]);

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/wordings/goal');

      const {
        wordingsThatHaveBeenCorrectedToday,
        wordingsForThreeDaysAhead,
        pendingWordings,
      } = response.data;

      setProgress(wordingsThatHaveBeenCorrectedToday);
      setTotal(wordingsForThreeDaysAhead);
      setPending(pendingWordings);

      if (pendingWordings > 200) {
        setPendingColor('danger');
      } else if (pendingWordings > 100) {
        setPendingColor('warning');
      } else {
        setPendingColor('success');
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setCorrectionLoading(true);

        const response = await api.get(`profile/corrections`, {
          params: { pageIndex, search, from: startDate, to: endDate },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setCorrectionLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search, startDate, endDate]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, 'dd/MM/yyyy');
        },
      },
      {
        label: 'Tema',
        field: 'wording',
        transform: (wording: WordingData, row: CorrectionData) => {
          return (
            <Link to={`/correcoes/visualizar/${row.id}`}>
              {wording.theme.name}
            </Link>
          );
        },
      },
      {
        label: '',
        field: 'status',
        transform: (status: boolean, row: CorrectionData) => {
          return (
            <Badge
              title={status ? 'Ativa' : 'Inativa'}
              color={status ? 'success' : 'error'}
              tooltip={row.disapprove_reason}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container>
        <Welcome>
          <div>
            <h1>Dashboard</h1>
            <WelcomeMessage>
              {`Seja bem-vindo, ${user.show_name}!`}
              <span
                role="img"
                aria-label="Coração roxo"
                style={{ marginLeft: '4px' }}
              >
                💜
              </span>
            </WelcomeMessage>
          </div>

          <div>
            <h3>Redações pendentes</h3>
            <PendingWordings color={pendingColor}>{pending}</PendingWordings>
          </div>

          <div>
            <ProgressBar
              title="Redações para hoje"
              progress={progress}
              total={total}
            />
          </div>

          <div style={{ textAlign: 'right' }}>
            <Button color="secondary" onClick={() => history.push('/corrigir')}>
              Nova correção
            </Button>
          </div>
        </Welcome>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <h2 style={{ flex: 1 }}>Estatísticas</h2>

          <Filter>
            {filter}

            <div>
              <ReactDatePicker
                name="filterDate"
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(subMonths(setDate(date, 27), 1));
                  setEndDate(setDate(date, 26));
                }}
                dateFormat="dd/MM/yyyy"
                maxDate={addMonths(new Date(), 1)}
                showMonthYearPicker
                showFullMonthYearPicker
                locale={ptBR}
              />
            </div>
            <div>
              <input
                type="text"
                value={formatDate(endDate.toISOString(), 'dd/MM/yyyy')}
                disabled
              />
            </div>
          </Filter>
        </div>

        <Row style={{ marginBottom: '20px' }}>
          <Col md={6} lg={3}>
            <BoxInfo
              loading={profileLoading}
              title="Correções"
              value={profileStats.countCorrectionsInMonth}
              icon={MdDoneAll}
            />
          </Col>

          <Col md={6} lg={3}>
            <BoxInfo
              loading={profileLoading}
              title="Nota média"
              value={profileStats.averageGradeInMonth}
              decimals={1}
              icon={MdTimeline}
            />
          </Col>

          <Col md={6} lg={3}>
            <BoxInfo
              loading={profileLoading}
              title="Avaliação dos alunos"
              value={profileStats.averageRatingInMonth * 2}
              icon={MdStarBorder}
              decimals={1}
            />
          </Col>

          <Col md={6} lg={3}>
            <BoxInfo
              loading={profileLoading}
              title="Saldo"
              value={profileStats.balanceInMonth}
              prefix="R$"
              decimals={2}
              icon={MdAttachMoney}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <CardTitle>Minhas correções</CardTitle>

            <Card>
              <Datatable
                loading={correctionLoading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>

          <Col lg={12}>
            <CardTitle>Correções por mês</CardTitle>

            <Card>
              <StatisticsChart />
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default CorrectorDashboard;
