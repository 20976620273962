import styled from 'styled-components';

export const Header = styled.header`
  position: relative;

  > div {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 28.8px;
    color: ${({ theme }) => theme.title};
    text-align: center;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.title};
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  text-align: center;

  > p {
    padding: 0 24px;
    margin-bottom: 24px;
  }

  > footer {
    button + button {
      margin-left: 8px;
    }
  }
`;
