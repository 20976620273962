import styled from 'styled-components';

interface ContainerProps {
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'danger';
}

export const Container = styled.label<ContainerProps>`
  input {
    display: none;

    &:checked + div {
      background: ${({ theme }) => theme.input.background};
      border: ${({ theme }) => theme.input.border};
    }
  }

  > div {
    cursor: pointer;
    border: 1px solid transparent;
    margin: 4px;
    border-radius: 8px;
    position: relative;
    padding: 8px 12px;
    padding-left: 28px;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${props => props.theme.colors[props.color]};
    }
  }
`;
