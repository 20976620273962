import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  hasNotifications: boolean;
}

interface MenuProps {
  active: boolean;
}

interface MenuItemProps {
  read: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 999;

  > button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    height: 100%;
    background: none;
    border: none;
    color: ${({ theme }) => theme.text};

    &:hover {
      background: ${({ theme }) =>
        shade(theme.hoverPercentage, theme.backgroundCard)};
    }

    ${props =>
      props.hasNotifications &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: ${({ theme }) => theme.colors.tertiary};
          top: Calc(50% - 6px);
          right: Calc(50% - 6px);
          transform: translate(50%, -50%);
        }
      `}
  }
`;

export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: Calc(100% + 16px);
  left: 50%;
  width: 400px;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundCard};
  display: ${props => (props.active ? 'block' : 'none')};
  box-shadow: ${({ theme }) => theme.shadow};
  transform: translateX(-50%);
  z-index: 9;

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 48px;
    background: ${({ theme }) => theme.background};
    border-radius: 8px 8px 0 0;

    span {
      color: ${({ theme }) => theme.title};
      font-weight: bold;
    }

    button {
      background: none;
      border: none;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  footer {
    padding: 0 16px;
    height: 48px;
    background: ${({ theme }) => theme.background};
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }

  @media (max-width: 575px) {
    & {
      width: 100vw;
      right: -15px;
      top: Calc(100% + 1px);
    }
  }

  @media (min-width: 576px) {
    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: Calc(50% - 4px);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-bottom: 6px solid ${({ theme }) => theme.background};
    }
  }

  > p {
    text-align: center;
    padding: 16px 20px;
    color: ${({ theme }) => theme.text};
  }

  ul {
    list-style: none;
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  & + li {
    border-top: 1px solid ${({ theme }) => theme.background};
  }

  > button {
    cursor: ${props => (props.read ? 'default' : 'pointer')};
  }

  > a,
  > button {
    border: none;
    background: none;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    justify-content: space-between;

    svg {
      margin-right: 8px;
      transition: color 0.2s ease;
    }

    &:hover {
      background: ${({ theme }) =>
        shade(theme.hoverPercentage, theme.backgroundCard)};

      svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    div {
      flex: 1;
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 14px;
        margin-bottom: 8px;
      }

      small {
        font-size: 11px;
      }
    }

    > span {
      width: 8px;
      height: 8px;
      background: ${({ theme }) => theme.colors.tertiary};
      border-radius: 50%;
      z-index: 999;
      visibility: ${props => (props.read ? 'hidden' : 'visible')};
    }
  }
`;
