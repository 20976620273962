import styled from 'styled-components';

export const CorrectionPanel = styled.div`
  width: 100%;
  z-index: 99;
  margin-bottom: 40px;
  margin-top: 1px;
  background: ${({ theme }) => theme.backgroundCard};
  padding: 28px 0;
  margin-bottom: 40px;
  transition: all 0.2s ease;
  position: sticky;
  top: 0;

  > div {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    > section {
      display: flex;
      position: relative;

      > img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }

      > div {
        font-weight: bold;
        margin-left: 16px;

        display: flex;
        flex-direction: column;

        span:last-child {
          font-size: 14px;
          color: ${({ theme }) => theme.colors.tertiary};
        }
      }
    }
  }
`;

interface PreviousWordingOverlay {
  active: boolean;
}

export const PreviousWordingOverlay = styled.div<PreviousWordingOverlay>`
  position: absolute;
  background: ${({ theme }) => theme.background};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  z-index: 9;
  transition: all 0.2s ease;
`;
