import styled, { css } from 'styled-components';
import ReactSelect, { OptionTypeBase, Props } from 'react-select';
import { shade } from 'polished';

interface ContainerProps {
  hasError: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: left;
  margin-bottom: 16px;

  > label {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    > div {
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      padding: 12px 16px;
      margin-top: 8px;

      display: flex;
      align-items: center;

      ${props =>
        props.disabled &&
        css`
          opacity: 0.45;
          cursor: not-allowed;

          input {
            cursor: not-allowed;
          }
        `}

      ${props =>
        props.hasError &&
        css`
          border-color: ${({ theme }) => theme.colors.danger};

          &:not(:focus-within) {
            input {
              color: ${({ theme }) => theme.colors.danger};
            }
          }
        `}

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      > svg {
        margin-right: 16px;
        color: ${({ theme }) => theme.input.icon};
      }
    }
  }
`;

export const ReactSelectElement = styled(ReactSelect)<Props<OptionTypeBase>>`
  flex: 1;
  background: transparent;
  color: ${({ theme }) => theme.input.text};

  > div {
    border: none;
    background: none;
    min-height: 0;

    .react-select__value-container {
      padding: 0;

      .react-select__placeholder {
        color: ${({ theme }) => theme.input.placeholder};
        font-weight: normal;
        margin-left: 0;
        margin-right: 0;
      }

      .react-select__single-value {
        font-weight: normal;
        color: ${({ theme }) => theme.title};
      }

      .css-b8ldur-Input {
        color: ${({ theme }) => theme.title};
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .react-select__option {
      font-weight: normal;
    }

    .react-select__option:hover,
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      background: ${({ theme }) =>
        shade(theme.hoverPercentage, theme.backgroundCard)};
      color: inherit;
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        color: ${({ theme }) => theme.title};
        padding: 0;
      }
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }

  .react-select__menu {
    width: Calc(100% + 32px);
    left: -16px;
    top: Calc(100% + 12px);
    background: ${({ theme }) => theme.backgroundCard};
    box-shadow: ${({ theme }) => theme.shadow};
  }

  .react-select__menu-notice {
    font-weight: normal;
  }

  .css-1pahdxg-control {
    box-shadow: none;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 14px;
  margin-top: 4px;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
`;
