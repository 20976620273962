import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';
import Input from '../../components/Input';
import Modal, { ModalHeader } from '../../components/Modal';
import Loader from '../../components/Loader';
import ModalConfirm from '../../components/Modal/Confirm';

// import { Container } from './styles';

interface ExamData {
  id: string;
  name: string;
  expire_at: string;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'name' | 'expire_at' | 'created_at';
  transform?(value: string, row?: ExamData): string | React.ReactElement;
}

interface ExamFormData {
  name: string;
}

const Exam: React.FC = () => {
  const { addToast } = useToast();

  const formAddExamRef = useRef<FormHandles>(null);
  const formEditExamRef = useRef<FormHandles>(null);

  const [selectedExamId, setSelectedExamId] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingAddExam, setLoadingAddExam] = useState(false);
  const [loadingDelExam, setLoadingDelExam] = useState(false);
  const [loadingEditExam, setLoadingEditExam] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [modalAddExam, setModalAddExam] = useState(false);
  const [modalDelExam, setModalDelExam] = useState(false);
  const [modalEditExam, setModalEditExam] = useState(false);

  const [data, setData] = useState<ExamData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/exams', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleAddExam = useCallback(
    async (formData: ExamFormData) => {
      setLoadingAddExam(true);

      formAddExamRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post<ExamData>('exams', {
          name: formData.name,
        });

        setData(oldData => [response.data, ...oldData]);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O exame foi cadastrado com sucesso!',
        });

        setModalAddExam(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddExamRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingAddExam(false);
      }
    },
    [addToast],
  );

  const handleEditExam = useCallback(
    async (formData: ExamFormData) => {
      setLoadingEditExam(true);

      formEditExamRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.put<ExamData>(`exams/${selectedExamId}`, {
          name: formData.name,
        });

        setData(oldDatas =>
          oldDatas.map(oldData =>
            oldData.id === response.data.id ? response.data : oldData,
          ),
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O exame foi atualizado com sucesso!',
        });

        setModalEditExam(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditExamRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingEditExam(false);
      }
    },
    [addToast, selectedExamId],
  );

  const handleDelExam = useCallback(async () => {
    setLoadingDelExam(true);

    try {
      await api.delete(`/exams/${selectedExamId}`);

      setData(oldDatas =>
        oldDatas.filter(oldData => oldData.id !== selectedExamId),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O exame foi excluído com sucesso!',
      });

      setModalDelExam(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingDelExam(false);
    }
  }, [addToast, selectedExamId]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedExamId(id);
                    setModalEditExam(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Alterar
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedExamId(id);
                    setModalDelExam(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Excluir
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Exames" items={[{ title: 'Exames' }]}>
          <Button color="secondary" onClick={() => setModalAddExam(true)}>
            Cadastrar exame
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field])
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddExam}
        setIsOpen={() => setModalAddExam(!modalAddExam)}
      >
        {loadingAddExam && <Loader />}
        <ModalHeader>
          <div>Cadastrar exame</div>

          <button type="button" onClick={() => setModalAddExam(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddExamRef} onSubmit={handleAddExam} noValidate>
          <Input type="text" name="name" label="Nome" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar exame
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditExam}
        setIsOpen={() => setModalEditExam(!modalEditExam)}
      >
        {loadingEditExam && <Loader />}
        <ModalHeader>
          <div>Atualizar exame</div>

          <button type="button" onClick={() => setModalEditExam(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formEditExamRef} onSubmit={handleEditExam} noValidate>
          <Input type="text" name="name" label="Nome" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar exame
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir exame"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir o exame? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelExam}
        isOpen={modalDelExam}
        isLoading={loadingDelExam}
        setIsOpen={() => setModalDelExam(!modalDelExam)}
      />
    </>
  );
};

export default Exam;
