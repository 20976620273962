import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Breadcrumbs from '../../containers/Breadcrumbs';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';
import Badge from '../../components/Badge';
import Modal, { ModalHeader } from '../../components/Modal';
import Loader from '../../components/Loader';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Select from '../../components/Select';

interface CourseRegistrationFormData {
  title: string;
  description: string;
  exam_name: string;
}

interface CourseData {
  id: string;
  title: string;
  slug: string;
  description: string;
  active: boolean;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'title' | 'description' | 'active' | 'created_at';
  transform?(
    value: string | boolean,
    row?: CourseData,
  ): string | React.ReactElement;
}

const Course: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const formCourseRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [modalAddCourse, setModalAddCourse] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<CourseData[]>([]);
  const [exams, setExams] = useState([]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = format(
            parseISO(created_at),
            "dd/MM/yyyy 'às' HH:mm",
            { locale: ptBR },
          );

          return formattedDate;
        },
      },
      {
        label: 'Nome',
        field: 'title',
      },
      {
        label: 'Descrição',
        field: 'description',
      },
      {
        label: '',
        field: 'active',
        transform: (status: string) =>
          status ? (
            <Badge title="Ativo" color="success" />
          ) : (
            <Badge title="Inativo" color="error" />
          ),
      },
      {
        label: '',
        field: 'id',
        transform: (id, row: CourseData) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/cursos/${row.slug}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    api.get('/exams/list').then(response => setExams(response.data));
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/courses', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleAddCourse = useCallback(
    async (formData: CourseRegistrationFormData) => {
      try {
        setModalLoading(true);

        formCourseRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          exam_name: Yup.string().required('Exame obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post<CourseData>('courses', {
          title: formData.title,
          description: formData.description,
          exam_name: formData.exam_name,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O curso foi cadastrado com sucesso!',
        });

        history.push(`/cursos/visualizar/${response.data.id}`);
      } catch (err) {
        setModalLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formCourseRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Cursos" items={[{ title: 'Cursos' }]}>
          <Button color="secondary" onClick={() => setModalAddCourse(true)}>
            Cadastrar curso
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum curso encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddCourse}
        setIsOpen={() => setModalAddCourse(!modalAddCourse)}
      >
        {modalLoading && <Loader />}

        <ModalHeader>
          <div>Cadastrar curso</div>

          <button type="button" onClick={() => setModalAddCourse(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formCourseRef} onSubmit={handleAddCourse} noValidate>
          <Row>
            <Col md={6}>
              <Input name="title" label="Nome" />
            </Col>

            <Col md={6}>
              <Select name="exam_name" label="Exame" options={exams} />
            </Col>
          </Row>

          <TextArea name="description" label="Descrição" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Course;
