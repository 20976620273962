import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: none;
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.text};
  transition: background 0.2s ease, color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.title};
    background: ${({ theme }) => darken(0.04, theme.backgroundCard)};
  }

  & + button {
    margin-left: 4px;
  }
`;
