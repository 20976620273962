import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { MdAttachMoney } from 'react-icons/md';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';

import Breadcrumbs from '../../../containers/Breadcrumbs';
import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Select from '../../../components/Select';

// import { Container } from './styles';

interface PartnerData {
  name: string;
  cpf: string;
  email: string;
  code: string;
  phone_number: string;
  sales_comission: number;
  discount_amount: number;
  discount_type: string;
  bank: string;
  agency: string;
  account: string;
  instagram: string;
  tiktok: string;
  youtube: string;
}

interface PartnerPreviewFormData {
  name: string;
  cpf: string;
  email: string;
  code: string;
  phone_number: string;
  sales_comission: number;
  discount_amount: number;
  discount_type: string;
  bank: string;
  agency: string;
  account: string;
  instagram: string;
  tiktok: string;
  youtube: string;
}

const PartnerPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [partner, setPartner] = useState<PartnerData>({} as PartnerData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/partners/${id}`);

        setPartner(response.data);

        formRef.current?.setData({
          sales_comission: response.data.sales_comission / 100,
          discount_amount: response.data.discount_amount / 100,
          cpf: response.data.cpf,
          discount_type: response.data.discount_type,
        });

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O parceiro não foi encontrado!',
        });

        history.push('/parceiros');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleSubmit = useCallback(
    async (data: PartnerPreviewFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string().required('CPF obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          code: Yup.string().required('Código do desconto obrigatório'),
          phone_number: Yup.string().required('Telefone obrigatório'),
          sales_comission: Yup.number()
            .nullable()
            .required('Comissão obrigatório'),
          discount_amount: Yup.string().required('Desconto obrigatório'),
          discount_type: Yup.string()
            .nullable()
            .required('Tipo do desconto obrigatório'),
          bank: Yup.string(),
          agency: Yup.string(),
          account: Yup.string(),
          instagram: Yup.string(),
          tiktok: Yup.string(),
          youtube: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`partners/${id}`, {
          name: data.name,
          cpf: data.cpf,
          email: data.email,
          code: data.code,
          phone_number: data.phone_number,
          sales_comission: data.sales_comission * 100,
          discount_amount: data.discount_amount * 100,
          discount_type: data.discount_type,
          bank: data.bank,
          agency: data.agency,
          account: data.account,
          instagram: data.instagram,
          tiktok: data.tiktok,
          youtube: data.youtube,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O parceiro foi atualizado com sucesso!',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, id],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar parceiro"
          items={[
            { title: 'Parceiros', link: '/parceiros' },
            { title: 'Visualizar parceiro' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}

              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={partner}
                noValidate
              >
                <Row>
                  <Col md={4}>
                    <Input type="text" name="name" label="Nome" />
                  </Col>

                  <Col md={4}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>

                  <Col md={4}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <InputMask
                      mask=""
                      maskChar={null}
                      type="text"
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="sales_comission"
                      label="Comissão (%)"
                    />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="code" label="Código do Desconto" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Select
                      name="discount_type"
                      label="Tipo do Desconto"
                      options={[
                        {
                          label: 'Fixo',
                          value: 'flat',
                        },
                        {
                          label: 'Porcentagem',
                          value: 'percentage',
                        },
                      ]}
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="discount_amount"
                      label="Valor do Desconto"
                    />
                  </Col>
                </Row>

                <h2>Dados bancários</h2>

                <Row>
                  <Col md={4}>
                    <Input type="text" name="bank" label="Banco" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="agency" label="Agência" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="account" label="Conta" />
                  </Col>
                </Row>

                <h2>Redes Sociais</h2>

                <Row>
                  <Col md={4}>
                    <Input type="text" name="instagram" label="Instagram" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="youtube" label="Youtube" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="tiktok" label="Tiktok" />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar parceiro
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default PartnerPreview;
