import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdAttachMoney, MdEvent } from 'react-icons/md';
import { subYears } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';
import genders from '../../utils/getGenders';
import convertFileToBase64 from '../../utils/convertFileToBase64';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Input from '../../components/Input';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import DatePicker from '../../components/Datepicker';
import ModalCropImage from '../../components/ModalCropImage';

import { ProfilePicture, DataBox } from './styles';

interface UserData {
  id: string;
  role: string;
  role_name: string;
  created_at: string;
  show_name: string;
  avatar_url: string;
  affiliate_coupon: string;
}

interface UserFormData {
  first_name: string;
  last_name: string;
  phone_number: string;
  birthday: Date;
  gender: string;
  password: string;
  old_password: string;
  password_confirmation: string;
  role: string;
  bank: string;
  agency: string;
  account: string;
  account_type: string;
}

interface UserInfo {
  countCorrections: number;
  averageRating: number;
  averageGrade: number;
  correctionsPerDay: number;
}

const Profile: React.FC = () => {
  const history = useHistory();
  const { user: loggedUser, updateUser } = useAuth();

  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLInputElement>(null);

  const [userLoading, setUserLoading] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const [modalAvatar, setModalAvatar] = useState(false);

  const [user, setUser] = useState<UserData>({} as UserData);
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);

  const [avatar, setAvatar] = useState('');

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData() {
      setUserLoading(true);

      try {
        const response = await api.get('/users/profile');

        const price_per_wording = response.data.price_per_wording
          ? response.data.price_per_wording / 100
          : null;

        setUser(response.data);

        formRef.current?.setData({
          ...response.data,
          price_per_wording,
        });

        setUserLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O usuário não foi encontrado!',
        });

        history.push('/usuarios');
      }
    }

    loadData();
  }, [loggedUser.id, addToast, history]);

  useEffect(() => {
    async function loadData() {
      if (
        !['corrector', 'super-corrector', 'hyper-corrector'].includes(user.role)
      ) {
        return;
      }

      setStatisticsLoading(true);

      try {
        const response = await api.get('/profile/info');

        setUserInfo(response.data);

        setStatisticsLoading(false);
      } catch (error) {
        // console.log(error);
      }
    }

    loadData();
  }, [addToast, history]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setUserLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          birthday: Yup.date()
            .max(subYears(new Date(), 18), 'Mínimo de 18 anos')
            .nullable(),
          gender: Yup.string().nullable(),
          password: Yup.string(),
          old_password: Yup.string().when('password', {
            is: value => value.length > 0,
            then: Yup.string().required('Senha antiga obrigatória'),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'As senhas não são iguais',
          ),
          bank: Yup.string(),
          account: Yup.string(),
          account_type: Yup.string().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put('users/profile', {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          birthday: data.birthday,
          gender: data.gender,
          password: data.password,
          old_password: data.old_password,
          password_confirmation: data.password_confirmation,
          bank: data.bank,
          agency: data.agency,
          account: data.account,
          account_type: data.account_type,
        });

        updateUser(response.data);
        setUser(response.data);

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O perfil foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setUserLoading(false);
      }
    },
    [addToast, updateUser],
  );

  const handleChangeAvatar = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setAvatarLoading(true);

        const targetFile = event.target.files && event.target.files[0];

        if (!targetFile) {
          setAvatar('');
          return;
        }

        const convertedFile = await convertFileToBase64(targetFile);

        if (avatarRef.current) {
          avatarRef.current.value = '';
        }

        setAvatar(convertedFile);
        setModalAvatar(true);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no upload',
          description: 'Ocorreu um erro no upload da foto, tente novamente.',
        });
      } finally {
        setAvatarLoading(false);
      }
    },
    [addToast],
  );

  const handleSubmitAvatar = useCallback(
    async (file: File) => {
      try {
        // setLoading(true);
        const data = new FormData();

        data.append('avatar', file);

        const response = await api.patch('profile/avatar', data);
        const { avatar_url } = response.data;

        setUser(oldUser => {
          updateUser({
            ...oldUser,
            avatar_url,
          });

          return { ...oldUser, avatar_url };
        });
      } catch (err) {
        // console.log(err);
      } finally {
        // setLoading(false);
      }
    },
    [updateUser],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Perfil" items={[{ title: 'Perfil' }]} />

        <Row>
          <Col md={4}>
            <Card>
              {userLoading && <Loader />}

              <ProfilePicture>
                <label htmlFor="avatar">
                  <img src={user.avatar_url} alt={user.show_name} />

                  <div>
                    <span>Trocar foto</span>
                  </div>

                  <input
                    ref={avatarRef}
                    type="file"
                    id="avatar"
                    accept="image/*"
                    name="avatar"
                    onChange={handleChangeAvatar}
                  />
                </label>

                <h2>{user.show_name}</h2>
                <span>
                  Membro desde
                  {user.created_at &&
                    formatDate(user.created_at, " MMMM 'de' yyyy")}
                </span>
              </ProfilePicture>
            </Card>

            {['corrector', 'super-corrector', 'hyper-corrector'].includes(
              user.role,
            ) && (
              <Row>
                <Col md={12}>
                  <DataBox>
                    {statisticsLoading && <Loader />}
                    Redações corrigidas
                    <span>{userInfo.countCorrections}</span>
                  </DataBox>
                </Col>

                <Col md={12}>
                  <DataBox>
                    {statisticsLoading && <Loader />}
                    Avaliação dos alunos
                    <span>
                      {new Intl.NumberFormat('pt-BR', {
                        maximumFractionDigits: 1,
                      }).format((userInfo.averageRating / 10) * 2 || 0)}
                    </span>
                  </DataBox>
                </Col>

                <Col md={12}>
                  <DataBox>
                    {statisticsLoading && <Loader />}
                    Nota média
                    <span>
                      {new Intl.NumberFormat('pt-BR', {
                        maximumFractionDigits: 1,
                      }).format(userInfo.averageGrade || 0)}
                    </span>
                  </DataBox>
                </Col>

                <Col md={12}>
                  <DataBox>
                    {statisticsLoading && <Loader />}
                    Correções por dia
                    <span>
                      {new Intl.NumberFormat('pt-BR', {
                        maximumFractionDigits: 1,
                      }).format(userInfo.correctionsPerDay || 0)}
                    </span>
                  </DataBox>
                </Col>
              </Row>
            )}
          </Col>

          <Col md={8}>
            <Card>
              {userLoading && <Loader />}
              <h2>Dados pessoais</h2>

              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Input type="email" name="email" label="E-mail" disabled />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      mask=""
                      maskChar={null}
                      type="text"
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Select name="gender" label="Gênero" options={genders} />
                  </Col>

                  <Col md={4}>
                    <DatePicker
                      icon={MdEvent}
                      name="birthday"
                      label="Data de Nascimento"
                      maxDate={subYears(new Date(), 18)}
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="price_per_wording"
                      label="Valor por Correção"
                      step="0.5"
                      disabled
                    />
                  </Col>
                </Row>

                <h2>Alterar Senha</h2>

                <Row>
                  <Col md={4}>
                    <Input
                      type="password"
                      name="old_password"
                      label="Senha antiga"
                    />
                  </Col>

                  <Col md={4}>
                    <Input type="password" name="password" label="Nova senha" />
                  </Col>

                  <Col md={4}>
                    <Input
                      type="password"
                      name="password_confirmation"
                      label="Confirmar nova senha"
                    />
                  </Col>
                </Row>

                <h2>Dados bancários</h2>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="bank" label="Banco" disabled />
                  </Col>

                  <Col md={6}>
                    <Select
                      name="account_type"
                      label="Tipo da Conta"
                      options={[
                        { value: 'Conta Corrente', label: 'Conta Corrente' },
                        { value: 'Conta Poupança', label: 'Conta Poupança' },
                      ]}
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="agency" label="Agência" disabled />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="account" label="Conta" disabled />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar perfil
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalCropImage
        image={avatar}
        cropSize={{ width: 200, height: 200 }}
        isOpen={modalAvatar}
        setIsOpen={setModalAvatar}
        onSubmit={handleSubmitAvatar}
      />
    </>
  );
};

export default Profile;
