import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface TicketAnswerProps {
  alignment: 'left' | 'right';
}

export const TicketContent = styled.div`
  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin-top: 0;
    margin-bottom: 1.4em;
  }

  ul,
  ol {
    padding-left: 2em;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => shade(0.2, theme.colors.primary)};
    }
  }
`;

export const TicketInfo = styled.ul`
  &::after,
  &::before {
    content: '';
    display: block;
    width: 15%;
    border-top: 1px solid ${({ theme }) => theme.background};
  }

  &::after {
    margin-top: 24px;
  }

  &::before {
    margin-bottom: 24px;
  }

  list-style: none;
  margin-bottom: 24px;

  li {
    display: flex;
    align-items: center;

    > svg {
      color: ${({ theme }) => theme.title};
      margin-right: 8px;
    }

    > a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }

    > button {
      background: none;
      border: none;
      line-height: 1;
      display: flex;
      align-items: center;
      color: #b31aff;
      font-size: 12px;
      margin-left: 8px;

      > svg {
        margin-right: 4px;
      }
    }
  }
`;

export const TicketStatus = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const TicketAnswer = styled.div<TicketAnswerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.alignment === 'left' ? 'flex-start' : 'flex-end'};

  > header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    flex-direction: ${props =>
      props.alignment === 'left' ? 'row' : 'row-reverse'};
    text-align: ${props => props.alignment};

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      ${props =>
        props.alignment === 'left'
          ? css`
              margin-right: 16px;
            `
          : css`
              margin-left: 16px;
            `}
    }

    > div {
      display: flex;
      flex-direction: column;

      small {
        font-size: 14px;
      }
    }
  }

  > div {
    width: 90%;
    position: relative;
    background: ${({ theme }) => theme.backgroundCard};
    border-radius: 8px;
    padding: 24px;
    box-shadow: ${({ theme }) => theme.shadow};
    margin-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid ${({ theme }) => theme.backgroundCard};

      ${props =>
        props.alignment === 'left'
          ? css`
              transform: translateX(-50%);
              left: 24px;
            `
          : css`
              transform: translateX(50%);
              right: 24px;
            `}
    }
  }
`;
