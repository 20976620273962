import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight, MdMoreHoriz } from 'react-icons/md';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Breadcrumbs from '../../containers/Breadcrumbs';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';
import Loader from '../../components/Loader';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import Modal, { ModalHeader } from '../../components/Modal';

import { SectionContainer, SectionBox } from './styles';
import ModalConfirm from '../../components/Modal/Confirm';

interface SectionData {
  id: string;
  name: string;
  description: string;
  slug: string;
}

interface SectionFormData {
  name: string;
  position: number;
  icon: string;
  description: string;
}

const Section: React.FC = () => {
  const { addToast } = useToast();

  const formAddSectionRef = useRef<FormHandles>(null);
  const formEditSectionRef = useRef<FormHandles>(null);

  const [loadingModal, setLoadingModal] = useState(false);

  const [modalAddSection, setModalAddSection] = useState(false);
  const [modalEditSection, setModalEditSection] = useState(false);
  const [modalDelSection, setModalDelSection] = useState(false);

  const [sections, setSections] = useState<SectionData[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionData>(
    {} as SectionData,
  );

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get('/sections');

        setSections(response.data);
      } catch (error) {
        // console.log(error)
      }
    }

    loadData();
  }, []);

  const handleSelectDelSection = (sectionData: SectionData) => {
    setSelectedSection(sectionData);
    setModalDelSection(true);
  };

  const handleSelectEditSection = (sectionData: SectionData) => {
    setSelectedSection(sectionData);
    setModalEditSection(true);
  };

  const handleAddSection = useCallback(
    async (formData: SectionFormData) => {
      try {
        setLoadingModal(true);

        formAddSectionRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          position: Yup.number().min(1).required('Posição obrigatória'),
          icon: Yup.string().required('Ícone obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post('/sections', {
          name: formData.name,
          position: formData.position,
          icon: formData.icon,
          description: formData.description,
        });

        setSections(oldSections => [response.data, ...oldSections]);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A seção foi cadastrada com sucesso!',
        });

        setModalAddSection(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddSectionRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao cadastrar a seção, verifique os campos.',
          type: 'error',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast],
  );

  const handleEditSection = useCallback(
    async (formData: SectionFormData) => {
      try {
        setLoadingModal(true);

        formEditSectionRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          position: Yup.number().min(1).required('Posição obrigatória'),
          icon: Yup.string().required('Ícone obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.put(`/sections/${selectedSection.id}`, {
          name: formData.name,
          position: formData.position,
          icon: formData.icon,
          description: formData.description,
        });

        setSections(oldSections =>
          oldSections.map(oldSection =>
            oldSection.id === selectedSection.id ? response.data : oldSection,
          ),
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'A seção foi atualizada com sucesso!',
        });

        setModalEditSection(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditSectionRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro ao atualizar a seção, verifique os campos.',
          type: 'error',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast, selectedSection.id],
  );

  const handleDelSection = useCallback(async () => {
    try {
      setLoadingModal(true);

      await api.delete(`/sections/${selectedSection.id}`);

      setSections(oldSections =>
        oldSections.filter(oldSection => oldSection.id !== selectedSection.id),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'A seção foi excluída com sucesso!',
      });

      setModalDelSection(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingModal(false);
    }
  }, [addToast, selectedSection.id]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Artigos e Seções"
          items={[{ title: 'Artigos e Seções' }]}
        >
          <Button color="secondary" onClick={() => setModalAddSection(true)}>
            Cadastrar seção
          </Button>
        </Breadcrumbs>

        <SectionContainer>
          {sections.map(section => (
            <SectionBox>
              <div>
                <span>
                  <MdMoreHoriz size={16} />
                </span>

                <div>
                  <ul>
                    <li>
                      <Link to={`/secoes/${section.slug}`}>
                        <MdKeyboardArrowRight size={18} />
                        Ver artigos
                      </Link>
                    </li>

                    <li>
                      <button
                        type="button"
                        onClick={() => handleSelectEditSection(section)}
                      >
                        <MdKeyboardArrowRight size={18} />
                        Alterar
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        onClick={() => handleSelectDelSection(section)}
                      >
                        <MdKeyboardArrowRight size={18} />
                        Excluir
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <h2>{section.name}</h2>

              <p>{section.description}</p>
            </SectionBox>
          ))}
        </SectionContainer>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddSection}
        setIsOpen={() => setModalAddSection(!modalAddSection)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Cadastrar seção</div>

          <button type="button" onClick={() => setModalAddSection(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddSectionRef} onSubmit={handleAddSection} noValidate>
          <Input type="text" label="Nome" name="name" />

          <Input type="number" label="Posição" name="position" />

          <Input type="text" label="Ícone" name="icon" />

          <TextArea label="Descrição" name="description" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar seção
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditSection}
        setIsOpen={() => setModalEditSection(!modalEditSection)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Atualizar seção</div>

          <button type="button" onClick={() => setModalEditSection(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formEditSectionRef}
          initialData={selectedSection}
          onSubmit={handleEditSection}
          noValidate
        >
          <Input type="text" label="Nome" name="name" />

          <Input type="number" label="Posição" name="position" />

          <Input type="text" label="Ícone" name="icon" />

          <TextArea label="Descrição" name="description" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar seção
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir seção"
        confirmText="Excluir"
        cancelText="Cancelar"
        text={`Tem certeza que deseja excluir a seção "${selectedSection.name}"? Essa ação não poderá ser
        desfeita.`}
        onConfirm={handleDelSection}
        isOpen={modalDelSection}
        isLoading={loadingModal}
        setIsOpen={() => setModalDelSection(!modalDelSection)}
      />
    </>
  );
};

export default Section;
