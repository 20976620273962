import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 460px;
  margin: 0 auto;
  margin-top: 80px;
  text-align: center;

  img {
    width: 220px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.backgroundCard};
  padding: 80px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};

  h1 {
    text-align: left;
    line-height: 1.2;
    font-size: 24px;
    margin-bottom: 40px;
  }

  a {
    margin-top: 40px;
    color: ${({ theme }) => theme.text};
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    &:hover {
      text-decoration: none;
    }
  }
`;
