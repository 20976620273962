import { shade } from 'polished';
import styled from 'styled-components';

export const MaterialDescription = styled.div`
  img {
    display: block;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 24px;
    box-shadow: ${({ theme }) => theme.shadow};
  }

  div {
    display: flex;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      font-size: 12px;

      svg {
        margin-right: 8px;
        color: ${({ theme }) => theme.title};
      }

      a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        }
      }
    }
  }
`;

export const DataBox = styled.div`
  position: relative;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;

  span {
    color: ${({ theme }) => theme.title};
    font-size: 32px;
    font-weight: bold;
  }
`;

export const DataList = styled.ul`
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    span {
      color: ${({ theme }) => theme.title};
      font-weight: bold;
      font-size: 18px;
    }
  }
`;
