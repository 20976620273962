import styled, { css } from 'styled-components';

interface ContainerProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'error';
}

const badgeColorVariations = {
  primary: css`
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
  `,
  secondary: css`
    background: ${({ theme }) => theme.colors.secondary};
    color: #fff;
  `,
  tertiary: css`
    background: ${({ theme }) => theme.colors.tertiary};
    color: #fff;
  `,
  info: css`
    background: ${({ theme }) => theme.colors.info};
    color: #fff;
  `,
  warning: css`
    background: ${({ theme }) => theme.colors.warning};
    color: #fff;
  `,
  success: css`
    background: ${({ theme }) => theme.colors.success};
    color: #fff;
  `,
  error: css`
    background: ${({ theme }) => theme.colors.danger};
    color: #fff;
  `,
};

export const Container = styled.span<ContainerProps>`
  ${props => badgeColorVariations[props.color || 'primary']}
  position: relative;
  cursor: default;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  > div {
    position: absolute;
    border-radius: 4px;
    line-height: 1.2;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -100%);
    top: -12px;
    left: 50%;
    background: black;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      transform: translateX(-50%);
    }
  }

  display: inline-block;
  white-space: nowrap;
  padding: 4px 8px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: baseline;
  border-radius: 4px;
`;
