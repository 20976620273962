import React, { useState, useEffect } from 'react';

import { Container } from './styles';

interface ProgressBarProps {
  title: string;
  progress: number;
  total: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  title,
  progress,
  total,
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (!progress) {
      return;
    }

    if (progress === total) {
      setPercentage(100);
      return;
    }

    const newPercentage = (100 * progress) / total;

    setPercentage(newPercentage);
  }, [progress, total]);

  return (
    <Container>
      <p>
        <span>{title}</span>

        <span>{`${progress}/${total}`}</span>
      </p>

      <div>
        <div style={{ width: `${percentage}%` }} />
      </div>
    </Container>
  );
};

export default ProgressBar;
