import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import { MdFileDownload } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useMark } from '../../../hooks/mark';
import { useToast } from '../../../hooks/toast';

import WordingContent from '../../../containers/WordingContent';
import CorrectionGrades from '../../../containers/CorrectionGrades';

import Loader from '../../../components/Loader';
import TextArea from '../../../components/TextArea';

import { Container, WordingTitle } from './styles';

interface LastCorrectionContentProps {
  text: string;
  theme?: {
    name: string;
    material_url: string;
    exam_name: string;
  };
  grades: {
    competence_1: number;
    competence_2: number;
    competence_3: number;
    competence_4: number;
    competence_5: number;
  };
  note?: string;
  initialMarks: any[];
  active?: boolean;
  fontSize?: number;
  marginTop?: number;
}

const LastCorrectionContent: React.FC<LastCorrectionContentProps> = ({
  text,
  theme,
  initialMarks,
  note,
  grades,
  fontSize,
  active = false,
  marginTop = 106,
}) => {
  const { addToast } = useToast();
  const { setInitialMarks } = useMark();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInitialMarks(initialMarks);
  }, [initialMarks, setInitialMarks]);

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    formRef.current.setData({ note, ...grades });
  }, [grades, note]);

  const handleCopyWording = useCallback(() => {
    setLoading(true);

    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        navigator.clipboard.writeText(text).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'A redação foi copiada com sucesso.',
              type: 'success',
            });

            setLoading(false);
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar a redação.',
              type: 'error',
            });

            setLoading(false);
          },
        );
      }
    });
  }, [text, addToast]);

  return (
    <Container active={active} marginTop={marginTop}>
      {loading && <Loader isFixed />}

      <Row>
        <Col md={12}>
          <WordingTitle>
            <div>
              <h2>{theme?.name}</h2>
              <ul>
                <li>
                  <a target="blank" href={theme?.material_url}>
                    <MdFileDownload size={16} />
                    Material de apoio
                  </a>
                </li>
              </ul>
            </div>
          </WordingTitle>

          <WordingContent wordingText={text} fontSize={fontSize} readOnly />
        </Col>

        <Col md={12}>
          <Form ref={formRef} onSubmit={() => false} noValidate>
            <CorrectionGrades disabled />

            <TextArea
              name="note"
              label="Comentários"
              required
              placeholder="Insira um breve comentário final sobre a redação e o que pode ser melhorado."
              disabled
              rows={8}
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LastCorrectionContent;
