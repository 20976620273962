import styled from 'styled-components';

interface ContainerProps {
  isFixed: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: ${props => (props.isFixed ? 'fixed' : 'absolute')};
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => `${theme.backgroundCard}b3`};

  > div {
    display: block;
    height: 32px;
    width: 32px;
    -webkit-animation: loader-2-1 3s linear infinite;
    animation: loader-2-1 3s linear infinite;

    > span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      clip: rect(16px, 32px, 32px, 0);
      -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1)
        infinite;
      animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        border: 3px solid transparent;
        border-top: 3px solid ${({ theme }) => theme.title};
        border-radius: 50%;
        -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1)
          infinite;
        animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        border: 3px solid ${({ theme }) => `${theme.title}80`};
        border-radius: 50%;
      }
    }
  }
  @keyframes loader-2-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-2-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-2-3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
