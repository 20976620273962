import styled, { css } from 'styled-components';

interface ContainerProps {
  hasError: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 16px;

  label {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: 140px;
      padding: 12px 16px;
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      margin-top: 8px;
      color: ${({ theme }) => theme.input.text};

      ${props =>
        props.disabled &&
        css`
          opacity: 0.45;
          cursor: not-allowed;

          input {
            cursor: not-allowed;
          }
        `}

      ${props =>
        props.hasError &&
        css`
          border-color: ${({ theme }) => theme.colors.danger};

          &:not(:focus-within) {
            input {
              color: ${({ theme }) => theme.colors.danger};
            }
          }
        `}

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      &::placeholder {
        color: ${({ theme }) => theme.input.placeholder};
      }
    }
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 14px;
  margin-top: 4px;
  font-weight: normal;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;
