import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdAccessTime, MdLink } from 'react-icons/md';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import Select from '../../../components/Select';

import { MaterialDescription, DataBox } from './styles';
import CreatableSelect from '../../../components/CreatableSelect';

interface MaterialData {
  id: string;
  created_at: string;
  name: string;
  description: string;
  thumbnail_url: string;
}

interface MaterialPreviewFormData {
  name: string;
  category: string;
  description: string;
  exam_name: string;
}

const MaterialPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [material, setMaterial] = useState<MaterialData>({} as MaterialData);
  const [exams, setExams] = useState([]);
  const [categories, setCategories] = useState([]);

  const [materialLoading, setMaterialLoading] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);

  const [materialFile, setMaterialFile] = useState<Blob | string>('');
  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData() {
      setMaterialLoading(true);

      try {
        const [examsResponse, materialResponse] = await Promise.all([
          api.get('/exams/list'),
          api.get(`/materials/${id}`),
        ]);

        const categoriesResponse = await api.get('/materials/categories/list', {
          params: {
            exam_name: materialResponse.data.exam_name,
          },
        });

        setExams(examsResponse.data);
        setCategories(categoriesResponse.data);

        const created_at = format(
          parseISO(materialResponse.data.created_at),
          "dd/MM/yyyy 'às' HH:mm",
          { locale: ptBR },
        );

        setMaterial({ ...materialResponse.data, created_at });

        formRef.current?.setData({
          category: materialResponse.data.category.name,
          exam_name: materialResponse.data.exam_name,
        });

        setMaterialLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O material não foi encontrado!',
        });

        history.push('/materiais');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleSubmit = useCallback(
    async (data: MaterialPreviewFormData) => {
      setMaterialLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          category: Yup.string().required('Categoria obrigatória'),
          exam_name: Yup.string().required('Exame obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);
        formData.append('exam_name', data.exam_name);

        if (materialFile) formData.append('material', materialFile);
        if (thumbnailFile) formData.append('thumbnail', thumbnailFile);

        const updatedMaterialResponse = await api.put<MaterialData>(
          `materials/${id}`,
          formData,
        );

        setMaterial(updatedMaterialResponse.data);

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O material foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setMaterialLoading(false);
      }
    },
    [addToast, id, materialFile, thumbnailFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar material"
          items={[
            { title: 'Materiais', link: '/materiais' },
            { title: 'Visualizar material' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {materialLoading && <Loader />}

              <MaterialDescription>
                <img src={material.thumbnail_url} alt={material.name} />

                <h2>{material.name}</h2>

                <div>
                  <span>
                    <MdAccessTime size={16} />
                    {material.created_at}
                  </span>

                  <span>
                    <MdLink size={16} />
                    <Link
                      to={`/materiais/download/${material.id}`}
                      target="blank"
                    >
                      Link do material
                    </Link>
                  </span>
                </div>
              </MaterialDescription>
            </Card>

            <Row>
              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Avaliações positivas
                  <span>?</span>
                </DataBox>
              </Col>
            </Row>
          </Col>

          <Col md={8}>
            <Card>
              {materialLoading && <Loader />}

              <Form
                ref={formRef}
                initialData={material}
                onSubmit={handleSubmit}
                noValidate
              >
                <Row>
                  <Col md={12}>
                    <Input type="text" name="name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Select name="exam_name" label="Exame" options={exams} />
                  </Col>

                  <Col md={6}>
                    <CreatableSelect
                      name="category"
                      label="Categoria"
                      options={categories}
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Dropzone
                      label="Material"
                      accept=".pdf"
                      maxSize={3 * 1000 * 1000}
                      onDrop={acceptedFile => setMaterialFile(acceptedFile)}
                    />
                  </Col>

                  <Col md={6}>
                    <Dropzone
                      label="Thumbnail"
                      accept="image/*"
                      maxSize={3 * 1000 * 1000}
                      onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar material
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default MaterialPreview;
