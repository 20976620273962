import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { MdAttachMoney, MdEvent } from 'react-icons/md';
import { subYears } from 'date-fns';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Select from '../../../components/Select';
import DatePicker from '../../../components/Datepicker';

interface UserRegistrationFormData {
  first_name: string;
  last_name: string;
  email: string;
  cpf: string;
  birthday: Date;
  phone_number: string;
  role: string;
  price_per_wording: number;
  affiliate_coupon: string;
  bank: string;
  agency: string;
  account: string;
  account_type: string;
  instagram: string;
  tiktok: string;
  youtube: string;
}

const UserRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: UserRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('CPF obrigatório'),
          birthday: Yup.date()
            .max(subYears(new Date(), 18), 'Mínimo de 18 anos')
            .required('Data de nascimento obrigatória')
            .nullable(),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          role: Yup.string().required('Função obrigatória').nullable(),
          affiliate_coupon: Yup.string().when('role', {
            is: 'affiliate',
            then: Yup.string().required('Cupom de afiliado obrigatório'),
            otherwise: Yup.string().nullable(),
          }),
          price_per_wording: Yup.number()
            .nullable()
            .when('role', {
              is: 'corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            })
            .when('role', {
              is: 'super-corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            })
            .when('role', {
              is: 'hyper-corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            }),
          bank: Yup.string(),
          agency: Yup.string(),
          account: Yup.string(),
          account_type: Yup.string().nullable(),
          instagram: Yup.string().nullable(),
          tiktok: Yup.string().nullable(),
          youtube: Yup.string().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        const price_per_wording = data.price_per_wording
          ? data.price_per_wording * 100
          : null;

        const affiliate_coupon = data.affiliate_coupon || null;

        await api.post('users', {
          price_per_wording,
          affiliate_coupon,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          cpf: data.cpf,
          birthday: data.birthday,
          role: data.role,
          bank: data.bank,
          agency: data.agency,
          account: data.account,
          account_type: data.account_type,
          instagram: data.instagram,
          tiktok: data.tiktok,
          youtube: data.youtube,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O usuário foi cadastrado com sucesso!',
        });

        history.push('/usuarios');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar usuário"
          items={[
            { title: 'Usuários', link: '/usuarios' },
            { title: 'Cadastrar usuário' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <h2>Dados pessoais</h2>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      mask=""
                      maskChar={null}
                      type="text"
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <DatePicker
                      icon={MdEvent}
                      name="birthday"
                      label="Data de Nascimento"
                      maxDate={subYears(new Date(), 18)}
                    />
                  </Col>

                  <Col md={3}>
                    <Select
                      name="role"
                      label="Função"
                      options={[
                        { value: 'administrator', label: 'Administrador' },
                        { value: 'coordinator', label: 'Coordenador' },
                        { value: 'affiliate', label: 'Afiliado' },
                        { value: 'corrector', label: 'Corretor N1' },
                        { value: 'super-corrector', label: 'Corretor N2' },
                        { value: 'hyper-corrector', label: 'Corretor N3' },
                      ]}
                    />
                  </Col>

                  <Col md={3}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="price_per_wording"
                      label="Valor por Correção"
                      step="0.05"
                    />
                  </Col>

                  <Col md={3}>
                    <Input
                      type="text"
                      name="affiliate_coupon"
                      label="Cupom de Afiliado"
                    />
                  </Col>
                </Row>

                <h2>Dados bancários</h2>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="bank" label="Banco" />
                  </Col>

                  <Col md={6}>
                    <Select
                      name="account_type"
                      label="Tipo da Conta"
                      options={[
                        { value: 'Conta Corrente', label: 'Conta Corrente' },
                        { value: 'Conta Poupança', label: 'Conta Poupança' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="agency" label="Agência" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="account" label="Conta" />
                  </Col>
                </Row>

                <h2>Redes Sociais</h2>

                <Row>
                  <Col md={4}>
                    <Input type="text" name="instagram" label="Instagram" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="tiktok" label="Tiktok" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="youtube" label="Youtube" />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar usuário
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default UserRegistration;
