import React, { useCallback, useRef } from 'react';

import { useMark } from '../../../hooks/mark';

import { Container } from './styles';

interface MarkProps {
  id: string;
  background: string;
  color: string;
}

const Mark: React.FC<MarkProps> = ({ id, background, color, children }) => {
  const markRef = useRef<HTMLDivElement>(null);

  const { showMark, hideMark, isActive } = useMark();

  const handleClick = useCallback(() => {
    const currentMark = markRef.current;

    if (!currentMark) {
      return;
    }

    if (!isActive(id)) {
      const { left, top, width } = currentMark.getBoundingClientRect();

      const offsetX = left + width / 2;
      const offsetY = top + window.scrollY - 14;

      showMark({ id, offsetX, offsetY });
    } else {
      hideMark(id);
    }
  }, [id, isActive, showMark, hideMark]);

  return (
    <Container
      ref={markRef}
      background={background}
      color={color}
      isActive={isActive(id)}
      onClick={handleClick}
    >
      {children}
    </Container>
  );
};

export default Mark;
