import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { MdDelete } from 'react-icons/md';

import { Mark, useMark } from '../../hooks/mark-photo';

import { MarkPopover } from './styles';

interface MarkPhotoContainerProps {
  marks: Mark[];
}

const MarkPhotoContainer: React.FC<MarkPhotoContainerProps> = ({
  marks,
  children,
}) => {
  const { removeMark } = useMark();

  return (
    <div>
      {children}
      {marks.map(mark => {
        return (
          <MarkPopover
            key={mark.id}
            isActive={mark.isActive}
            style={{ left: `${mark.offsetX}px`, top: `${mark.offsetY}px` }}
          >
            <header>
              {mark.title}
              <div>
                <button type="button" onClick={() => removeMark(mark.id)}>
                  <MdDelete />
                </button>
              </div>
            </header>

            <div>
              <ReactMarkdown
                plugins={[[gfm, { singleTilde: false }]]}
                linkTarget="blank"
                allowedTypes={[
                  'break',
                  'delete',
                  'emphasis',
                  'strong',
                  'text',
                  'paragraph',
                ]}
                source={mark.description}
              />
            </div>
          </MarkPopover>
        );
      })}
    </div>
  );
};

export default MarkPhotoContainer;
