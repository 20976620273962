import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

import { Background, Content } from './styles';

const Footer: React.FC = () => {
  return (
    <Background>
      <Container>
        <Content>
          <p>Poxalulu - todos os direitos reservados</p>

          <ul>
            <li>
              <Link to="/">Termos de Uso</Link>
            </li>
            <li>
              <Link to="/">Política de Privacidade</Link>
            </li>
          </ul>
        </Content>
      </Container>
    </Background>
  );
};

export default Footer;
