import styled from 'styled-components';

export const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};

  & + div {
    margin-top: 24px;
  }

  > img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: 16px;
    transition: opacity 0.2s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  > h2 {
    font-size: 1.4em;
    font-weight: bold;
    color: ${({ theme }) => theme.title};
  }

  > span {
    font-size: 1em;
    font-style: italic;
    margin-left: 24px;
    color: ${({ theme }) => theme.text};
    flex: 1;
  }

  > aside {
    display: flex;
    align-items: center;
  }
`;
