import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import TextArea from '../../../components/TextArea';
import CreatableSelect from '../../../components/CreatableSelect';

interface MaterialRegistrationFormData {
  name: string;
  category: string;
  description: string;
  exam_name: string;
}

const MaterialRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [exams, setExams] = useState([]);
  const [categories, setCategories] = useState([]);

  const [materialFile, setMaterialFile] = useState<Blob | string>('');
  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api.get('/exams/list').then(response => setExams(response.data));
    api
      .get('/materials/categories/list')
      .then(response => setCategories(response.data));
  }, []);

  const handleSubmit = useCallback(
    async (data: MaterialRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          category: Yup.string().required('Categoria obrigatória'),
          exam_name: Yup.string().required('Exame obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);
        formData.append('exam_name', data.exam_name);
        formData.append('material', materialFile);
        formData.append('thumbnail', thumbnailFile);

        await api.post('materials', formData);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O material foi cadastrado com sucesso!',
        });

        history.push('/materiais');
      } catch (err) {
        setLoading(false);

        console.log(err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history, materialFile, thumbnailFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar material"
          items={[
            { title: 'Materiais', link: '/materiais' },
            { title: 'Cadastrar material' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={4}>
                    <Input type="text" name="name" label="Nome" />
                  </Col>

                  <Col md={4}>
                    <Select name="exam_name" label="Exame" options={exams} />
                  </Col>

                  <Col md={4}>
                    <CreatableSelect
                      name="category"
                      label="Categoria"
                      options={categories}
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Dropzone
                      label="Material"
                      accept=".pdf"
                      maxSize={3000000}
                      onDrop={acceptedFile => setMaterialFile(acceptedFile)}
                    />
                  </Col>

                  <Col md={6}>
                    <Dropzone
                      label="Thumbnail"
                      accept="image/*"
                      maxSize={3000000}
                      onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar material
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default MaterialRegistration;
