import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import api from '../../services/api';

import formatDate from '../../utils/formatDate';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import Badge from '../../components/Badge';
import ModalConfirm from '../../components/Modal/Confirm';

interface CouponData {
  id: string;
  code: string;
  discount: number;
  expires_at: string | null;
  active: boolean;
  created_at: string;
}

interface ColumnData {
  label: string;
  field: 'id' | 'code' | 'discount' | 'expires_at' | 'created_at' | 'active';
  transform?(
    value: string | number | boolean | null,
    row?: CouponData,
  ): string | React.ReactElement;
}

const Coupon: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [coupons, setCoupons] = useState<CouponData[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<CouponData>(
    {} as CouponData,
  );

  const columns = useMemo<ColumnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) =>
          formatDate(created_at, "dd/MM/yyyy 'às' HH:mm"),
      },
      {
        label: 'Código',
        field: 'code',
      },
      {
        label: 'Desconto',
        field: 'discount',
        transform: (discount: number) => {
          return new Intl.NumberFormat('en-US', { style: 'percent' }).format(
            discount / 100,
          );
        },
      },
      {
        label: 'Validade',
        field: 'expires_at',
        transform: (expires_at: string | null) =>
          expires_at ? formatDate(expires_at, "dd/MM/yyyy 'às' HH:mm") : '',
      },
      {
        label: 'Status',
        field: 'active',
        transform: (active: boolean) => (
          <Badge
            title={active ? 'Ativo' : 'Inativo'}
            color={active ? 'success' : 'error'}
          />
        ),
      },
      {
        label: '',
        field: 'id',
        transform: (id: string, row: CouponData) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/cupons/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCoupon(row);
                    setDeleteModal(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Inativar
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  const handleDeleteCoupon = useCallback(async () => {
    setLoadingModal(true);

    try {
      const response = await api.delete(`/coupons/${selectedCoupon.id}`);

      setCoupons(oldCoupons =>
        oldCoupons.map(oldCoupon =>
          oldCoupon.id === response.data.id ? response.data : oldCoupon,
        ),
      );

      setDeleteModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingModal(false);
    }
  }, [selectedCoupon.id]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/coupons', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setCoupons(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Cupons" items={[{ title: 'Cupons' }]}>
          <Button
            color="secondary"
            onClick={() => history.push('/cupons/cadastrar')}
          >
            Cadastrar cupom
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {coupons.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!coupons.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum cupom encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        title="Inativar cupom"
        confirmText="Inativar"
        cancelText="Cancelar"
        text={`Tem certeza que deseja inativar o cupom "${selectedCoupon.code}"? Essa ação não poderá ser
        desfeita.`}
        onConfirm={handleDeleteCoupon}
        isOpen={deleteModal}
        isLoading={loadingModal}
        setIsOpen={() => setDeleteModal(!deleteModal)}
      />
    </>
  );
};

export default Coupon;
