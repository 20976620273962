import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdMail, MdArrowBack } from 'react-icons/md';
import * as Yup from 'yup';

import api from '../../services/api';

import logo from '../../assets/logo-poxalulu.svg';

import { Container, Content } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

interface ForgotFormData {
  email: string;
}

const Forgot: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/password/forgot', { email: data.email });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description:
            'Enviamos um link para a redefinição da sua senha por e-mail.',
        });

        history.push('/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha',
          description: 'O e-mail informado não foi encontrado.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <img src={logo} alt="Logo Poxalulu" />

      <Content>
        <h1>Esqueci minha senha</h1>

        <Form ref={formRef} onSubmit={handleSubmit} noValidate>
          <Input
            icon={MdMail}
            type="email"
            name="email"
            label="E-mail"
            placeholder="E-mail"
          />

          <Button type="submit" color="primary" loading={loading}>
            Recuperar senha
          </Button>
        </Form>

        <Link to="/">
          <MdArrowBack size={16} />
          Voltar para o login
        </Link>
      </Content>
    </Container>
  );
};

export default Forgot;
