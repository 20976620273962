import styled from 'styled-components';

export const Container = styled.div`
  .apexcharts-zoom-icon svg,
  .apexcharts-zoomin-icon svg,
  .apexcharts-zoomout-icon svg,
  .apexcharts-reset-icon svg,
  .apexcharts-menu-icon svg {
    fill: ${({ theme }) => theme.text};
  }

  .apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
  .apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
  .apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
  .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
  .apexcharts-zoomin-icon:hover svg,
  .apexcharts-zoomout-icon:hover svg,
  .apexcharts-reset-icon:hover svg,
  .apexcharts-menu-icon:hover svg {
    fill: ${({ theme }) => theme.title};
  }

  .apexcharts-pan-icon svg {
    fill: none;
    stroke: ${({ theme }) => theme.text};
  }

  .apexcharts-pan-icon.apexcharts-selected svg {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  .apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
    stroke: ${({ theme }) => theme.title};
  }
`;
