import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    &, &::after, &::before {
      transition: background 0.2s, border 0.2s, box-shadow 0.2s;
    }
  }

  body, #root, html {
    min-height: 100vh
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  h1 {
    font-size: 48px;
  }
`;
