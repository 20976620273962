import React from 'react';
import { IconBaseProps } from 'react-icons';
import CountUp from 'react-countup';

import { MdHelpOutline } from 'react-icons/md';
import Loader from '../Loader';

import { Container } from './styles';

interface BoxInfoProps {
  title: string;
  subtitle?: string;
  loading?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  value: number;
  decimals?: number;
  prefix?: string;
}

const BoxInfo: React.FC<BoxInfoProps> = ({
  title,
  subtitle,
  loading = false,
  icon: Icon,
  value = 0,
  decimals,
  prefix,
}) => {
  return (
    <Container>
      {loading && <Loader />}
      <h3>
        {title}
        {subtitle && (
          <small>
            <span>Aguardando prazo de devolução</span>
            {subtitle}
          </small>
        )}
      </h3>

      <div>
        <CountUp
          start={0}
          end={value}
          delay={0}
          duration={2}
          decimals={decimals}
          prefix={prefix}
          separator="."
          decimal=","
        >
          {({ countUpRef }) => <span ref={countUpRef} />}
        </CountUp>

        {Icon && <Icon size={40} />}
      </div>
    </Container>
  );
};

export default BoxInfo;
