import React from 'react';

import { useGrades } from '../../hooks/grades';

import Slider from '../../components/Slider';

import { Container } from './styles';

import getCompetence from '../../utils/getCompetenceColor';

interface CorrectionGradesProps {
  disabled?: boolean;
}

const CorrectionGrades: React.FC<CorrectionGradesProps> = ({
  disabled = false,
}) => {
  const { finalGrade, setCompetenceGrade } = useGrades();

  const onSlide = (name: string, value: number) => {
    setCompetenceGrade(name, value);
  };

  return (
    <Container>
      <aside>
        <h2>Nota final</h2>

        <div>{finalGrade}</div>
      </aside>

      <div>
        <Slider
          name="competence_1"
          label="Competência I"
          disabled={disabled}
          min={0}
          max={200}
          color={getCompetence(1).background}
          step={40}
          onSlide={onSlide}
        />

        <Slider
          name="competence_2"
          label="Competência II"
          disabled={disabled}
          min={0}
          max={200}
          color={getCompetence(2).background}
          step={40}
          onSlide={onSlide}
        />

        <Slider
          name="competence_3"
          label="Competência III"
          disabled={disabled}
          min={0}
          max={200}
          color={getCompetence(3).background}
          step={40}
          onSlide={onSlide}
        />

        <Slider
          name="competence_4"
          label="Competência IV"
          disabled={disabled}
          min={0}
          max={200}
          color={getCompetence(4).background}
          step={40}
          onSlide={onSlide}
        />

        <Slider
          name="competence_5"
          label="Competência V"
          disabled={disabled}
          min={0}
          max={200}
          color={getCompetence(5).background}
          step={40}
          onSlide={onSlide}
        />
      </div>
    </Container>
  );
};

export default CorrectionGrades;
