import React from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';

import IconButton from '../../../../components/IconButton';
import Button from '../../../../components/Button';

import { Container } from './styles';

interface ClusterProps {
  title: string;
  thumbnail: string;
  onCreate(): void;
  onUpdate(): void;
  onDelete(): void;
}

const Cluster: React.FC<ClusterProps> = ({
  title,
  thumbnail,
  onCreate,
  onUpdate,
  onDelete,
  children,
}) => {
  return (
    <Container>
      <header>
        <img src={thumbnail} alt={title} />

        <span>{title}</span>

        <Button
          color="primary"
          style={{ marginRight: '16px' }}
          onClick={onCreate}
        >
          Cadastrar aula
        </Button>

        <IconButton icon={MdModeEdit} onClick={onUpdate} />
        <IconButton icon={MdDelete} onClick={onDelete} />
      </header>

      <section>{children}</section>
    </Container>
  );
};

export default Cluster;
