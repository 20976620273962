import React, { useRef, useEffect } from 'react';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';

import { MdErrorOutline } from 'react-icons/md';
import { Container, ReactSelectElement, Error } from './styles';

interface Option {
  label: string;
  value: string;
}

interface GroupOption {
  label: string;
  options: Option[];
}

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  icon?: React.ComponentType<IconBaseProps>;
  options: Option[] | GroupOption[];
  disabled?: boolean;
  selectedValue?: string;
}

const Select: React.FC<Props> = ({
  name,
  label,
  icon: Icon,
  options,
  disabled = false,
  defaultValue,
}) => {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.value) {
          return null;
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        if (value) {
          const selectOptions: Option[] = ref.props.options;
          const selectedOption = selectOptions.find(
            option => option.value === value,
          );

          if (selectedOption) {
            ref.select.selectOption(selectedOption);
          }
        }
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          <ReactSelectElement
            ref={selectRef}
            selected={defaultValue}
            placeholder="Selecione..."
            classNamePrefix="react-select"
            options={options}
            noOptionsMessage={() => 'Nenhuma opção encontrada'}
            isClearable
            isDisabled={disabled}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};
export default Select;
