import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdClose, MdThumbDown, MdThumbUp } from 'react-icons/md';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useMark } from '../../hooks/mark';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';

import MarkEditor from '../../components/MarkEditor';
import Button from '../../components/Button';
import Modal, { ModalHeader } from '../../components/Modal';
import Select from '../../components/Select';
import Loader from '../../components/Loader';

import Mark from './Mark';

import { Container, MarkSelection, SelectedText } from './styles';
import getCompetenceColor from '../../utils/getCompetenceColor';

interface WordingContentProps {
  wordingText: string;
  fontSize?: number;
  readOnly?: boolean;
}

interface IMarkPosition {
  from_index: number;
  to_index: number;
  selectedText: string;
}

interface ICorrectionMark {
  id: string;
  category_id?: string;
  type: 'negative' | 'positive';
  background: string;
  color: string;
  title: string;
  description: string;
  from_index: number;
  to_index: number;
}

interface IMark {
  value: string;
  label: string;
  competence: 1 | 2 | 3 | 4 | 5;
}

interface MarkOptions {
  label: string;
  options: IMark[];
}

interface WordingContent {
  content: string;
  mark?: ICorrectionMark;
}

interface Wording {
  id: string;
  contents: WordingContent[];
}

interface MarkFormData {
  category_id: string;
  description: string;
}

interface Color {
  color: string;
  background: string;
}

const WordingContent: React.FC<WordingContentProps> = ({
  wordingText,
  fontSize = 18.5,
  readOnly = false,
}) => {
  const wordingRef = useRef<HTMLDivElement>(null);
  const formAddMarkRef = useRef<FormHandles>(null);

  const { marks, addMark } = useMark();
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [addMarkModalOpen, setAddMarkModalOpen] = useState(false);

  const [wording, setWording] = useState<Wording[]>([]);

  const [markPosition, setMarkPosition] = useState<IMarkPosition>(
    {} as IMarkPosition,
  );
  const [markType, setMarkType] = useState<'positive' | 'negative'>('negative');

  const [markCategories, setMarkCategories] = useState<IMark[]>([]);
  const [markCategoriesOptions, setMarkCategoriesOptions] = useState<
    MarkOptions[]
  >([]);

  const handleAddMark = useCallback(
    async (formData: MarkFormData) => {
      setLoadingModal(true);

      formAddMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          category_id:
            markType === 'negative'
              ? Yup.string().required('Categoria obrigatória').nullable()
              : Yup.string(),
          description:
            markType === 'negative'
              ? Yup.string()
              : Yup.string().required('Comentário obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        setLoadingModal(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro da marcação',
          description:
            'Ocorreu um erro no cadastro da marcação, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }

      const { category_id, description } = formData;
      const { from_index, to_index } = markPosition;

      let competence = 0;
      let title = 'Marcação positiva';

      if (markType === 'negative') {
        const markCategory = markCategories.find(
          findMarkCategory => findMarkCategory.value === category_id,
        );

        if (markCategory) {
          competence = markCategory.competence;
          title = markCategory.label;
        }
      }

      const competenceColor = getCompetenceColor(competence);

      addMark({
        from_index,
        to_index,
        background: competenceColor.background,
        color: competenceColor.color,
        title,
        description,
        type: markType,
      });

      setAddMarkModalOpen(false);
    },
    [markPosition, markType, markCategories, addMark, addToast],
  );

  const deselection = useCallback((): void => {
    const getSelection = window.getSelection();

    if (getSelection) {
      if (getSelection.empty) {
        getSelection.empty();
      } else if (getSelection.removeAllRanges) {
        getSelection.removeAllRanges();
      }
    }

    wordingRef.current?.blur();
  }, []);

  const validateMarker = useCallback(
    (range: Range): boolean => {
      if (range.toString().length < 3) {
        deselection();
        return false;
      }

      if (range.commonAncestorContainer.parentElement) {
        if (range.commonAncestorContainer.parentElement.classList.length > 0) {
          deselection();
          return false;
        }
      }

      if (range.cloneContents().querySelectorAll('div').length > 0) {
        deselection();
        return false;
      }

      return true;
    },
    [deselection],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<IMark[]>('/marks/list');

        setMarkCategories(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        addToast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
          type: 'error',
        });

        history.push('/');
      }
    }

    loadData();
  }, [addToast, history]);

  useEffect(() => {
    const groupedMarkCategories: MarkOptions[] = [];

    markCategories.forEach(markCategory => {
      const competence = `Competência ${markCategory.competence}`;

      const groupIndex = groupedMarkCategories.findIndex(
        groupedMarkCategory => groupedMarkCategory.label === competence,
      );

      if (groupIndex === -1) {
        groupedMarkCategories.push({
          label: competence,
          options: [markCategory],
        });
      } else {
        groupedMarkCategories[groupIndex].options.push(markCategory);
      }
    });

    setMarkCategoriesOptions(groupedMarkCategories);
  }, [markCategories]);

  useEffect(() => {
    if (!readOnly) {
      const currentWording = wordingRef.current;

      if (currentWording) {
        const preventDefault = (event: Event) => {
          event.preventDefault();
          return false;
        };

        currentWording.addEventListener('keypress', preventDefault);
        currentWording.addEventListener('keydown', preventDefault);
        currentWording.addEventListener('paste', preventDefault);
        currentWording.addEventListener('drop', preventDefault);
        currentWording.addEventListener('focus', preventDefault);
        currentWording.addEventListener('input', preventDefault);
        currentWording.addEventListener('contextmenu', preventDefault);
      }
    }
  }, [readOnly]);

  useEffect(() => {
    if (wordingText) {
      const tempWording: Wording[] = [];
      const tempMarks = marks;

      const splitByParagraph = wordingText.split('\n');
      const removeSpaces = splitByParagraph.map(p => p.trim());

      const newText = removeSpaces.join('\n');

      const tempTexts = [newText];

      let provisoryText: string[] = [];

      tempMarks.forEach(tempMark => {
        let realStartIndex = 0;
        let indexPosition = 0;

        tempTexts.every((tempText, index) => {
          if (tempText.startsWith('[mark=') || tempText === '[/mark]') {
            return true;
          }

          if (realStartIndex + tempText.length > tempMark.from_index) {
            const startIndex = tempMark.from_index - realStartIndex;
            const endIndex =
              startIndex + (tempMark.to_index - tempMark.from_index);

            indexPosition = index;

            provisoryText = [
              tempText.slice(0, startIndex),
              `[mark=${tempMark.id}]`,
              tempText.slice(startIndex, endIndex),
              `[/mark]`,
              tempText.slice(endIndex),
            ];

            return false;
          }

          realStartIndex += tempText.length;
          return true;
        });

        tempTexts.splice(indexPosition, 1, ...provisoryText);
      });

      const paragraphs = tempTexts.join('').split('\n');

      paragraphs.forEach(paragraph => {
        const splittedParagraph = paragraph.split(
          /(\[mark=.+?\].+?\[\/mark])/gi,
        );

        const contents: WordingContent[] = [];

        splittedParagraph.forEach(part => {
          const tempContent: WordingContent = {
            content: part,
          };

          const splittedParts = part.split(/\[mark=(.+?)\](.+?)\[\/mark]/);

          if (splittedParts.length > 1) {
            const [id, text] = splittedParts.filter(item => item);

            tempContent.content = text;

            const findMark = marks.find(mark => mark.id === id);

            if (findMark) {
              tempContent.mark = findMark;
            }
          }

          contents.push(tempContent);
        });

        tempWording.push({
          id: uuid(),
          contents,
        });
      });

      setWording(tempWording);
    }
  }, [wordingText, marks]);

  useEffect(() => {
    if (!readOnly) {
      if (!wordingRef.current) {
        return;
      }

      const currentWording = wordingRef.current;

      currentWording.addEventListener('mouseup', (event: MouseEvent): void => {
        if (event.button === 0) {
          event.preventDefault();

          // pega o conteúdo da seleção do mouse
          const selection = window.getSelection();

          if (!selection || !selection.rangeCount) {
            return;
          }

          const range = selection.getRangeAt(0);
          const rangeCount = range.endOffset - range.startOffset;

          // verifica se não selecionou nada e aplica a "deseleção"
          if (!rangeCount) {
            deselection();
            return;
          }

          // valida a seleção
          if (!validateMarker(range)) {
            return;
          }

          const paragraphs = [
            ...Array.prototype.slice.call(currentWording.children),
          ] as HTMLElement[];

          const startParagraph = range.startContainer.parentElement;
          const endParagraph = range.endContainer.parentElement;

          if (!startParagraph || !endParagraph) {
            return;
          }

          // pega o indice do paragrafo que foi selecionado
          const startParagraphIndex = paragraphs.indexOf(startParagraph);

          const start = range.startOffset;

          const selectedText = selection.toString();

          let offset = 0;
          paragraphs.every((paragraph, index) => {
            if (index) {
              // Faz-se necessário por conta das quebras de linha de parágrafos
              offset += 1;
            }

            // achei o parágrafo
            if (index === startParagraphIndex) {
              const childNodes = Array.prototype.slice.call(
                paragraph.childNodes,
              );

              childNodes.every(childNode => {
                const childContent =
                  childNode.nodeType === document.ELEMENT_NODE
                    ? childNode.innerHTML
                    : childNode.wholeText;

                if (childNode === range.startContainer) {
                  offset += start;

                  return false;
                }

                offset += childContent.length;

                return true;
              });

              return false;
            }

            offset += paragraph.innerText.length;

            return true;
          });

          setMarkPosition({
            from_index: offset,
            to_index: offset + selectedText.length,
            selectedText,
          });

          deselection();

          setAddMarkModalOpen(true);
        }
      });
    }
  }, [deselection, validateMarker, readOnly]);

  return (
    <>
      <Container fontSize={fontSize}>
        {loading && <Loader isFixed />}
        <aside>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
          <div>11</div>
          <div>12</div>
          <div>13</div>
          <div>14</div>
          <div>15</div>
          <div>16</div>
          <div>17</div>
          <div>18</div>
          <div>19</div>
          <div>20</div>
          <div>21</div>
          <div>22</div>
          <div>23</div>
          <div>24</div>
          <div>25</div>
          <div>26</div>
          <div>27</div>
          <div>28</div>
          <div>29</div>
          <div>30</div>
        </aside>

        <div ref={wordingRef} contentEditable={!readOnly} spellCheck="true">
          {wording.map(paragraph => {
            return (
              <div key={paragraph.id} style={{ whiteSpace: 'pre-wrap' }}>
                {paragraph.contents.map(content => {
                  if (content.mark) {
                    return (
                      <Mark
                        key={content.mark.id}
                        id={content.mark.id}
                        background={content.mark.background}
                        color={content.mark.color}
                      >
                        {content.content}
                      </Mark>
                    );
                  }

                  return content.content;
                })}
              </div>
            );
          })}
        </div>
      </Container>

      <Modal
        isOpen={addMarkModalOpen}
        setIsOpen={() => setAddMarkModalOpen(!addMarkModalOpen)}
      >
        {loadingModal && <Loader />}

        <ModalHeader>
          <div>Adicionar Marcação</div>

          <button type="button" onClick={() => setAddMarkModalOpen(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <SelectedText>{markPosition.selectedText}</SelectedText>

        <Form ref={formAddMarkRef} onSubmit={handleAddMark} noValidate>
          <MarkSelection markType={markType}>
            <button type="button" onClick={() => setMarkType('negative')}>
              <MdThumbDown size={16} />
              Marcação Negativa
            </button>

            <button type="button" onClick={() => setMarkType('positive')}>
              <MdThumbUp size={16} />
              Marcação Positiva
            </button>
          </MarkSelection>

          {markType === 'negative' && (
            <Select
              name="category_id"
              label="Marcação"
              options={markCategoriesOptions}
            />
          )}

          <MarkEditor name="description" label="Comentário" />

          <footer>
            <Button type="submit" color="primary">
              Adicionar marcação
            </Button>
          </footer>
        </Form>
      </Modal>
    </>
  );
};

export default WordingContent;
