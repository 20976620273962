import React, { useRef, useState, useEffect } from 'react';
import { useField } from '@unform/core';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import { MdErrorOutline } from 'react-icons/md';
import gfm from 'remark-gfm';

import { Container, Error as ErrorComponent } from './styles';

interface MarkEditorProps {
  name: string;
  label: string;
  defaultValue?: string;
}

const MarkEditor: React.FC<MarkEditorProps> = ({
  name,
  label,
  defaultValue = '',
}) => {
  const editorRef = useRef(null);

  const [content, setContent] = useState(defaultValue);

  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: editorRef.current,
      setValue(_: any, value: string) {
        setContent(value);
      },
      getValue(): string {
        return content;
      },
      clearValue(): void {
        setContent('');
      },
    });
  }, [fieldName, registerField, content]);

  return (
    <Container hasError={!!error} style={{ marginBottom: '24px' }}>
      <label htmlFor={name}>{label}</label>
      <ReactMde
        ref={editorRef}
        value={content}
        onChange={setContent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={[['bold', 'italic', 'strikethrough']]}
        generateMarkdownPreview={markdown => {
          return Promise.resolve(
            <ReactMarkdown
              plugins={[[gfm, { singleTilde: false }]]}
              linkTarget="blank"
              allowedTypes={[
                'break',
                'delete',
                'emphasis',
                'strong',
                'text',
                'paragraph',
              ]}
              renderers={{
                image: ({ alt, src, title }) => (
                  <img
                    src={src}
                    alt={alt}
                    title={title}
                    style={{
                      maxHeight: 300,
                      display: 'block',
                      borderRadius: 4,
                      margin: 'auto',
                      cursor: 'pointer',
                      boxShadow: 'rgba(12, 11, 14, 0.27) 0px 4px 12px',
                    }}
                  />
                ),
              }}
              source={markdown}
            />,
          );
        }}
        l18n={{
          write: 'Escrever',
          preview: 'Visualizar',
          pasteDropSelect:
            'Anexe arquivos arrastando, clicando ou colando aqui. (Máx.: 1MB).',
          uploadingImage: 'Carregando imagem...',
        }}
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />

      {error && (
        <ErrorComponent>
          <MdErrorOutline size={16} />
          {error}
        </ErrorComponent>
      )}
    </Container>
  );
};

export default MarkEditor;
