import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.info};
  position: relative;
  color: #fff;
  border-radius: 8px;
  padding: 24px;
  min-height: 160px;
  margin-bottom: 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    & {
      min-height: 0;
      flex-direction: row;
      align-items: center;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1;
    display: flex;
    justify-content: space-between;

    small {
      color: yellow;
      font-size: 12px;
      position: relative;
      cursor: help;

      &:hover > span {
        visibility: visible;
        opacity: 1;
      }

      > span {
        color: white;
        position: absolute;
        border-radius: 4px;
        line-height: 1.5;
        padding: 8px 8px;
        font-size: 12px;
        font-weight: bold;
        transform: translate(-50%, -100%);
        top: -12px;
        left: 50%;
        background: black;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s;
        width: 100px;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid black;
          transform: translateX(-50%);
        }
      }
    }
  }

  div {
    display: flex;
    align-items: center;

    span {
      font-size: 32px;
      font-weight: bold;
      line-height: 1;
      flex: 1;
    }

    svg {
      color: ${({ theme }) => shade(0.4, theme.colors.info)};

      @media (max-width: 767px) {
        & {
          display: none;
        }
      }
    }
  }
`;
