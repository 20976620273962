import { shade } from 'polished';
import styled from 'styled-components';

export const FilterDate = styled.div`
  text-align: left;
  margin-top: 40px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  max-width: 400px;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    > div {
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      padding: 12px 16px;
      margin-top: 8px;
      position: relative;

      display: flex;
      align-items: center;

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      > svg {
        margin-right: 16px;
        color: ${({ theme }) => theme.input.icon};
        flex: none;
      }

      input {
        flex: 1;
        width: 100%;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.input.text};

        &::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }
      }
    }
  }
`;

export const Table = styled.ul`
  margin-bottom: 20px;

  p {
    text-align: center;
    margin-top: 20px;
  }

  li {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => shade(0.2, theme.colors.primary)};
      }
    }

    &:first-child {
      color: ${({ theme }) => theme.title};
      font-weight: bold;
      padding: 0 16px;
    }

    &:not(:first-child) {
      margin-top: 12px;
      background: ${({ theme }) => theme.background};
      border-radius: 8px;
      padding: 12px 16px;
    }
  }
`;
