import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

type SliderProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  min: number;
  max: number;
  color: string;
  disabled?: boolean;
  onSlide(name: string, value: number): void;
};

const Slider: React.FC<SliderProps> = ({
  label,
  name,
  min,
  max,
  color,
  disabled = false,
  onSlide,
  ...rest
}) => {
  const sliderRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  const [value, setValue] = useState(0);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: sliderRef.current,
      getValue: (ref: any) => {
        return ref.valueAsNumber;
      },
      setValue: (ref: any, initialValue: any) => {
        if (initialValue) {
          setValue(initialValue);
          ref.value = initialValue;
        }
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setPosition(((value - min) / (max - min)) * 100);
  }, [value, min, max]);

  return (
    <Container color={color} disabled={disabled}>
      <div>
        <div style={{ left: `Calc(${position}%)` }}>{value}</div>
      </div>
      <input
        ref={sliderRef}
        name={name}
        type="range"
        min={min}
        max={max}
        disabled={disabled}
        defaultValue={defaultValue || 0}
        onChange={event => {
          onSlide(event.target.name, event.target.valueAsNumber);
          setValue(event.target.valueAsNumber);
        }}
        {...rest}
      />
      <div>{label}</div>
    </Container>
  );
};

export default Slider;
