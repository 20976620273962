import { shade } from 'polished';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionBox = styled.div`
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow};

  div {
    position: absolute;
    top: 16px;
    right: 16px;

    span {
      cursor: pointer;
      background: none;
      border: none;
      color: ${({ theme }) => theme.text};
      transition: background 0.2s ease, color 0.2s ease;

      &:hover {
        color: ${({ theme }) => theme.title};

        & + div {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    div {
      opacity: 0;
      visibility: hidden;
      width: 150px;
      position: absolute;
      top: -8px;
      right: 0;
      transform: translateY(-100%);
      background: ${({ theme }) => theme.backgroundCard};
      box-shadow: ${({ theme }) => theme.shadow};
      padding: 16px 0;
      border-radius: 8px;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        visibility: visible;
      }

      ul {
        list-style: none;

        a,
        button {
          border: none;
          background: none;
          padding: 8px 16px;
          color: ${({ theme }) => theme.text};
          text-decoration: none;
          display: flex;
          width: 100%;
          align-items: center;
          transition: padding 0.2s;

          &:hover {
            color: ${({ theme }) => theme.colors.primary};
            background: ${({ theme }) =>
              shade(theme.hoverPercentage, theme.backgroundCard)};
            padding-left: 24px;
          }

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.title};
    margin-bottom: 16px;
  }
`;
