import styled, { css } from 'styled-components';

interface ContainerProps {
  isDragging: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  border: 2px dashed transparent;
  padding: 12px 24px;
  transition: none;
  cursor: grab;

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed ${({ theme }) => theme.colors.primary};
      cursor: grabbing;
      transition: none;
      background: transparent;
      box-shadow: none;

      * {
        opacity: 0;
      }
    `};

  & + div {
    margin-top: 12px;
  }

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  > span {
    display: flex;
    align-items: center;
    flex: 1;

    > a {
      display: flex;
      align-items: center;
      line-height: 1;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 12px;
      margin-left: 8px;
      text-decoration: none;

      > svg {
        margin-right: 4px;
      }
    }
  }

  > aside {
    display: flex;
    margin-left: 16px;
  }
`;
