import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { MdAccessTime } from 'react-icons/md';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';
import formatDate from '../../../utils/formatDate';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import TextArea from '../../../components/TextArea';
import Badge from '../../../components/Badge';

import { DataBox, AchievementDescription } from './styles';
import SwitchButton from '../../../components/SwitchButton';

interface AchievementData {
  id: string;
  icon: string;
  title: string;
  description: string;
  exp: number;
  key: string;
  requirement: string;
  is_hidden: boolean;
  icon_url: string;
  created_at: string;
  updated_at: string;
}

interface AchievementUpdationFormData {
  title: string;
  description: string;
  key: string;
  exp: number;
  requirement: string;
  is_hidden: boolean;
}

const AchievementUpdation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [achievement, setAchievement] = useState<AchievementData>(
    {} as AchievementData,
  );
  const [iconFile, setCoverFile] = useState<Blob | string>('');

  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [statisticsLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const response = await api.get(`achievements/${id}`);

        setIsHidden(response.data.is_hidden);
        setAchievement(response.data);

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O conquista não foi encontrado!',
        });

        history.push('/conquistas');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleSubmit = useCallback(
    async (data: AchievementUpdationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          key: Yup.string().required('Chave obrigatória'),
          exp: Yup.number().required('Experiência obrigatória').nullable(),
          requirement: Yup.string().required('Pré-requisito obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('key', data.key);
        formData.append('exp', String(data.exp));
        formData.append('requirement', data.requirement);
        formData.append('is_hidden', String(isHidden));
        iconFile && formData.append('icon', iconFile);

        const response = await api.put(`achievements/${id}`, formData);

        setAchievement(response.data);

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'A conquista foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, id, iconFile, isHidden],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar conquista"
          items={[
            { title: 'Conquistas', link: '/conquistas' },
            { title: 'Visualizar conquista' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {loading && <Loader />}

              <AchievementDescription>
                <img src={achievement.icon_url} alt={achievement.title} />

                <h2>{achievement.title}</h2>

                <div>
                  <span>
                    <MdAccessTime size={16} />
                    {achievement.created_at &&
                      formatDate(
                        achievement.created_at,
                        "dd/MM/yyyy 'às' hh:mm",
                      )}
                  </span>

                  <span>
                    <Badge title={`+${achievement.exp}`} color="success" />
                  </span>
                </div>
              </AchievementDescription>
            </Card>

            <Row>
              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Conquistas alcançadas
                  <span>?</span>
                </DataBox>
              </Col>
            </Row>
          </Col>

          <Col md={8}>
            <Card>
              {loading && <Loader />}
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={achievement}
                noValidate
              >
                <Row>
                  <Col md={4}>
                    <Input type="text" name="title" label="Título" />
                  </Col>

                  <Col md={4}>
                    <Input type="text" name="key" label="Chave" />
                  </Col>

                  <Col md={4}>
                    <Input type="number" name="exp" label="Experiência" />
                  </Col>

                  <Col md={12}>
                    <SwitchButton
                      label="Oculto"
                      active={isHidden}
                      onClick={() => setIsHidden(!isHidden)}
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>

                  <Col md={12}>
                    <TextArea name="requirement" label="Pré-requisito" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Dropzone
                      label="Ícone"
                      accept="image/*"
                      maxSize={3000000}
                      onDrop={acceptedFile => setCoverFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar conquista
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default AchievementUpdation;
