import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  > aside {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    color: ${({ theme }) => shade(0.1, theme.title)};
    margin-bottom: 24px;

    div {
      font-size: 32px;
      font-weight: bold;
    }
  }

  > div {
    display: flex;
    grid-auto-flow: column;
    gap: 16px;
  }
`;
