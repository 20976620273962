import React, { useEffect } from 'react';

import { Container } from './styles';

interface SwitchButtonProps {
  active: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md';
  label?: string;
  pulse?: boolean;
  onClick?(): void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  active,
  label,
  size = 'md',
  disabled = false,
  pulse = false,
  onClick,
}) => {
  return (
    <>
      <Container active={active} size={size} disabled={disabled} pulse={pulse}>
        <label>{label}</label>
        <button
          type="button"
          style={{ marginBottom: label && '16px' }}
          onClick={onClick}
        >
          <div />
        </button>
      </Container>
    </>
  );
};

export default SwitchButton;
