import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import WordingContent from '../../../../containers/WordingContent';
import { useMark } from '../../../../hooks/mark';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

interface TextContentProps {
  correction_id: string;
  text: string;
  font_size: number;
}

const TextContent: React.FC<TextContentProps> = ({
  correction_id,
  text,
  font_size,
}) => {
  const { setInitialMarks } = useMark();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/corrections/${correction_id}/marks`);

        setInitialMarks(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro.',
        });

        history.push('/redacoes');
      }
    }

    correction_id && loadData();
  }, [correction_id, addToast, history, setInitialMarks]);

  return <WordingContent wordingText={text} readOnly fontSize={font_size} />;
};

export default TextContent;
