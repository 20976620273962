import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons/lib';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker.css';

import { MdErrorOutline } from 'react-icons/md';
import { Container, Error } from './styles';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label: string;
  icon?: React.ComponentType<IconBaseProps>;
}
const DatePicker: React.FC<Props> = ({ label, name, icon: Icon, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(defaultValue || null);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      setValue: (ref: any, value: any) => {
        if (value) {
          ref.setSelected(new Date(value));
        }
      },
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error}>
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          <ReactDatePicker
            ref={datepickerRef}
            selected={date}
            onChange={setDate}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            {...rest}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};
export default DatePicker;
