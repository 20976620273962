import styled from 'styled-components';
import { shade } from 'polished';

interface MenuProps {
  active: boolean;
}

export const Container = styled.div`
  position: relative;
  padding: 16px;
  cursor: pointer;
  z-index: 999;

  &:hover {
    background: ${({ theme }) =>
      shade(theme.hoverPercentage, theme.backgroundCard)};
  }
`;

export const Content = styled.section`
  display: flex;
  align-items: center;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;

    @media (max-width: 767px) {
      & {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    line-height: 1.5;

    span {
      font-size: 14px;
    }

    @media (max-width: 575px) {
      & {
        display: none;
      }
    }
  }
`;

export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: Calc(100% + 16px);
  right: 0;
  width: 240px;
  border-radius: 8px;
  padding: 8px 0;
  background: ${({ theme }) => theme.backgroundCard};
  display: ${props => (props.active ? 'block' : 'none')};
  box-shadow: ${({ theme }) => theme.shadow};
  z-index: 99;

  @media (max-width: 575px) {
    & {
      width: 100vw;
      right: -15px;
      top: Calc(100% + 1px);
    }
  }

  @media (min-width: 576px) {
    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: Calc(50% - 4px);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-bottom: 6px solid ${({ theme }) => theme.backgroundCard};
    }
  }

  ul {
    list-style: none;

    li {
      a,
      > button {
        border: none;
        background: none;
        padding: 8px 16px;
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        display: flex;
        width: 100%;
        align-items: center;

        &:hover {
          background: ${({ theme }) =>
            shade(theme.hoverPercentage, theme.backgroundCard)};
        }

        svg {
          margin-right: 8px;
        }

        span {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
`;
