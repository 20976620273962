import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container } from 'styled-bootstrap-grid';
import { MdDeleteForever } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import Breadcrumbs from '../../../../containers/Breadcrumbs';
import { useToast } from '../../../../hooks/toast';

import Footer from '../../../../containers/Footer';
import Header from '../../../../containers/Header';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Editor from '../../../../components/Editor';
import Input from '../../../../components/Input';
import Card from '../../../../components/Card';
import SwitchButton from '../../../../components/SwitchButton';
import Loader from '../../../../components/Loader';
import ModalConfirm from '../../../../components/Modal/Confirm';

interface SectionData {
  name: string;
}

interface ArticleUpdationFormData {
  title: string;
  body: string;
  is_promoted: boolean;
}

const ArticlePreview: React.FC = () => {
  const { section_slug, article_slug } = useParams<{
    section_slug: string;
    article_slug: string;
  }>();

  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [section, setSection] = useState({} as SectionData);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isPromoted, setIsPromoted] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const sectionResponse = await api.get(`/sections/${section_slug}`);

        setSection(sectionResponse.data);

        const response = await api.get(
          `/sections/${section_slug}/articles/${article_slug}`,
        );

        setIsPromoted(response.data.is_promoted);
        formRef.current?.setData(response.data);

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Artigo não encontrado',
          description: 'Desculpe, nós não conseguimos encontrar esse artigo.',
        });

        history.push(`/secoes/${section_slug}`);
      }
    }

    loadData();
  }, [section_slug, article_slug, history, addToast]);

  const handleEditArticle = useCallback(
    async (formData: ArticleUpdationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          body: Yup.string().required('Artigo obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`/sections/${section_slug}/articles/${article_slug}`, {
          title: formData.title,
          body: formData.body,
          is_promoted: isPromoted,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O artigo foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [isPromoted, addToast, section_slug, article_slug],
  );

  const handleDeleteArticle = useCallback(async () => {
    try {
      setLoadingModal(true);

      await api.delete(`/sections/${section_slug}/articles/${article_slug}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O artigo foi excluído com sucesso!',
      });

      history.push(`/secoes/${section_slug}`);
    } catch (error) {
      setLoadingModal(false);

      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    }
  }, [section_slug, article_slug, addToast, history]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar artigo"
          items={[
            { title: 'Seções', link: '/secoes' },
            { title: section.name, link: `/secoes/${section_slug}` },
            { title: 'Visualizar artigo' },
          ]}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => setModalIsOpen(true)}
          >
            <MdDeleteForever />
            Excluir artigo
          </Button>
        </Breadcrumbs>

        <Card>
          <Form ref={formRef} onSubmit={handleEditArticle} noValidate>
            {loading && <Loader />}
            <Input label="Título" name="title" />

            <SwitchButton
              label="Em destaque"
              active={isPromoted}
              onClick={() => setIsPromoted(!isPromoted)}
            />

            <Editor name="body" minEditorHeight={400} />

            <Button type="submit" color="primary">
              Alterar artigo
            </Button>
          </Form>
        </Card>
      </Container>

      <Footer />

      <ModalConfirm
        title="Excluir artigo"
        text="Tem certeza que deseja excluir o artigo? Essa ação não poderá ser desfeita."
        isOpen={modalIsOpen}
        setIsOpen={() => setModalIsOpen(false)}
        cancelText="Cancelar"
        confirmText="Excluir artigo"
        isLoading={loadingModal}
        onConfirm={handleDeleteArticle}
      />
    </>
  );
};

export default ArticlePreview;
