import React, { useCallback, useEffect, useState } from 'react';
import { MdStar, MdStarHalf, MdStarBorder } from 'react-icons/md';

import { Container } from './styles';

interface RatingProps {
  min: number;
  max: number;
  value: number;
  numberOfStars?: number;
  color?: string;
  readOnly?: boolean;
}

const Rating: React.FC<RatingProps> = ({
  min,
  max,
  value,
  numberOfStars = 5,
  color = '#ffcc00',
  readOnly = false,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [currentMin, setCurrentMin] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);
  const [stars, setStars] = useState<JSX.Element[]>([]);

  const getStars = useCallback(() => {
    const previewStars = [];

    for (let i = 1; i <= numberOfStars; i += 1) {
      let star = <MdStarBorder color={color} size={16} />;

      if (i <= currentValue) {
        star = <MdStar color={color} size={16} />;
      } else if (i < currentValue + 1) {
        if (currentValue % 1) {
          star = <MdStarHalf color={color} size={16} />;
        }
      }

      previewStars.push(star);
    }

    setStars(previewStars);
  }, [numberOfStars, color, currentValue]);

  useEffect(() => {
    const proportion = max / numberOfStars;

    const realMin = min > 0 ? min / proportion : min;
    const realMax = max > 0 ? max / proportion : max;
    const realValue = value > 0 ? value / proportion : value;

    setCurrentMin(realMin);
    setCurrentMax(realMax);

    if (realValue < realMin) {
      setCurrentValue(realMin);
    } else if (realValue > realMax) {
      setCurrentValue(realMax);
    } else {
      setCurrentValue(realValue);
    }

    getStars();
  }, [value, max, min, numberOfStars, getStars]);

  return (
    <Container>
      <div>{currentValue.toFixed(1)}</div>

      {stars.map(star => star)}
    </Container>
  );
};

export default Rating;
