import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface ContainerProps {
  background: string;
  color: string;
  isMoving: boolean;
  readOnly: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  border-radius: 4px;
  background: ${({ background }) => transparentize(0.4, background)};
  color: ${({ color }) => color};
  ${({ readOnly, isMoving }) => {
    if (readOnly) {
      return css`
        cursor: pointer;
      `;
    }

    return css`
      cursor: ${isMoving ? 'grabbing' : 'grab'};
    `;
  }}
`;
