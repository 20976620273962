import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MdArchive, MdGavel } from 'react-icons/md';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';

import { useToast } from '../../../hooks/toast';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';

import { Profile, Actions, ListItem, BreakLine, Title } from './styles';
import formatDate from '../../../utils/formatDate';
import Loader from '../../../components/Loader';
import ModalConfirm from '../../../components/Modal/Confirm';

interface UserData {
  id: string;
  show_name: string;
  avatar_url: string;
  role: string;
}

interface CategoryData {
  id: string;
  title: string;
}

interface ReportData {
  id: string;
  accused: UserData;
  user: UserData;
  category: CategoryData;
  banned_until?: string;
  created_at: string;
  note?: string;
  reason?: string;
  target_id: string;
  type: 'topic' | 'answer';
  status: string;
  status_info: {
    name: string;
    color: 'warning' | 'primary' | 'error';
  };
}

interface TargetData {
  id: string;
  content: string;
}

interface StrikesData {
  title: string;
  color: 'warning' | 'primary' | 'error';
}

interface ReportRegistrationFormData {
  punishment_days: number;
  note: string;
}

const ReportPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const [report, setReport] = useState({} as ReportData);
  const [target, setTarget] = useState({} as TargetData);
  const [strikes, setStrikes] = useState({} as StrikesData);

  const [rejectModal, setRejectModal] = useState(false);
  const [deferModal, setDeferModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/reports/${id}`);

        setReport(response.data.report);
        setTarget(response.data.target);
        setStrikes(response.data.strikes);

        setLoading(false);
      } catch (e) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro ao visualizar a denúncia.',
        });

        history.push('/denuncias');
      }
    }

    loadData();
  }, [id, history, addToast]);

  const handleSubmit = useCallback(
    async (formData: ReportRegistrationFormData) => {
      setLoading(true);
      setDeferModal(false);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          punishment_days: Yup.number()
            .required('Punição obrigatória')
            .moreThan(-1, 'Punição inválida, apenas 0 ou mais')
            .nullable(),
          note: Yup.string().when('punishment_days', {
            is: value => value === 0,
            then: Yup.string().required('Observação obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.put(`/reports/${id}`, formData);

        setReport(response.data);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'A punição foi aplicada com sucesso.',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao aplicar a punição',
          description: 'Ocorreu um erro ao aplicar a punição, tente novamente.',
        });
      }
    },
    [addToast, id],
  );

  const handleReject = useCallback(async () => {
    setLoadingModal(true);

    try {
      await api.delete(`/reports/${id}`);

      setReport(oldReport => ({
        ...oldReport,
        status: 'rejected',
        status_info: {
          name: 'Arquivada',
          color: 'primary',
        },
      }));

      addToast({
        type: 'success',
        title: 'Denúncia arquivada',
        description: 'A denúncia foi arquivada com sucesso.',
      });

      setRejectModal(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao arquivar a denúncia',
        description: 'Ocorreu um erro ao arquivar a denúncia, tente novamente.',
      });
    } finally {
      setLoadingModal(false);
    }
  }, [addToast, id]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar denúncia"
          items={[
            { title: 'Denúncias', link: '/denuncias' },
            { title: 'Visualizar denúncia' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {loading && <Loader />}

              <Profile>
                <h2>Usuário denunciado</h2>

                <img
                  src={report.accused?.avatar_url}
                  alt={report.accused?.show_name}
                />

                <h3>
                  <Link
                    to={
                      report.accused?.role === 'student'
                        ? `/alunos/visualizar/${report.accused?.id}`
                        : `/usuarios/visualizar/${report.accused?.id}`
                    }
                  >
                    {report.accused?.show_name}
                  </Link>
                </h3>

                <Badge color={strikes.color} title={strikes.title} />

                <br />
              </Profile>
            </Card>
          </Col>

          <Col md={8}>
            <Card>
              {loading && <Loader />}
              <Title>
                <h2>Informações</h2>

                <div>
                  <Badge
                    title={report.status_info?.name}
                    color={report.status_info?.color}
                  />
                </div>
              </Title>
              <ListItem>
                <Row>
                  <Col sm={3}>
                    <span>Data</span>
                  </Col>

                  <Col sm={9}>
                    {report.created_at &&
                      formatDate(report.created_at, "dd/MM/yyyy 'às' HH:mm")}
                  </Col>
                </Row>
              </ListItem>
              <ListItem>
                <Row>
                  <Col sm={3}>
                    <span>Responsável</span>
                  </Col>

                  <Col sm={9}>{report.user?.show_name}</Col>
                </Row>
              </ListItem>
              <ListItem>
                <Row>
                  <Col sm={3}>
                    <span>Categoria</span>
                  </Col>

                  <Col sm={9}>{report.category?.title}</Col>
                </Row>
              </ListItem>
              <ListItem>
                <Row>
                  <Col sm={3}>
                    <span>Motivo</span>
                  </Col>

                  <Col sm={9}>{report.reason || 'Motivo não especificado'}</Col>
                </Row>
              </ListItem>

              <ListItem>
                <Row>
                  <Col sm={3}>
                    <span>Mensagem</span>
                  </Col>

                  <Col sm={9}>
                    <blockquote>{target.content}</blockquote>
                  </Col>
                </Row>
              </ListItem>

              {report.status === 'deferred' && (
                <ListItem>
                  <Row>
                    <Col sm={3}>
                      <span>Suspenso até</span>
                    </Col>

                    <Col sm={9}>
                      {report.banned_until
                        ? formatDate(
                            report.banned_until,
                            "dd/MM/yyyy 'às' HH:mm",
                          )
                        : 'Sem suspensão aplicada'}
                    </Col>
                  </Row>
                </ListItem>
              )}

              <BreakLine />

              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <ListItem>
                  <Row>
                    <Col sm={3}>
                      <label htmlFor="punishment_days">Punição (em dias)</label>
                    </Col>

                    <Col sm={9}>
                      <Input
                        type="number"
                        name="punishment_days"
                        disabled={report.status !== 'pending'}
                      />
                    </Col>
                  </Row>
                </ListItem>

                <ListItem>
                  <Row>
                    <Col sm={3}>
                      <label htmlFor="note">Observações</label>
                    </Col>

                    <Col sm={9}>
                      <TextArea
                        name="note"
                        disabled={report.status !== 'pending'}
                      />
                    </Col>
                  </Row>
                </ListItem>

                <Actions>
                  <Button
                    type="button"
                    color="none"
                    disabled={report.status !== 'pending'}
                    onClick={() => setRejectModal(true)}
                  >
                    <MdArchive size={20} />
                    Arquivar
                  </Button>
                  <Button
                    type="button"
                    color="error"
                    disabled={report.status !== 'pending'}
                    onClick={() => setDeferModal(true)}
                  >
                    <MdGavel size={20} />
                    Aplicar punição
                  </Button>
                </Actions>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        title="Arquivar denúncia"
        confirmText="Arquivar"
        cancelText="Cancelar"
        text="Tem certeza que deseja arquivar a denúncia? Essa ação não poderá ser
        desfeita."
        onConfirm={handleReject}
        isOpen={rejectModal}
        isLoading={loadingModal}
        setIsOpen={() => setRejectModal(!rejectModal)}
      />

      <ModalConfirm
        title="Aplicar punição"
        confirmText="Aplicar punição"
        cancelText="Cancelar"
        text="Tem certeza que deseja aplicar a punição? Essa ação não poderá ser
        desfeita."
        onConfirm={() => formRef.current?.submitForm()}
        isOpen={deferModal}
        isLoading={loadingModal}
        setIsOpen={() => setDeferModal(!deferModal)}
      />
    </>
  );
};

export default ReportPreview;
