import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { MdErrorOutline } from 'react-icons/md';

import { Container, Error } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  name,
  disabled = false,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <textarea
          id={name}
          ref={textAreaRef}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          {...rest}
        />
        {error && (
          <Error>
            <MdErrorOutline size={16} />
            {error}
          </Error>
        )}
      </label>
    </Container>
  );
};

export default TextArea;
