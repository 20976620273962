import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
  MdChevronLeft,
  MdChevronRight,
  MdLastPage,
  MdFirstPage,
  MdSearch,
} from 'react-icons/md';

import { Container, SearchInput, Pagination, PaginationButton } from './styles';

import Loader from '../Loader';

interface Column {
  label: string;
  field: string;
  sort?: 'asc' | 'desc' | 'disabled' | null;
}

interface DatatableProps {
  loading: boolean;
  columns: Column[];
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  maxPages: number;
  pageIndex: number;
  setPageIndex(value: number): void;
  setSearch?(value: string): void;
}

const Datatable: React.FC<DatatableProps> = ({
  loading,
  columns,
  showingFrom,
  showingTo,
  totalRecords,
  children,
  maxPages,
  pageIndex,
  setPageIndex,
  setSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const delayedSearch = useCallback(
    debounce(() => {
      setPageIndex(0);
      if (setSearch) setSearch(searchTerm);
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  function canNextPage(): boolean {
    return pageIndex + 1 < maxPages;
  }

  function canPreviousPage(): boolean {
    return !!pageIndex;
  }

  function goLastPage() {
    if (canNextPage()) {
      setPageIndex(maxPages - 1);
    }
  }

  function goFirstPage() {
    if (canPreviousPage()) {
      setPageIndex(0);
    }
  }

  function goNextPage() {
    if (canNextPage()) {
      setPageIndex(pageIndex + 1);
    }
  }

  function goPreviousPage() {
    if (canPreviousPage()) {
      setPageIndex(pageIndex - 1);
    }
  }

  return (
    <>
      <Container>
        {loading && <Loader />}
        {setSearch && (
          <SearchInput>
            <MdSearch size={20} />
            <input
              type="text"
              name="search"
              placeholder="Pesquisar..."
              onChange={event => setSearchTerm(event.target.value)}
              autoComplete="off"
            />
          </SearchInput>
        )}

        <table>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.field}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </Container>

      <Pagination>
        <p>
          {`Exibindo ${
            showingTo ? showingFrom : 0
          } a ${showingTo} de ${totalRecords} registros`}
        </p>

        <div>
          <PaginationButton
            type="button"
            isActive={canPreviousPage()}
            onClick={goFirstPage}
          >
            <MdFirstPage size={24} />
          </PaginationButton>

          <PaginationButton
            type="button"
            isActive={canPreviousPage()}
            onClick={goPreviousPage}
          >
            <MdChevronLeft size={24} />
          </PaginationButton>

          <PaginationButton
            type="button"
            isActive={canNextPage()}
            onClick={goNextPage}
          >
            <MdChevronRight size={24} />
          </PaginationButton>

          <PaginationButton
            type="button"
            isActive={canNextPage()}
            onClick={goLastPage}
          >
            <MdLastPage size={24} />
          </PaginationButton>
        </div>
      </Pagination>
    </>
  );
};

export default Datatable;
