import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import WordingPhotoContent from '../../../../containers/WordingPhotoContent';
import { useMark } from '../../../../hooks/mark-photo';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

interface PhotoContentProps {
  correction_id: string;
  photo_url: string;
}

const PhotoContent: React.FC<PhotoContentProps> = ({
  correction_id,
  photo_url,
}) => {
  const { setInitialMarks } = useMark();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/corrections/${correction_id}/marks`);

        setInitialMarks(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu um erro.',
        });

        history.push('/dashboard');
      }
    }

    correction_id && loadData();
  }, [correction_id, addToast, history, setInitialMarks]);

  return <WordingPhotoContent photo_url={photo_url} readOnly />;
};

export default PhotoContent;
