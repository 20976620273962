import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Datatable from '../../../components/Datatable';
import DropdownButton from '../../../components/DropdownButton';
import Modal, { ModalHeader } from '../../../components/Modal';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import ModalConfirm from '../../../components/Modal/Confirm';
import TextArea from '../../../components/TextArea';
import Select from '../../../components/Select';

interface CategoryData {
  id: string;
  title: string;
  description: string;
  type: string;
  need_reason: boolean;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'title' | 'description' | 'type' | 'need_reason' | 'created_at';
  transform?(
    value: string | boolean,
    row?: CategoryData,
  ): string | React.ReactElement;
}

interface CategoryRegistrationFormData {
  title: string;
  description: string;
  type: string;
  need_reason: string;
}

interface CategoryUpdationFormData {
  title: string;
  description: string;
  type: string;
  need_reason: string;
}

interface Category {
  id: string;
  title: string;
  description: string;
  type: string;
  need_reason: string;
}

const ReportCategory: React.FC = () => {
  const { addToast } = useToast();

  const formAddCategoryRef = useRef<FormHandles>(null);
  const formEditCategoryRef = useRef<FormHandles>(null);

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category,
  );

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [modalAddCategory, setModalAddCategory] = useState(false);
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [modalDelCategory, setModalDelCategory] = useState(false);

  const [data, setData] = useState<CategoryData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/reports/categories', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleAddCategory = useCallback(
    async (formData: CategoryRegistrationFormData) => {
      setLoadingModal(true);

      formAddCategoryRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          type: Yup.string().required('Selecione uma opção'),
          need_reason: Yup.string().required('Selecione uma opção'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post<CategoryData>('/reports/categories', {
          title: formData.title,
          description: formData.description,
          type: formData.type,
          need_reason: formData.need_reason,
        });

        setData(oldData => [response.data, ...oldData]);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A categoria foi cadastrada com sucesso!',
        });

        setModalAddCategory(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddCategoryRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast],
  );

  const handleEditCategory = useCallback(
    async (formData: CategoryUpdationFormData) => {
      setLoadingModal(true);

      formEditCategoryRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          type: Yup.string().required('Selecione uma opção'),
          need_reason: Yup.string().required('Selecione uma opção'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.put(
          `/reports/categories/${selectedCategory.id}`,
          {
            title: formData.title,
            description: formData.description,
            type: formData.type,
            need_reason: formData.need_reason,
          },
        );

        setData(oldData =>
          oldData.map(findData =>
            findData.id === response.data.id ? response.data : findData,
          ),
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'A categoria foi atualizada com sucesso!',
        });

        setModalEditCategory(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditCategoryRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingModal(false);
      }
    },
    [addToast, selectedCategory.id],
  );

  const handleDelCategory = useCallback(async () => {
    setLoadingModal(true);

    try {
      await api.delete(`/reports/categories/${selectedCategory.id}`);

      setData(oldData =>
        oldData.filter(findData => findData.id !== selectedCategory.id),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'A categoria foi excluída com sucesso!',
      });

      setModalDelCategory(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingModal(false);
    }
  }, [addToast, selectedCategory.id]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Título',
        field: 'title',
      },
      {
        label: 'Tipo',
        field: 'type',
        transform: (type: string) => {
          switch (type) {
            case 'topic':
              return 'Tópico';

            case 'answer':
              return 'Resposta';

            default:
              return 'Não encontrado...';
          }
        },
      },
      {
        label: 'Motivo obrigatório?',
        field: 'need_reason',
        transform: (need_reason: boolean) =>
          need_reason ? (
            <Badge title="Sim" color="success" />
          ) : (
            <Badge title="Não" color="error" />
          ),
      },
      {
        label: '',
        field: 'id',
        transform: (id: string, row: CategoryData) => (
          <DropdownButton>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCategory({
                      id,
                      title: row.title,
                      description: row.description,
                      type: row.type,
                      need_reason: String(row.need_reason),
                    });
                    setModalEditCategory(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Alterar
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCategory({
                      id,
                      title: row.title,
                      description: row.description,
                      type: row.type,
                      need_reason: String(row.need_reason),
                    });
                    setModalDelCategory(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Excluir
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Categorias das denúncias"
          items={[
            {
              title: 'Denúncias',
              link: '/denuncias',
            },
            { title: 'Categorias' },
          ]}
        >
          <Button color="secondary" onClick={() => setModalAddCategory(true)}>
            Cadastrar categoria
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>
                      Nenhuma categoria encontrada
                    </td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddCategory}
        setIsOpen={() => setModalAddCategory(!modalAddCategory)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Cadastrar categoria</div>

          <button type="button" onClick={() => setModalAddCategory(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddCategoryRef} onSubmit={handleAddCategory} noValidate>
          <Input label="Título" name="title" />

          <Row>
            <Col md={6}>
              <Select
                label="Tipo"
                name="type"
                options={[
                  { label: 'Tópico', value: 'topic' },
                  { label: 'Resposta', value: 'answer' },
                ]}
              />
            </Col>

            <Col md={6}>
              <Select
                label="Motivo obrigatório?"
                name="need_reason"
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                ]}
              />
            </Col>
          </Row>

          <TextArea label="Descrição" name="description" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar categoria
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditCategory}
        setIsOpen={() => setModalEditCategory(!modalEditCategory)}
      >
        {loadingModal && <Loader />}
        <ModalHeader>
          <div>Alterar categoria</div>

          <button type="button" onClick={() => setModalEditCategory(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formEditCategoryRef}
          onSubmit={handleEditCategory}
          noValidate
          initialData={selectedCategory}
        >
          <Input label="Título" name="title" />

          <Row>
            <Col md={6}>
              <Select
                label="Tipo"
                name="type"
                options={[
                  { label: 'Tópico', value: 'topic' },
                  { label: 'Resposta', value: 'answer' },
                ]}
              />
            </Col>

            <Col md={6}>
              <Select
                label="Motivo obrigatório?"
                name="need_reason"
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                ]}
              />
            </Col>
          </Row>

          <TextArea label="Descrição" name="description" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Alterar
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir categoria"
        confirmText="Excluir"
        cancelText="Cancelar"
        text={`Tem certeza que deseja excluir a categoria "${selectedCategory.title}"? Essa ação não poderá ser desfeita.`}
        onConfirm={handleDelCategory}
        isOpen={modalDelCategory}
        isLoading={loadingModal}
        setIsOpen={() => setModalDelCategory(!modalDelCategory)}
      />
    </>
  );
};

export default ReportCategory;
