import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  max-width: 890px;
  margin: 0 auto;
  position: relative;
`;

export const WordingPhotoContainer = styled.div`
  user-select: none;

  > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

interface OverlayProps {
  isVisible: boolean;
  isSmall: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  background: ${({ isSmall }) =>
    isSmall ? transparentize(0.4, '#ff0000') : transparentize(0.4, '#000000')};
  border-radius: 4px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  transition: none;
`;

export const Zoom = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;
`;
