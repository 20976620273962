import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  > header {
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.backgroundCard};
    border-radius: 8px;
    padding: 24px;
    box-shadow: ${({ theme }) => theme.shadow};
    cursor: grab;

    > img {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      margin-right: 16px;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    > span {
      flex: 1;
      font-size: 1.4em;
      font-weight: bold;
      color: ${({ theme }) => theme.title};
    }
  }

  > section {
    padding: 12px 0;
    margin-bottom: 16px;
  }
`;
