import React, { useState, useEffect, useRef } from 'react';
import {
  MdExpandMore,
  MdExitToApp,
  MdPersonOutline,
  MdBrightness2,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../hooks/auth';

import { Container, Content, Menu } from './styles';
import SwitchButton from '../../../components/SwitchButton';
import { useDarkMode } from '../../../hooks/theme';

const Profile: React.FC = () => {
  const { user, logout } = useAuth();
  const { darkMode, switchDarkMode } = useDarkMode();

  const profileRef = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!profileRef.current || !event.target) {
        return;
      }

      if (!profileRef.current.contains(event.target as HTMLDivElement)) {
        setActive(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={profileRef}>
      <Content onClick={() => setActive(!active)}>
        <img src={user.avatar_url} alt={user.show_name} />

        <div>
          <strong>{user.show_name}</strong>
          <span>
            {user.role === 'corrector' ||
            user.role === 'super-corrector' ||
            user.role === 'hyper-corrector'
              ? 'Corretor'
              : user.role_name}
          </span>
        </div>

        <MdExpandMore size={20} />
      </Content>

      <Menu active={active}>
        <ul>
          <li>
            <Link to="/perfil">
              <MdPersonOutline />
              Perfil
            </Link>
          </li>
          <li>
            <button type="button" onClick={switchDarkMode}>
              <span>
                <MdBrightness2 />
                Modo escuro
              </span>
              <SwitchButton active={!!darkMode} />
            </button>
          </li>
          <li>
            <button type="button" onClick={logout}>
              <MdExitToApp />
              Sair
            </button>
          </li>
        </ul>
      </Menu>
    </Container>
  );
};

export default Profile;
