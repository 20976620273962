import React, { useRef, useState, useEffect } from 'react';
import { useField } from '@unform/core';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import { MdErrorOutline } from 'react-icons/md';
import gfm from 'remark-gfm';
import FileType from 'file-type';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { Container, Error as ErrorComponent } from './styles';

interface EditorProps {
  name: string;
  minEditorHeight?: number;
}

const Editor: React.FC<EditorProps> = ({ name, minEditorHeight }) => {
  const editorRef = useRef(null);

  const [content, setContent] = useState('');
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const { fieldName, registerField, error } = useField(name);

  const { addToast } = useToast();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: editorRef.current,
      setValue(_: any, value: string) {
        setContent(value);
      },
      getValue(): string {
        return content;
      },
      clearValue(): void {
        setContent('');
      },
    });
  }, [fieldName, registerField, content]);

  const saveImage = async function* generateSequence(
    data: ArrayBuffer,
  ): AsyncGenerator<string, boolean> {
    try {
      const fileType = await FileType.fromBuffer(data);

      if (!fileType) {
        throw new Error();
      }

      if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileType.ext)) {
        throw new Error();
      }

      const arrayBufferView = new Uint8Array(data);

      if (data.byteLength > 1000000) {
        throw new Error();
      }

      const file = new File(
        [arrayBufferView],
        `ticket-attachment-file.${fileType.ext}`,
        { type: fileType.mime },
      );

      const formData = new FormData();

      formData.append('image', file);

      const response = await api.patch<string>('tickets', formData);

      yield response.data;

      return true;
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no upload',
        description: 'Ocorreu um erro no upload, tente novamente.',
      });

      yield '';

      return false;
    }
  };

  return (
    <Container hasError={!!error} style={{ marginBottom: '24px' }}>
      <ReactMde
        ref={editorRef}
        value={content}
        onChange={setContent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={[
          ['bold', 'italic', 'strikethrough'],
          ['link', 'image'],
          ['unordered-list', 'ordered-list'],
        ]}
        minEditorHeight={minEditorHeight}
        minPreviewHeight={minEditorHeight}
        generateMarkdownPreview={markdown => {
          return Promise.resolve(
            <ReactMarkdown
              plugins={[[gfm, { singleTilde: false }]]}
              linkTarget="blank"
              disallowedTypes={['heading', 'blockquote', 'code', 'inlineCode']}
              renderers={{
                image: ({ alt, src, title }) => (
                  <img
                    src={src}
                    alt={alt}
                    title={title}
                    style={{
                      maxHeight: 300,
                      display: 'block',
                      borderRadius: 4,
                      margin: 'auto',
                      cursor: 'pointer',
                      boxShadow: 'rgba(12, 11, 14, 0.27) 0px 4px 12px',
                    }}
                  />
                ),
              }}
              source={markdown}
            />,
          );
        }}
        l18n={{
          write: 'Escrever',
          preview: 'Visualizar',
          pasteDropSelect:
            'Anexe arquivos arrastando, clicando ou colando aqui. (Máx.: 1MB).',
          uploadingImage: 'Carregando imagem...',
        }}
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
        paste={{ saveImage }}
      />

      {error && (
        <ErrorComponent>
          <MdErrorOutline size={16} />
          {error}
        </ErrorComponent>
      )}
    </Container>
  );
};

export default Editor;
