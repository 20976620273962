import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Badge from '../../../../components/Badge';
import Card from '../../../../components/Card';
import Datatable from '../../../../components/Datatable';
import DropdownButton from '../../../../components/DropdownButton';
import Loader from '../../../../components/Loader';
import ModalConfirm from '../../../../components/Modal/Confirm';
import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';

import formatDate from '../../../../utils/formatDate';

interface ThemeData {
  id: string;
  photo: string;
  name: string;
}

interface StatusData {
  name: string;
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'error';
  reason?: string;
}

interface wordingData {
  id: string;
  created_at: string;
  theme: ThemeData;
  status_info: StatusData;
}

interface columnData {
  label: string;
  field: 'id' | 'created_at' | 'theme' | 'status_info';
  transform?(
    value: string | ThemeData | StatusData,
    row?: wordingData,
  ): string | React.ReactElement;
}

interface WordingsDatatableParams {
  student_id: string;
}

const WordingsDatatable: React.FC<WordingsDatatableParams> = ({
  student_id,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<wordingData[]>([]);

  const [modalLoading, setModalLoading] = useState(false);
  const [reviewWordingModal, setReviewWordingModal] = useState(false);
  const [selectedWording, setSelectedWording] = useState('');

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, "dd/MM/yyyy 'às' HH:mm");
        },
      },
      {
        label: 'Tema',
        field: 'theme',
        transform: (theme: ThemeData) => {
          return (
            <Link to={`/temas/visualizar/${theme.id}`} target="blank">
              {theme.name}
            </Link>
          );
        },
      },
      {
        label: 'Status',
        field: 'status_info',
        transform: (statusInfo: StatusData) => {
          return (
            <Badge
              color={statusInfo.color}
              title={statusInfo.name}
              tooltip={statusInfo.reason}
            />
          );
        },
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/redacoes/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedWording(id);
                    setReviewWordingModal(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Revisão
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/students/${student_id}/wordings`, {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search, student_id]);

  const handleReviewWording = useCallback(async () => {
    setModalLoading(true);

    try {
      const response = await api.post(`/wordings/${selectedWording}/review`);

      setData(oldData =>
        oldData.map(oData =>
          oData.id === selectedWording ? response.data : oData,
        ),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na atribuição',
        description: 'A redação foi atribuída com sucesso!',
      });

      setReviewWordingModal(false);
      // eslint-disable-next-line
    } catch (err) {
      const error = err.response.data.error || '';

      switch (error) {
        case 'wording-not-found':
          addToast({
            title: 'A redação não existe',
            description: 'A redação que você está tentando alterar não existe.',
            type: 'error',
          });
          break;

        case 'not-corrected':
          addToast({
            title: 'A redação ainda não foi corrigida',
            description:
              'Apenas redações corrigidas podem ser colocadas em revisão.',
            type: 'error',
          });
          break;

        default:
          addToast({
            type: 'error',
            title: 'Erro na alteração',
            description: 'Ocorreu um erro na alteração.',
          });
      }
    } finally {
      setModalLoading(false);
    }
  }, [addToast, selectedWording]);

  return (
    <>
      <Card>
        {loading && <Loader />}
        <h2>Redações</h2>

        <Datatable
          loading={loading}
          columns={columns}
          showingTo={showingTo}
          showingFrom={showingFrom}
          totalRecords={totalRecords}
          pageIndex={pageIndex}
          maxPages={maxPages}
          setPageIndex={(value: number) => setPageIndex(value)}
          setSearch={(value: string) => setSearch(value)}
        >
          {data.map(row => (
            <tr key={row.id}>
              {columns.map(column => (
                <td key={column.field}>
                  {column.transform
                    ? column.transform(row[column.field])
                    : row[column.field]}
                </td>
              ))}
            </tr>
          ))}
          {!data.length && (
            <tr>
              <td colSpan={columns.length}>Nenhuma redação encontrada</td>
            </tr>
          )}
        </Datatable>
      </Card>

      <ModalConfirm
        isOpen={reviewWordingModal}
        setIsOpen={() => setReviewWordingModal(!reviewWordingModal)}
        cancelText="Cancelar"
        confirmText="Confirmar"
        isLoading={modalLoading}
        onConfirm={handleReviewWording}
        text="Ao colocar uma redação em revisão, ela retorna para a fila para que seja corrigida por um Corretor N3. Tem certeza de que deseja prosseguir?"
        title="Colocar em revisão"
      />
    </>
  );
};

export default WordingsDatatable;
