export interface CompetenceColor {
  color: string;
  background: string;
}

export default function (competence: number): CompetenceColor {
  switch (Number(competence)) {
    case 1:
      return {
        color: '#ffffff',
        background: '#ffce56',
      };
    case 2:
      return {
        color: '#ffffff',
        background: '#36a2eb',
      };
    case 3:
      return {
        color: '#ffffff',
        background: '#008d3d',
      };
    case 4:
      return {
        color: '#ffffff',
        background: '#e86d6c',
      };
    case 5:
      return {
        color: '#ffffff',
        background: '#9966ff',
      };
    default:
      return {
        color: '#ffffff',
        background: '#FF1A66',
      };
  }
}
