import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'info' | 'success' | 'error';
}

const toastTypeVariations = {
  info: css`
    background: ${({ theme }) => theme.colors.info};
    color: #fff;
  `,
  success: css`
    background: ${({ theme }) => theme.colors.success};
    color: #fff;
  `,
  error: css`
    background: ${({ theme }) => theme.colors.danger};
    color: #fff;
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 360px;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  overflow: hidden;
  margin-top: 30px;
  margin-right: 30px;

  display: flex;

  & + div {
    margin-top: 16px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 1.5;
    }
  }

  button {
    position: absolute;
    right: 8px;
    top: 15px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }
`;

export const Life = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8px;
  background: rgba(0, 0, 0, 0.05);
`;
