import React from 'react';

import { useAuth } from '../hooks/auth';

interface UseCanProps {
  roles?: string[];
  children: any;
}

const UseCan: React.FC<UseCanProps> = ({ roles = [], children }) => {
  const { user, isAuthenticated } = useAuth();

  // console.log(user.role);
  if (!isAuthenticated) {
    return <></>;
  }

  if (roles.length > 0) {
    if (roles.includes(user.role)) {
      return children;
    }
  }

  return <></>;
};

export default UseCan;
