import styled from 'styled-components';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { shade } from 'polished';

export const FilterCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 40px;
  row-gap: 40px;

  @media (min-width: 769px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectUser = styled.div`
  text-align: left;
  margin-bottom: 16px;

  > div {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    > div {
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      padding: 12px 16px;
      margin-top: 8px;

      display: flex;
      align-items: center;

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      > svg {
        margin-right: 16px;
        color: ${({ theme }) => theme.input.icon};
      }
    }
  }
`;

export const ReactSelectElement = styled(AsyncSelect)<
  AsyncProps<OptionTypeBase>
>`
  flex: 1;
  background: transparent;
  color: ${({ theme }) => theme.input.text};

  > div {
    border: none;
    background: none;
    min-height: 0;

    .react-select__value-container {
      padding: 0;

      .react-select__placeholder {
        color: ${({ theme }) => theme.input.placeholder};
        font-weight: normal;
        margin-left: 0;
        margin-right: 0;
      }

      .react-select__single-value {
        font-weight: normal;
        color: ${({ theme }) => theme.title};
      }

      .css-b8ldur-Input {
        color: ${({ theme }) => theme.title};
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .react-select__option {
      font-weight: normal;
    }

    .react-select__option:hover,
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      background: ${({ theme }) =>
        shade(theme.hoverPercentage, theme.backgroundCard)};
      color: inherit;
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        color: ${({ theme }) => theme.title};
        padding: 0;
      }
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }

  .react-select__menu {
    width: Calc(100% + 32px);
    left: -16px;
    top: Calc(100% + 12px);
    background: ${({ theme }) => theme.backgroundCard};
    box-shadow: ${({ theme }) => theme.shadow};
  }

  .react-select__menu-notice {
    font-weight: normal;
  }

  .css-1pahdxg-control {
    box-shadow: none;
  }
`;

export const FilterDate = styled.div`
  text-align: left;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    > div {
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      padding: 12px 16px;
      margin-top: 8px;
      position: relative;

      display: flex;
      align-items: center;

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      > svg {
        margin-right: 16px;
        color: ${({ theme }) => theme.input.icon};
        flex: none;
      }

      input {
        flex: 1;
        width: 100%;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.input.text};

        &::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }
      }
    }
  }
`;

export const BadgeReview = styled.span`
  display: inline-flex;
  font-size: 10px;
  text-wrap: nowrap;
  padding: 2px 6px;
  border-radius: 4px;
  background: red;
  color: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  margin-left: 8px;
  gap: 2px;
`;

export const BadgeIsPhoto = styled.span`
  display: inline-flex;
  font-size: 10px;
  text-wrap: nowrap;
  padding: 2px 6px;
  border-radius: 4px;
  color: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  margin-left: 8px;
`;
