import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

const Embed: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/materials/files/${id}`, {
          responseType: 'blob',
        });

        const fileURL = URL.createObjectURL(response.data);

        window.location.href = fileURL;
      } catch (error) {
        history.push('/dashboard');
      }
    }

    loadData();
  }, [history, id]);

  return <></>;
};

export default Embed;
