import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Datepicker from '../../components/Datepicker';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';
import Modal, { ModalHeader } from '../../components/Modal';
import AsyncSelect from '../../components/Select/Async';
import Loader from '../../components/Loader';
import ModalConfirm from '../../components/Modal/Confirm';
import Select from '../../components/Select';

// import { Container } from './styles';

interface StudentData {
  id: string;
  full_name: string;
}

interface CreditData {
  id: string;
  student: StudentData;
  used: boolean;
  expire_at: string;
  created_at: string;
  exam_name: string;
}

interface columnData {
  label: string;
  field: 'id' | 'student' | 'used' | 'expire_at' | 'exam_name' | 'created_at';
  transform?(
    value: string | boolean | StudentData,
    row?: CreditData,
  ): string | React.ReactElement;
}

interface CreditRegistrationFormData {
  student_id: string;
  expire_at: string;
  exam_name: string;
}

interface CreditUpdationFormData {
  expire_at: string;
}

const Credit: React.FC = () => {
  const { addToast } = useToast();

  const formAddCreditRef = useRef<FormHandles>(null);
  const formEditCreditRef = useRef<FormHandles>(null);

  const [selectedCreditId, setSelectedCreditId] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingAddCredit, setLoadingAddCredit] = useState(false);
  const [loadingDelCredit, setLoadingDelCredit] = useState(false);
  const [loadingEditCredit, setLoadingEditCredit] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [modalAddCredit, setModalAddCredit] = useState(false);
  const [modalDelCredit, setModalDelCredit] = useState(false);
  const [modalEditCredit, setModalEditCredit] = useState(false);

  const [data, setData] = useState<CreditData[]>([]);
  const [exams, setExams] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/credits', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (!modalAddCredit && !modalDelCredit && !modalEditCredit) {
      loadData();
    }
  }, [pageIndex, search, modalAddCredit, modalDelCredit, modalEditCredit]);

  useEffect(() => {
    api.get('/exams/list').then(response => setExams(response.data));
  }, []);

  const handleAddCredit = useCallback(
    async (formData: CreditRegistrationFormData) => {
      setLoadingAddCredit(true);

      formAddCreditRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          student_id: Yup.string().required('Aluno obrigatório'),
          expire_at: Yup.date().nullable(),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.post('credits', {
          student_id: formData.student_id,
          expire_at: formData.expire_at,
          exam_name: formData.exam_name,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O crédito foi cadastrado com sucesso!',
        });

        setModalAddCredit(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddCreditRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingAddCredit(false);
      }
    },
    [addToast],
  );

  const handleEditCredit = useCallback(
    async (formData: CreditUpdationFormData) => {
      setLoadingEditCredit(true);

      formEditCreditRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          expire_at: Yup.date().nullable(),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`credits/${selectedCreditId}`, {
          expire_at: formData.expire_at,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O crédito foi atualizado com sucesso!',
        });

        setModalEditCredit(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditCreditRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingEditCredit(false);
      }
    },
    [addToast, selectedCreditId],
  );

  const handleDelCredit = useCallback(async () => {
    setLoadingDelCredit(true);

    try {
      await api.delete(`/credits/${selectedCreditId}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O crédito foi excluído com sucesso!',
      });

      setModalDelCredit(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingDelCredit(false);
    }
  }, [addToast, selectedCreditId]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Aluno',
        field: 'student',
        transform: (student: StudentData) => {
          return (
            <Link to={`/alunos/visualizar/${student.id}`}>
              {student.full_name}
            </Link>
          );
        },
      },
      {
        label: 'Validade',
        field: 'expire_at',
        transform: (expire_at: string) => {
          return expire_at && format(new Date(expire_at), 'dd/MM/yyyy');
        },
      },
      {
        label: 'Exame',
        field: 'exam_name',
      },
      {
        label: 'Utilizado',
        field: 'used',
        transform: (used: boolean) => {
          return used ? 'Sim' : 'Não';
        },
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCreditId(id);
                    setModalEditCredit(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Alterar
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCreditId(id);
                    setModalDelCredit(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Excluir
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  const studentsOptions = (
    inputValue: string,
    callback: (options: any) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/students/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Créditos" items={[{ title: 'Créditos' }]}>
          <Button color="secondary" onClick={() => setModalAddCredit(true)}>
            Cadastrar crédito
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field])
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddCredit}
        setIsOpen={() => setModalAddCredit(!modalAddCredit)}
      >
        {loadingAddCredit && <Loader />}
        <ModalHeader>
          <div>Cadastrar crédito</div>

          <button type="button" onClick={() => setModalAddCredit(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddCreditRef} onSubmit={handleAddCredit} noValidate>
          <AsyncSelect
            label="Aluno"
            name="student_id"
            loadOptions={debounce(studentsOptions, 500)}
          />

          <Select name="exam_name" label="Exame" options={exams} />

          <Datepicker name="expire_at" label="Validade" minDate={new Date()} />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar crédito
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditCredit}
        setIsOpen={() => setModalEditCredit(!modalEditCredit)}
      >
        {loadingEditCredit && <Loader />}
        <ModalHeader>
          <div>Atualizar crédito</div>

          <button type="button" onClick={() => setModalEditCredit(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formEditCreditRef} onSubmit={handleEditCredit} noValidate>
          <Datepicker name="expire_at" label="Validade" minDate={new Date()} />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar crédito
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir crédito"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir o crédito? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelCredit}
        isOpen={modalDelCredit}
        isLoading={loadingDelCredit}
        setIsOpen={() => setModalDelCredit(!modalDelCredit)}
      />
    </>
  );
};

export default Credit;
