import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight, MdEvent, MdPhoto, MdEdit } from 'react-icons/md';
import { debounce } from 'lodash';
import { ptBR } from 'date-fns/locale';
import ReactDatePicker from 'react-datepicker';

import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import Breadcrumbs from '../../containers/Breadcrumbs';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';

import Badge from '../../components/Badge';
import Card from '../../components/Card';
import Checkbox from '../../components/Checkbox';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';

import {
  SelectUser,
  ReactSelectElement,
  FilterCard,
  FilterDate,
  CheckboxGroup,
  BadgeReview,
} from './styles';
import AsyncSelect from '../../components/Select/Async';
import ModalConfirm from '../../components/Modal/Confirm';

interface StudentData {
  id: string;
  show_name: string;
}

interface UserData {
  id: string;
  show_name: string;
}

interface ThemeData {
  id: string;
  name: string;
}

interface StatusData {
  name: string;
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'error';
  reason?: string;
}

interface WordingData {
  id: string;
  created_at: string;
  student: StudentData;
  theme: ThemeData;
  status_info: StatusData;
  user: UserData;
  in_review: boolean;
  is_photo: boolean;
}

interface columnData {
  label: string;
  field:
    | 'id'
    | 'created_at'
    | 'student'
    | 'theme'
    | 'status_info'
    | 'user'
    | 'is_photo';
  key?: string;
  transform?(
    value: string | StudentData | UserData | ThemeData | StatusData | boolean,
    row?: WordingData,
  ): string | React.ReactElement;
}

const Wording: React.FC = () => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<WordingData[]>([]);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedWording, setSelectedWording] = useState('');

  const [reviewWordingModal, setReviewWordingModal] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [status, setStatus] = useState({
    canceled: false,
    pending: true,
    in_correction: true,
    in_confirmation: true,
    corrected: false,
  });

  const {
    canceled,
    pending,
    in_correction,
    in_confirmation,
    corrected,
  } = status;

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageIndex(0);
    setStatus({ ...status, [event.target.name]: event.target.checked });
  };

  const handleReviewWording = useCallback(async () => {
    setModalLoading(true);

    try {
      const response = await api.post(`/wordings/${selectedWording}/review`);

      setData(oldData =>
        oldData.map(oData =>
          oData.id === selectedWording ? response.data : oData,
        ),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na atribuição',
        description: 'A redação foi atribuída com sucesso!',
      });

      setReviewWordingModal(false);
    } catch (err) {
      const error = err.response.data.error || '';

      switch (error) {
        case 'wording-not-found':
          addToast({
            title: 'A redação não existe',
            description: 'A redação que você está tentando alterar não existe.',
            type: 'error',
          });
          break;

        case 'not-corrected':
          addToast({
            title: 'A redação ainda não foi corrigida',
            description:
              'Apenas redações corrigidas podem ser colocadas em revisão.',
            type: 'error',
          });
          break;

        default:
          addToast({
            type: 'error',
            title: 'Erro na alteração',
            description: 'Ocorreu um erro na alteração.',
          });
      }
    } finally {
      setModalLoading(false);
    }
  }, [addToast, selectedWording]);

  /* const handleAssignWording = useCallback(
    async (formData: { user_id: string }) => {
      setModalLoading(true);

      try {
        const schema = Yup.object().shape({
          user_id: Yup.string().required('Corretor obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const request = await api.post<WordingData>(
          `/wordings/${selectedWording}/users/${formData.user_id}`,
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atribuição',
          description: 'A redação foi atribuída com sucesso!',
        });

        if (pending) {
          const { user } = request.data;

          setData(oldData =>
            oldData.map(wording =>
              wording.id === selectedWording ? { ...wording, user } : wording,
            ),
          );
        } else {
          setData(oldData =>
            oldData.filter(wording => wording.id !== selectedWording),
          );
        }

        setAssignWordingModal(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formCancelWordingRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atribuição',
          description: 'Ocorreu um erro na atribuição, verifique os campos.',
        });
      } finally {
        setModalLoading(false);
      }
    },
    [addToast, selectedWording, pending],
  ); */

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string, row) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Aluno',
        field: 'student',
        transform: (student: StudentData, row) => {
          return (
            <Link to={`/alunos/visualizar/${student.id}`} target="blank">
              {student.show_name}
            </Link>
          );
        },
      },
      {
        label: 'Tema',
        field: 'theme',
        transform: (theme: ThemeData, row) => {
          return (
            <Link
              to={`/temas/visualizar/${theme.id}`}
              target="blank"
              style={{ display: 'inline-block' }}
            >
              {theme.name}
              {row?.in_review && <BadgeReview>Em revisão</BadgeReview>}
            </Link>
          );
        },
      },
      {
        label: 'Tipo',
        field: 'is_photo',
        key: 'actions',
        transform: (is_photo: boolean, row) => (
          <>
            {is_photo ? (
              <BadgeReview style={{ background: '#e43691' }}>
                <MdPhoto size={14} />
                Foto
              </BadgeReview>
            ) : (
              <BadgeReview style={{ background: '#0bcbff' }}>
                <MdEdit size={14} />
                Texto
              </BadgeReview>
            )}
          </>
        ),
      },
      {
        label: 'Corretor',
        field: 'user',
        transform: (user: UserData, row) => {
          return (
            user && (
              <Link to={`/usuarios/visualizar/${user.id}`} target="blank">
                {user.show_name}
              </Link>
            )
          );
        },
      },
      {
        label: 'Status',
        field: 'status_info',
        transform: (statusInfo: StatusData, row) => {
          return (
            <Badge
              color={statusInfo.color}
              title={statusInfo.name}
              tooltip={statusInfo.reason}
            />
          );
        },
      },
      {
        label: '',
        field: 'id',
        key: 'actions',
        transform: (id: string, row) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/redacoes/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedWording(id);
                    setReviewWordingModal(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Revisão
                </button>
              </li>
              {/* <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedWording(id);
                    setAssignWordingModal(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Revisão
                </button>
                </li> */}
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/wordings', {
          params: {
            pageIndex,
            search,
            user_id: selectedUser,
            from: startDate,
            to: endDate,
            canceled,
            pending,
            in_correction,
            in_confirmation,
            corrected,
          },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [
    pageIndex,
    search,
    selectedUser,
    startDate,
    endDate,
    canceled,
    pending,
    in_correction,
    in_confirmation,
    corrected,
  ]);

  const usersOptions = (
    inputValue: string,
    callback: (options: any) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/users/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Redações" items={[{ title: 'Redações' }]} />

        <Row>
          <Col md={12}>
            <Card>
              <FilterCard>
                <section>
                  <h2>Filtrar corretor</h2>

                  <SelectUser>
                    <div>
                      Corretores
                      <div>
                        <ReactSelectElement
                          cacheOptions
                          placeholder="Selecione..."
                          classNamePrefix="react-select"
                          noOptionsMessage={() => 'Nenhuma opção encontrada'}
                          loadOptions={debounce(usersOptions, 500)}
                          loadingMessage={() => 'Carregando...'}
                          isClearable
                          onChange={(selected: { value: string }) => {
                            const value = selected ? selected.value : '';
                            setPageIndex(0);
                            setSelectedUser(value);
                          }}
                        />
                      </div>
                    </div>
                  </SelectUser>
                </section>

                <section>
                  <h2>Filtrar data</h2>

                  <FilterDate>
                    <div>
                      De
                      <div>
                        <MdEvent />
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date: Date | null) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          locale={ptBR}
                        />
                      </div>
                    </div>

                    <div>
                      Até
                      <div>
                        <MdEvent />
                        <ReactDatePicker
                          selected={endDate}
                          onChange={(date: Date | null) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          locale={ptBR}
                        />
                      </div>
                    </div>
                  </FilterDate>
                </section>

                <section>
                  <h2>Filtrar status</h2>

                  <CheckboxGroup>
                    <Checkbox
                      name="canceled"
                      label="Canceladas"
                      color="danger"
                      checked={canceled}
                      onChange={handleChangeStatus}
                    />

                    <Checkbox
                      name="pending"
                      label="Pendentes"
                      color="warning"
                      checked={pending}
                      onChange={handleChangeStatus}
                    />

                    <Checkbox
                      name="in_correction"
                      label="Em correção"
                      color="info"
                      checked={in_correction}
                      onChange={handleChangeStatus}
                    />

                    <Checkbox
                      name="in_confirmation"
                      label="Em homologação"
                      color="primary"
                      checked={in_confirmation}
                      onChange={handleChangeStatus}
                    />

                    <Checkbox
                      name="corrected"
                      label="Corrigidas"
                      color="success"
                      checked={corrected}
                      onChange={handleChangeStatus}
                    />
                  </CheckboxGroup>
                </section>
              </FilterCard>
            </Card>

            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.key || column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        isOpen={reviewWordingModal}
        setIsOpen={() => setReviewWordingModal(!reviewWordingModal)}
        cancelText="Cancelar"
        confirmText="Confirmar"
        isLoading={modalLoading}
        onConfirm={handleReviewWording}
        text="Ao colocar uma redação em revisão, ela retorna para a fila para que seja corrigida por um Corretor N3. Tem certeza de que deseja prosseguir?"
        title="Colocar em revisão"
      />

      {/* <Modal
        isOpen={assignWordingModal}
        setIsOpen={() => setAssignWordingModal(!assignWordingModal)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Atribuir redação</div>

          <button type="button" onClick={() => setAssignWordingModal(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formAssignWordingRef}
          onSubmit={handleAssignWording}
          noValidate
        >
          <AsyncSelect
            label="Corretor"
            name="user_id"
            loadOptions={debounce(usersOptions, 500)}
          />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Confirmar
          </Button>
        </Form>
      </Modal> */}
    </>
  );
};

export default Wording;
