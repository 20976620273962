import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'error'
    | 'none';
  block: boolean;
}

const buttonColorVariations = {
  primary: css`
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.primary)};
    }
  `,
  secondary: css`
    background: ${({ theme }) => theme.colors.secondary};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.secondary)};
    }
  `,
  tertiary: css`
    background: ${({ theme }) => theme.colors.tertiary};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.tertiary)};
    }
  `,
  info: css`
    background: ${({ theme }) => theme.colors.info};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.info)};
    }
  `,
  warning: css`
    background: ${({ theme }) => theme.colors.warning};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.warning)};
    }
  `,
  success: css`
    background: ${({ theme }) => theme.colors.success};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.success)};
    }
  `,
  error: css`
    background: ${({ theme }) => theme.colors.danger};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => shade(0.1, theme.colors.danger)};
    }
  `,
  none: css`
    background: none;
    color: ${({ theme }) => theme.title};

    &:not(:disabled):hover {
      opacity: 0.9;
    }
  `,
};

export const Container = styled.button<ContainerProps>`
  border: none;
  border-radius: 4px;
  padding: 0 18px;
  height: 44px;
  font-weight: bold;
  position: relative;
  display: inline-flex;
  align-items: center;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  > div {
    position: absolute;
    border-radius: 4px;
    line-height: 1.2;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -100%);
    top: -12px;
    left: 50%;
    background: black;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      transform: translateX(-50%);
    }
  }

  svg {
    margin-right: 4px;
  }

  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
  ${props => buttonColorVariations[props.color || 'primary']}

  > span {
    display: inline-flex;
    align-items: center;

    svg {
      animation: rotating 1.2s linear infinite;

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
