import styled, { css } from 'styled-components';

interface ContainerProps {
  hasError: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: left;
  margin-bottom: 16px;

  label {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    font-weight: bold;

    div {
      border-radius: 4px;
      border: ${({ theme }) => theme.input.border};
      background: ${({ theme }) => theme.input.background};
      padding: 12px 16px;
      margin-top: 8px;

      display: flex;
      align-items: center;

      ${props =>
        props.disabled &&
        css`
          opacity: 0.45;
          cursor: not-allowed;

          input {
            cursor: not-allowed;
          }
        `}

      ${props =>
        props.hasError &&
        css`
          border-color: ${({ theme }) => theme.colors.danger};

          &:not(:focus-within) {
            input {
              color: ${({ theme }) => theme.colors.danger};
            }
          }
        `}

      &:hover,
      &:focus-within {
        border-color: ${({ theme }) => theme.input.border};
      }

      svg {
        margin-right: 16px;
        color: ${({ theme }) => theme.input.icon};
        flex: none;
      }

      input {
        flex: 1;
        width: 100%;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.input.text};

        &::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }
      }
    }
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 14px;
  margin-top: 4px;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;
