import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import api from '../../services/api';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import formatDate from '../../utils/formatDate';

interface CourseData {
  id: string;
  title: string;
}

interface AppointmentData {
  id: string;
  course: CourseData;
  start: string;
  end: string;
  title: string;
}

interface ColumnData {
  label: string;
  field: 'id' | 'course' | 'start' | 'end' | 'title';
  transform?(
    value: string | CourseData,
    row?: AppointmentData,
  ): string | React.ReactElement;
}

const Appointment: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<AppointmentData[]>([]);

  const columns = useMemo<ColumnData[]>(
    () => [
      {
        label: 'Início',
        field: 'start',
        transform: (start: string) => {
          const formattedDate = formatDate(start, "dd/MM/yyyy 'às' HH:mm");

          return formattedDate;
        },
      },
      {
        label: 'Término',
        field: 'end',
        transform: (end: string) => {
          const formattedDate = formatDate(end, "dd/MM/yyyy 'às' HH:mm");

          return formattedDate;
        },
      },
      {
        label: 'Título',
        field: 'title',
      },
      {
        label: 'Curso',
        field: 'course',
        transform: (course: CourseData) => {
          if (!course) {
            return 'Todos os cursos';
          }

          return (
            <p>
              <Link to={`/cursos/visualizar/${course.id}`}>{course.title}</Link>
            </p>
          );
        },
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/eventos/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/appointments', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Eventos" items={[{ title: 'Eventos' }]}>
          <Button
            color="secondary"
            onClick={() => history.push('/eventos/cadastrar')}
          >
            Cadastrar evento
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum evento encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Appointment;
