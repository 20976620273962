import styled, { css } from 'styled-components';

interface MarkSelectionProps {
  isNegative: boolean;
}

export const MarkSelection = styled.div<MarkSelectionProps>`
  margin-bottom: 20px;
  display: flex;

  button {
    background: none;
    flex: 1;
    padding: 16px;
    background: ${({ theme }) => theme.input.background};
    border: ${({ theme }) => theme.input.border};
    color: ${({ theme }) => theme.input.text};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-width: 2px;
    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.input.placeholder};
    }

    & + button {
      margin-left: 16px;
    }

    svg {
      color: inherit;
      margin-right: 8px;
    }

    ${({ isNegative }) =>
      isNegative
        ? css`
            &:first-child {
              color: ${({ theme }) => theme.colors.danger};
              border-color: ${({ theme }) => theme.colors.danger};
            }
          `
        : css`
            &:last-child {
              color: ${({ theme }) => theme.colors.success};
              border-color: ${({ theme }) => theme.colors.success};
            }
          `}
  }
`;
