import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { MdClose, MdThumbDown, MdThumbUp } from 'react-icons/md';

import { useToast } from '../../hooks/toast';
import { useMark } from '../../hooks/mark-photo';

import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import getCompetenceColor from '../../utils/getCompetenceColor';

import Loader from '../../components/Loader';

import Modal, { ModalHeader } from '../../components/Modal';
import Select from '../../components/Select';
import MarkEditor from '../../components/MarkEditor';
import Button from '../../components/Button';

import { MarkSelection } from './styles';

interface Category {
  value: string;
  label: string;
  competence: number;
}

interface GroupedCategory {
  label: string;
  options: Category[];
}

interface MarkRegistrationFormData {
  category_id: string;
  description: string;
}

interface ModalMarkRegistrationProps {
  tempCategoryId?: string;
  description?: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  setTempDescription?(value: string): void;
  setTempCategoryId?(value: string): void;
}

const ModalMarkRegistration: React.FC<ModalMarkRegistrationProps> = ({
  tempCategoryId,
  description,
  x1,
  y1,
  x2,
  y2,
  isOpen,
  setIsOpen,
  setTempDescription,
  setTempCategoryId,
}) => {
  const { addToast } = useToast();
  const { addMark } = useMark();

  const formAddMarkRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<Category[]>([]);
  const [groupedCategories, setGroupedCategories] = useState<GroupedCategory[]>(
    [],
  );

  const [isNegative, setIsNegative] = useState(true);

  const handleAddMark = useCallback(
    async (formData: MarkRegistrationFormData) => {
      setLoading(true);

      // setTempDescription && setTempDescription(formData.description);
      // setTempCategoryId && setTempCategoryId(formData.category_id);
      // console.log(formData);

      formAddMarkRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          category_id: isNegative
            ? Yup.string().required('Categoria obrigatória').nullable()
            : Yup.string(),
          description: isNegative
            ? Yup.string()
            : Yup.string().required('Comentário obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddMarkRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro da marcação',
          description:
            'Ocorreu um erro no cadastro da marcação, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }

      let competence = 0;
      let title = 'Marcação positiva';

      if (isNegative) {
        const category = categories.find(
          findCategory => findCategory.value === formData.category_id,
        );

        if (category) {
          competence = category.competence;
          title = category.label;
        }
      }

      const competenceColor = getCompetenceColor(competence);

      addMark({
        x1,
        y1,
        x2,
        y2,
        background: competenceColor.background,
        color: competenceColor.color,
        title,
        description: formData.description,
        type: isNegative ? 'negative' : 'positive',
      });

      setIsOpen(false);
    },
    [
      addToast,
      addMark,
      categories,
      isNegative,
      setIsOpen,
      x1,
      y1,
      x2,
      y2,
      setTempDescription,
      setTempCategoryId,
    ],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<Category[]>('/marks/list');

        setCategories(response.data);

        const groups: GroupedCategory[] = [];

        response.data.forEach(category => {
          const competence = `Competência ${category.competence}`;

          const groupIndex = groups.findIndex(
            groupedCategory => groupedCategory.label === competence,
          );

          if (groupIndex === -1) {
            groups.push({
              label: competence,
              options: [category],
            });
          } else {
            groups[groupIndex].options.push(category);
          }
        });

        setGroupedCategories(groups);

        setLoading(false);

        if (tempCategoryId) {
          formAddMarkRef.current?.setData({
            category_id: tempCategoryId,
          });

          console.log(tempCategoryId);
        }
      } catch (error) {
        setLoading(false);

        addToast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
          type: 'error',
        });
      }
    }

    loadData();
  }, [addToast, tempCategoryId]);

  return (
    <Modal isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)}>
      {loading && <Loader />}

      <ModalHeader>
        <div>Adicionar Marcação</div>

        <button type="button" onClick={() => setIsOpen(false)}>
          <MdClose size={20} />
        </button>
      </ModalHeader>

      <Form ref={formAddMarkRef} onSubmit={handleAddMark} noValidate>
        <MarkSelection isNegative={isNegative}>
          <button type="button" onClick={() => setIsNegative(true)}>
            <MdThumbDown size={16} />
            Marcação Negativa
          </button>

          <button type="button" onClick={() => setIsNegative(false)}>
            <MdThumbUp size={16} />
            Marcação Positiva
          </button>
        </MarkSelection>

        {isNegative && (
          <Select
            name="category_id"
            label="Marcação"
            options={groupedCategories}
          />
        )}

        <MarkEditor
          name="description"
          label="Comentário"
          defaultValue={description}
        />

        <footer>
          <Button type="submit" color="primary">
            Adicionar marcação
          </Button>
        </footer>
      </Form>
    </Modal>
  );
};

export default ModalMarkRegistration;
