import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA, { ReCAPTCHA as ReCAPTCHAProps } from 'react-google-recaptcha';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdLock, MdPerson } from 'react-icons/md';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Welcome, Content } from './styles';

import logo from '../../assets/logo-poxalulu.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaExpired, setCaptchaExpired] = useState(false);
  const [captchaDisplayed, setCaptchaDisplayed] = useState(false);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  const history = useHistory();

  const recaptchaRef = useRef<ReCAPTCHAProps>(null);

  const { login } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    if (captchaExpired) {
      recaptchaRef.current?.reset();
    }
  }, [captchaExpired]);

  const handleChangeToken = useCallback(token => {
    setCaptchaToken(token);
    if (token === null) {
      setCaptchaExpired(true);
    }
  }, []);

  const handleLoadCaptcha = useCallback(() => {
    setCaptchaLoaded(true);
  }, []);

  const handleDOMChange = useCallback(() => {
    const iframe = document.querySelector(
      'iframe[src*="recaptcha/api2/bframe"]',
    );

    const container = iframe?.parentNode?.parentNode?.firstChild as
      | HTMLElement
      | undefined;

    if (!container) {
      return;
    }

    container.onclick = () => {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no CAPTCHA',
        description: 'Por favor, cumpra a etapa de verificação.',
      });
    };
  }, [addToast]);

  useEffect(() => {
    const domObserver = new MutationObserver(handleDOMChange);

    domObserver.observe(document.body, {
      childList: true,
    });
  }, [handleDOMChange]);

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string().required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        if (recaptchaRef.current) {
          const token = await recaptchaRef.current.executeAsync();

          setCaptchaToken(token);
        }

        await login({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (captchaLoaded) {
          recaptchaRef.current?.reset();
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });

        setCaptchaDisplayed(true);
      }
    },
    [captchaLoaded, history, login, addToast],
  );

  return (
    <Container>
      <Welcome>
        <h1>Olá! Seja bem-vindo novamente.</h1>
      </Welcome>

      <Content>
        <img src={logo} alt="Logo Poxalulu" />

        <Form ref={formRef} onSubmit={handleSubmit} noValidate>
          <Input
            icon={MdPerson}
            type="email"
            name="email"
            label="E-mail ou CPF"
            placeholder="E-mail ou CPF"
          />

          <Input
            icon={MdLock}
            type="password"
            name="password"
            label="Senha"
            placeholder="Senha"
          />

          {captchaDisplayed && (
            <div style={{ color: 'red', marginBottom: '16px' }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                size="invisible"
                onChange={handleChangeToken}
                asyncScriptOnLoad={handleLoadCaptcha}
              />
            </div>
          )}

          <Button
            type="submit"
            color="primary"
            loading={loading}
            disabled={captchaDisplayed && !captchaLoaded}
          >
            Entrar
          </Button>
        </Form>

        <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
      </Content>
    </Container>
  );
};

export default Login;
