import React, { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Container } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { useCallback } from 'react';
import { FormHandles } from '@unform/core';
import Button from '../../../../components/Button';
import Breadcrumbs from '../../../../containers/Breadcrumbs';
import { useToast } from '../../../../hooks/toast';

import Footer from '../../../../containers/Footer';
import Header from '../../../../containers/Header';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Editor from '../../../../components/Editor';
import Input from '../../../../components/Input';
import Card from '../../../../components/Card';
import SwitchButton from '../../../../components/SwitchButton';
import Loader from '../../../../components/Loader';

interface SectionData {
  id: string;
  slug: string;
  name: string;
}

interface ArticleData {
  id: string;
  title: string;
  body: string;
  abstract: string;
  slug: string;
  is_promoted: boolean;
  vote_count: number;
  vote_sum: number;
}

interface ArticleRegistrationFormData {
  title: string;
  body: string;
  is_promoted: boolean;
}

const ArticleRegistration: React.FC = () => {
  const { section_slug } = useParams<{ section_slug: string }>();

  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [isPromoted, setIsPromoted] = useState(false);

  const handleAddArticle = useCallback(
    async (formData: ArticleRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          body: Yup.string().required('Artigo obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post(`/sections/${section_slug}/articles`, {
          title: formData.title,
          body: formData.body,
          is_promoted: isPromoted,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O artigo foi cadastrado com sucesso!',
        });

        history.push(`/secoes/${section_slug}/${response.data.slug}`);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [isPromoted, addToast, history, section_slug],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar artigo"
          items={[
            { title: 'Seções', link: '/secoes' },
            { title: 'Artigos de 777', link: `/secoes/${section_slug}` },
            { title: 'Cadastrar artigo' },
          ]}
        />

        <Card>
          <Form ref={formRef} onSubmit={handleAddArticle} noValidate>
            {loading && <Loader />}
            <Input label="Título" name="title" />

            <SwitchButton
              label="Em destaque"
              active={isPromoted}
              onClick={() => setIsPromoted(!isPromoted)}
            />

            <Editor name="body" minEditorHeight={400} />

            <Button type="submit" color="primary">
              Cadastrar artigo
            </Button>
          </Form>
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default ArticleRegistration;
