import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import { MdLink } from 'react-icons/md';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import TextArea from '../../../components/TextArea';
import AsyncSelect, { Option } from '../../../components/Select/Async';
import Datepicker from '../../../components/Datepicker';
import formatDate from '../../../utils/formatDate';

import { AppointmentDescription } from './styles';
import ModalConfirm from '../../../components/Modal/Confirm';

interface AppointmentData {
  created_at: string;
  course_id: string;
  start: string;
  end: string;
  title: string;
  link: string;
  description: string;
  cover_url: string;
  startDay: string;
  startMonth: string;
  startTime: string;
}

interface AppointmentUpdationFormData {
  course_id: string;
  start: string;
  end: string;
  title: string;
  link: string;
  description: string;
}

const AppointmentUpdation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [appointment, setAppointment] = useState<AppointmentData>(
    {} as AppointmentData,
  );
  const [coverFile, setCoverFile] = useState<Blob | string>('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const coursesOptions = (
    inputValue: string,
    callback: (options: Option[]) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/courses/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const response = await api.get(`appointments/${id}`);

        const startDay = formatDate(response.data.start, 'dd');
        const startMonth = formatDate(response.data.start, 'MMM');
        const startTime = formatDate(response.data.start, 'HH:mm');

        setAppointment({ ...response.data, startDay, startMonth, startTime });

        formRef.current?.setData({
          course_id: response.data.course_id,
          start: response.data.start,
          end: response.data.end,
        });

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O evento não foi encontrado!',
        });

        history.push('/eventos');
      }
    }

    loadData();
  }, [id, addToast, history]);

  const handleDelete = useCallback(async () => {
    try {
      setLoadingDelete(true);

      await api.delete(`appointments/${id}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O evento foi excluído com sucesso!',
      });

      history.push('/eventos');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão.',
      });
    }
  }, [addToast, history, id]);

  const handleSubmit = useCallback(
    async (data: AppointmentUpdationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          course_id: Yup.string().notRequired(),
          start: Yup.string().nullable().required('Início obrigatório'),
          end: Yup.string().nullable().required('Fim obrigatório'),
          title: Yup.string().required('Título obrigatório'),
          link: Yup.string().url('Link em formato inválido').notRequired(),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        data.course_id && formData.append('course_id', data.course_id);
        formData.append('start', data.start);
        formData.append('end', data.end);
        formData.append('title', data.title);
        formData.append('link', data.link);
        formData.append('description', data.description);
        coverFile && formData.append('cover', coverFile);

        const response = await api.put(`appointments/${id}`, formData);

        setAppointment(response.data);

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O evento foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, id, coverFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar evento"
          items={[
            { title: 'Eventos', link: '/eventos' },
            { title: 'Visualizar evento' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {loading && <Loader />}

              <AppointmentDescription cover={appointment.cover_url}>
                <figure />

                <div>
                  <div>
                    <div>
                      <span>{appointment.startDay}</span>
                      <span>{appointment.startMonth}</span>
                    </div>

                    <h2>{appointment.title}</h2>

                    <time>{appointment.startTime}</time>

                    <p>{appointment.description}</p>
                  </div>

                  <footer>
                    <p>
                      <MdLink size={20} />

                      {appointment.link ? (
                        <a href={appointment.link} target="blank">
                          Link do evento
                        </a>
                      ) : (
                        <span>Sem link</span>
                      )}
                    </p>

                    <Button
                      type="button"
                      color="error"
                      onClick={() => setDeleteModal(true)}
                    >
                      Remover evento
                    </Button>
                  </footer>
                </div>
              </AppointmentDescription>
            </Card>
          </Col>

          <Col md={8}>
            <Card>
              {loading && <Loader />}
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={appointment}
                noValidate
              >
                <Row>
                  <Col md={6}>
                    <Input type="text" name="title" label="Título" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="link" label="Link" />
                  </Col>

                  <Col md={4}>
                    <AsyncSelect
                      name="course_id"
                      label="Curso"
                      loadOptions={debounce(coursesOptions, 500)}
                      isClearable
                    />
                  </Col>

                  <Col md={4}>
                    <Datepicker
                      name="start"
                      label="Início"
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </Col>

                  <Col md={4}>
                    <Datepicker
                      name="end"
                      label="Fim"
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Dropzone
                      label="Capa"
                      accept="image/*"
                      maxSize={3000000}
                      onDrop={acceptedFile => setCoverFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar evento
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        title="Excluir evento"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir o evento? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelete}
        isOpen={deleteModal}
        isLoading={loadingDelete}
        setIsOpen={() => setDeleteModal(!deleteModal)}
      />
    </>
  );
};

export default AppointmentUpdation;
