import React, { useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import DragDropContext from '../../hooks/dragdrop';

import { Container } from './styles';

interface ClusterItemProps {
  index: number;
}

const ClusterItem: React.FC<ClusterItemProps> = ({ index, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item: { type: string; index: number }, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current?.getBoundingClientRect();

      if (!targetSize) {
        return;
      }

      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();

      if (!draggedOffset) {
        return;
      }

      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      console.log('foi');
      // move(draggedIndex, targetIndex);
    },
  });

  dragRef(dropRef(ref));

  return (
    <Container ref={ref} isDragging={isDragging}>
      {children}
    </Container>
  );
};

export default ClusterItem;
