import styled from 'styled-components';
import { shade } from 'polished';

interface BackgroundProps {
  active: boolean;
  hasMargin: boolean;
}

interface MobileMenuProps {
  active: boolean;
}

export const Background = styled.div<BackgroundProps>`
  margin-top: 1px;
  margin-bottom: ${({ hasMargin }) => (hasMargin ? '40px' : '0px')};
  background: ${({ theme }) => theme.backgroundCard};

  @media (max-width: 768px) {
    & {
      background: ${props =>
        props.active &&
        shade(props.theme.hoverPercentage, props.theme.backgroundCard)};
    }
  }
`;

export const MobileButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.text};
`;

export const MobileMenu = styled.div<MobileMenuProps>`
  display: ${props => (props.active ? 'block' : 'none')};
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 8;
  background: ${({ theme }) => theme.backgroundCard};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 0 0 8px 8px;
`;
