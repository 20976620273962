import React, { useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';

import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

import { Container } from './styles';

import getCroppedImg from '../../utils/getCroppedImage';
import Button from '../Button';

interface IModalCropImageProps {
  image: string;
  isOpen: boolean;
  cropSize: {
    width: number;
    height: number;
  };
  setIsOpen(value: boolean): void;
  onSubmit(value: File): Promise<void>;
}

const ModalCropImage: React.FC<IModalCropImageProps> = ({
  image,
  cropSize,
  isOpen,
  setIsOpen,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area>({} as Area);

  const handleResetConfigs = useCallback(() => {
    setZoom(1);
    setRotation(0);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setLoading(true);

      const croppedImage = await getCroppedImg(
        image,
        croppedArea,
        rotation,
        cropSize,
      );

      handleResetConfigs();

      if (!croppedImage) {
        throw new Error('');
      }

      await onSubmit(croppedImage);

      setIsOpen(false);
    } catch (e) {
      // console.error(e);
    } finally {
      setLoading(false);
    }
  }, [
    croppedArea,
    rotation,
    image,
    cropSize,
    handleResetConfigs,
    onSubmit,
    setIsOpen,
  ]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    handleResetConfigs();
  }, [setIsOpen, handleResetConfigs]);

  return (
    <Container isOpen={isOpen}>
      <header>
        <button type="button" onClick={onClose}>
          <MdClose size={20} />
        </button>
      </header>

      <div>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={cropSize.width / cropSize.height}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={(_, croppedAreaPixels) => {
            setCroppedArea(croppedAreaPixels);
          }}
          onZoomChange={setZoom}
          restrictPosition
          cropShape="round"
          style={{ cropAreaStyle: { boxShadow: 'none' } }}
        />
      </div>

      <footer>
        <div>
          <div>
            <span>Zoom</span>

            <input
              type="range"
              value={zoom}
              min={1}
              max={2}
              step={0.1}
              onChange={e => setZoom(Number(e.target?.value))}
            />
          </div>

          <div>
            <span>Rotação</span>
            <input
              type="range"
              value={rotation}
              min={0}
              max={360}
              step={1}
              onChange={e => setRotation(Number(e.target?.value))}
            />
          </div>
        </div>

        <Button color="tertiary" onClick={showCroppedImage} loading={loading}>
          Confirmar alterações
        </Button>
      </footer>
    </Container>
  );
};

export default ModalCropImage;
