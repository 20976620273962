import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';

import { useToast } from '../../../hooks/toast';
import { MarkProvider } from '../../../hooks/mark';

import Footer from '../../../containers/Footer';
import Header from '../../../containers/Header';

import Modal, { ModalHeader } from '../../../components/Modal';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import CorrectionContent from './CorrectionContent';
import { MarkPhotoProvider } from '../../../hooks/mark-photo';
import CorrectionPhotoContent from './CorrectionPhotoContent';
import SwitchButton from '../../../components/SwitchButton';
import { Shadow } from './styles';

interface WordingData {
  id: string;
  created_at: string;
  end_time_in_seconds?: number;
  text: string;
  is_photo: boolean;
  photo_url: string;
  redemption_date?: string;
  status: string;
  font_size: number;
  status_info: {
    name: string;
    color:
      | 'error'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'info'
      | 'warning'
      | 'success';
  };
  corrections: CorrectionData[];
  student?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
  theme?: {
    id: string;
    name: string;
    thumbnail_url: string;
    material_url: string;
    exam_name: string;
  };
}

interface CorrectionData {
  id: string;
  created_at: string;
  corrected_in: number;
  note: string;
  status: boolean;
  disapprove_reason?: string;
  final_grade?: number;
  rating?: number;
  rating_reason?: string;
  user?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
}

interface CorrectionGrade {
  name:
    | 'competence_1'
    | 'competence_2'
    | 'competence_3'
    | 'competence_4'
    | 'competence_5';
  value: number;
}

interface Grades {
  competence_1: number;
  competence_2: number;
  competence_3: number;
  competence_4: number;
  competence_5: number;
}

const WordingPreview: React.FC = () => {
  const { wording_id } = useParams<{ wording_id: string }>();
  const history = useHistory();
  const { addToast } = useToast();

  const formCancelWordingRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [cancelWordingModal, setCancelWordingModal] = useState(false);

  const [wording, setWording] = useState<WordingData>({} as WordingData);
  const [showLastCorrection, setShowLastCorrection] = useState(false);
  const [hasLastWording, setHasLastWording] = useState(false);
  const [previousWording, setPreviousWording] = useState({} as WordingData);

  const handleCancelWording = useCallback(
    async (formData: { cancellation_reason: string }) => {
      setModalLoading(true);

      try {
        const schema = Yup.object().shape({
          cancellation_reason: Yup.string().required('Motivo obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.delete<WordingData>(
          `/wordings/${wording_id}`,
          { data: formData },
        );

        addToast({
          type: 'success',
          title: 'Sucesso no cancelamento',
          description: 'A redação foi cancelada com sucesso!',
        });

        const { status_info } = response.data;

        setWording(oldWording => ({ ...oldWording, status_info }));

        setCancelWordingModal(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formCancelWordingRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cancelamento',
          description: 'Ocorreu um erro na exclusão, verifique os campos.',
        });
      } finally {
        setModalLoading(false);
      }
    },
    [addToast, wording_id],
  );

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const [wordingResponse, previousWordingResponse] = await Promise.all([
          api.get<WordingData>(`/wordings/${wording_id}`),
          api.get<WordingData>(`/wordings/${wording_id}/previous`),
        ]);

        setWording(wordingResponse.data);

        if (previousWordingResponse?.data) {
          // check if previous wording is newer than wording
          const wordingDate = new Date(wordingResponse.data.created_at);
          const previousWordingDate = new Date(
            previousWordingResponse.data.created_at,
          );

          if (previousWordingDate < wordingDate) {
            setPreviousWording(previousWordingResponse.data);
            setHasLastWording(true);

            setPreviousWording(previousWordingResponse.data);
          }
        }

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'A redação não foi encontrada!',
        });

        history.push('/redacoes');
      }
    }

    loadData();
  }, [addToast, history, wording_id]);

  return (
    <>
      <Header />
      {loading && <Loader isFixed />}
      <Container style={{ marginBottom: 32, zIndex: 1 }}>
        <Row>
          <Col>
            <span>Correção anterior</span>
            <SwitchButton
              active={showLastCorrection}
              disabled={!hasLastWording}
              size="sm"
              pulse={showLastCorrection}
              onClick={() => {
                if (!hasLastWording) {
                  return;
                }

                setShowLastCorrection(!showLastCorrection);
              }}
            />
          </Col>
        </Row>
      </Container>
      {showLastCorrection && (
        <Shadow>
          {previousWording.is_photo ? (
            <MarkPhotoProvider>
              <CorrectionPhotoContent
                created_at={previousWording.created_at}
                status={previousWording.status}
                status_info={previousWording.status_info}
                photo_url={previousWording.photo_url}
                corrections={previousWording.corrections}
                end_time_in_seconds={previousWording.end_time_in_seconds}
                student={previousWording.student}
                theme={previousWording.theme}
                setCancelWordingModal={setCancelWordingModal}
              />
            </MarkPhotoProvider>
          ) : (
            <MarkProvider>
              <CorrectionContent
                created_at={previousWording.created_at}
                status={previousWording.status}
                status_info={previousWording.status_info}
                text={previousWording.text}
                corrections={previousWording.corrections}
                end_time_in_seconds={previousWording.end_time_in_seconds}
                student={previousWording.student}
                theme={previousWording.theme}
                setCancelWordingModal={setCancelWordingModal}
                fontSize={previousWording.font_size}
              />
            </MarkProvider>
          )}
        </Shadow>
      )}
      <div style={{ display: showLastCorrection ? 'none' : 'block' }}>
        {wording.is_photo ? (
          <MarkPhotoProvider>
            <CorrectionPhotoContent
              created_at={wording.created_at}
              status={wording.status}
              status_info={wording.status_info}
              photo_url={wording.photo_url}
              corrections={wording.corrections}
              end_time_in_seconds={wording.end_time_in_seconds}
              student={wording.student}
              theme={wording.theme}
              setCancelWordingModal={setCancelWordingModal}
            />
          </MarkPhotoProvider>
        ) : (
          <MarkProvider>
            <CorrectionContent
              created_at={wording.created_at}
              status={wording.status}
              status_info={wording.status_info}
              text={wording.text}
              corrections={wording.corrections}
              end_time_in_seconds={wording.end_time_in_seconds}
              student={wording.student}
              theme={wording.theme}
              setCancelWordingModal={setCancelWordingModal}
              fontSize={wording.font_size}
            />
          </MarkProvider>
        )}
      </div>
      <Footer />
      <Modal
        isOpen={cancelWordingModal}
        setIsOpen={() => setCancelWordingModal(!cancelWordingModal)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Cancelar redação</div>

          <button type="button" onClick={() => setCancelWordingModal(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formCancelWordingRef}
          onSubmit={handleCancelWording}
          id="cancelWording"
          noValidate
        >
          <Select
            label="Motivo"
            name="cancellation_reason"
            options={[
              {
                label: 'A redação está em branco',
                value: 'is_blank',
              },
              {
                label: 'A redação está ilegível',
                value: 'is_unreadable',
              },
              {
                label: 'A redação está duplicada',
                value: 'is_duplicated',
              },
              {
                label: 'A redação está de lado, impossibilitando a correção',
                value: 'is_rotated',
              },
              {
                label: 'Foi uma solicitação do aluno',
                value: 'was_requested',
              },
              {
                label: 'Tema diferente do proposto pela plataforma',
                value: 'different_theme',
              },
            ]}
          />

          <Button
            type="submit"
            color="primary"
            style={{ marginTop: '16px' }}
            form="cancelWording"
          >
            Confirmar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default WordingPreview;
