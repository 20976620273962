import styled from 'styled-components';

export const ProfilePicture = styled.div`
  text-align: center;

  > label {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
    position: relative;
    width: 152px;
    height: 152px;

    > div {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #fff;
      width: Calc(100% - 24px);
      height: Calc(100% - 24px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.2s ease;
    }

    &:hover {
      > div {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 4px solid ${({ theme }) => theme.colors.primary};
      padding: 8px;
    }

    input {
      display: none;
    }
  }

  span {
    font-size: 12px;
  }
`;

export const DataBox = styled.div`
  position: relative;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;

  span {
    color: ${({ theme }) => theme.title};
    font-size: 32px;
    font-weight: bold;
  }
`;
