import styled, { css } from 'styled-components';

interface AppointmentDescriptionProps {
  cover?: string;
}

export const AppointmentDescription = styled.aside<AppointmentDescriptionProps>`
  > figure {
    width: 100%;
    height: 98px;
    border-radius: 12px;
    ${props =>
      props.cover
        ? css`
            background: url(${props.cover}) center center / cover no-repeat;
          `
        : css`
            background: ${({ theme }) => theme.background};

            ::after {
              content: 'Sem capa';
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              color: ${({ theme }) => theme.title};
              text-transform: uppercase;
              font-weight: bold;
            }
          `}
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;
    position: relative;
    height: 340px;

    > div {
      > div {
        position: absolute;
        top: -42px;
        left: 28px;
        width: 44px;

        > span {
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          line-height: 1em;
          display: block;

          &:first-child {
            padding: 10px 0 6px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            color: #1d1a1f;
            background-color: #f1e9f5;
            font-size: 1.25rem;
          }

          &:last-child {
            padding: 4px 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            color: #f1e9f5;
            background-color: ${({ theme }) => theme.colors.primary};
            font-size: 0.75rem;
          }
        }
      }

      > h2 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0;
      }

      > time {
        margin-top: 10px;
        font-size: 0.875rem;
        font-weight: bold;
      }

      > p {
        margin-top: 20px;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.4285714286em;
      }
    }

    > footer {
      > p {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          fill: ${({ theme }) => theme.colors.primary};
        }

        a,
        span {
          font-size: 0.875rem;
          font-weight: 500;
        }

        a {
          color: ${({ theme }) => theme.colors.primary};
          text-decoration: none;
        }
      }

      > button {
        margin-top: 38px;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;
