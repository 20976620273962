import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import Datatable from '../../../../components/Datatable';
import formatDate from '../../../../utils/formatDate';

// import { Container } from './styles';

interface UserLogsProps {
  user_id: string;
}

interface LoginLogData {
  id: string;
  browser: string;
  os: string;
  device: string;
  ip: string;
  location: string;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'browser' | 'os' | 'device' | 'ip' | 'location' | 'created_at';
  transform?(value: string, row?: LoginLogData): string | React.ReactElement;
}

const UserLogs: React.FC<UserLogsProps> = ({ user_id }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loginLogs, setLoginLogs] = useState<LoginLogData[]>([]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('/login-logs', {
          params: {
            user_id,
            pageIndex,
          },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setLoginLogs(response.data.records);

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O usuário não foi encontrado!',
        });
      }
    }

    loadData();
  }, [user_id, addToast, history, pageIndex]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Data',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, "dd/MM/yyyy 'às' HH:mm");
        },
      },
      {
        label: 'IP',
        field: 'ip',
      },
      {
        label: 'Dispositivo',
        field: 'device',
      },
      {
        label: 'Browser',
        field: 'browser',
      },
      {
        label: 'Sistema',
        field: 'os',
      },
      {
        label: 'Localização',
        field: 'location',
      },
    ],
    [],
  );

  return (
    <Datatable
      loading={loading}
      columns={columns}
      showingTo={showingTo}
      showingFrom={showingFrom}
      totalRecords={totalRecords}
      pageIndex={pageIndex}
      maxPages={maxPages}
      setPageIndex={(value: number) => setPageIndex(value)}
    >
      {loginLogs.map(row => (
        <tr key={row.id}>
          {columns.map(column => (
            <td key={column.field}>
              {column.transform
                ? column.transform(row[column.field], row)
                : row[column.field]}
            </td>
          ))}
        </tr>
      ))}
      {!loginLogs.length && (
        <tr>
          <td colSpan={columns.length}>Nenhum log de login encontrado</td>
        </tr>
      )}
    </Datatable>
  );
};

export default UserLogs;
