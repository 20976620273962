import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import gfm from 'remark-gfm';
import {
  MdLabelOutline,
  MdPersonOutline,
  MdSchedule,
  MdSentimentSatisfied,
  MdEdit,
  MdClose,
} from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { SRLWrapper } from 'simple-react-lightbox';

import { Col, Container, Row } from 'styled-bootstrap-grid';

import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';

import { useToast } from '../../../hooks/toast';

import Rating from '../../../components/Rating';
import Editor from '../../../components/Editor';
import Button from '../../../components/Button';
import ModalConfirm from '../../../components/Modal/Confirm';
import Modal, { ModalHeader } from '../../../components/Modal';
import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import Badge from '../../../components/Badge';

import {
  TicketInfo,
  TicketStatus,
  TicketAnswer,
  TicketContent,
} from './styles';
import Select from '../../../components/Select';

interface TicketData {
  title: string;
  student_id: string;
  description: string;
  status: string;
  rating: number;
  created_at: string;
  student: {
    id: string;
    show_name: string;
  };
  category: {
    id: string;
    name: string;
  };
  answers: Array<{
    id: string;
    created_at: string;
    user_id: string;
    text: string;
    user: {
      id: string;
      show_name: string;
      avatar_url: string;
    };
  }>;
}

interface Options {
  value: string;
  label: string;
}

interface TicketAnswerFormData {
  text: string;
}

interface TicketUpdationFormData {
  category_id: string;
}

type Color =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'info'
  | 'error';

interface StatusTypes {
  [key: string]: {
    title: string;
    color: Color;
  };
}

const TicketPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const formEditTicketRef = useRef<FormHandles>(null);

  const [modalEditTicket, setModalEditTicket] = useState(false);
  const [modalCloseTicket, setModalCloseTicket] = useState(false);

  const [loadingAddTicket, setLoadingAddTicket] = useState(false);
  const [loadingEditTicket, setLoadingEditTicket] = useState(false);
  const [loadingCloseTicket, setLoadingCloseTicket] = useState(false);

  const [ticketLoading, setTicketLoading] = useState(false);

  const [ticket, setTicket] = useState<TicketData>({} as TicketData);
  const [categories, setCategories] = useState<Options[]>([]);

  const { addToast } = useToast();

  const statusTypes: StatusTypes = {
    pending: {
      title: 'Pendente',
      color: 'warning',
    },
    awaiting: {
      title: 'Aguardando',
      color: 'info',
    },
    closed: {
      title: 'Fechado',
      color: 'success',
    },
    canceled: {
      title: 'Cancelado',
      color: 'error',
    },
  };

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get<Options[]>(`/tickets/categories/list`);

        setCategories(response.data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'A lista de categorias não foi encontrada!',
        });
      }
    }

    loadData();
  }, [addToast]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setTicketLoading(true);

      try {
        const response = await api.get(`/tickets/${id}`);

        const created_at = format(
          parseISO(response.data.created_at),
          "dd/MM/yyyy 'às' HH:mm",
          { locale: ptBR },
        );

        setTicket({ ...response.data, created_at });

        setTicketLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O chamado não foi encontrado!',
        });

        history.push('/chamados');
      }
    }

    if (!loadingAddTicket) {
      loadData();
    }
  }, [id, addToast, history, loadingAddTicket]);

  const handleSubmit = useCallback(
    async (formData: TicketAnswerFormData) => {
      setLoadingAddTicket(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          text: Yup.string().required('Texto obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.post(`tickets/${id}/answer`, {
          text: formData.text,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A resposta foi cadastrada com sucesso!',
        });

        formRef.current?.clearField('text');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingAddTicket(false);
      }
    },
    [addToast, id],
  );

  const handleEditTicket = useCallback(
    async (formData: TicketUpdationFormData) => {
      setLoadingEditTicket(true);

      formEditTicketRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          category_id: Yup.string().required('Categoria obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`tickets/${id}`, {
          category_id: formData.category_id,
        });

        const selectedCategory = categories.find(
          category => category.value === formData.category_id,
        );

        if (!selectedCategory) {
          throw new Error('Category not selected');
        }

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O chamado foi atualizado com sucesso!',
        });

        setTicket(oldValue => ({
          ...oldValue,
          category: {
            id: selectedCategory.value,
            name: selectedCategory.label,
          },
        }));

        setModalEditTicket(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditTicketRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingEditTicket(false);
      }
    },
    [addToast, categories, id],
  );

  const handleCloseTicket = useCallback(async () => {
    setLoadingCloseTicket(true);

    try {
      await api.put(`/tickets/${id}/close`);

      addToast({
        type: 'success',
        title: 'Sucesso no fechamento',
        description: 'O chamado foi fechado com sucesso!',
      });

      history.push('/chamados');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no fechamento',
        description: 'Ocorreu um erro no fechamento, verifique os campos.',
      });

      setLoadingCloseTicket(false);
    }
  }, [addToast, id, history]);

  return (
    <SRLWrapper
      options={{
        settings: {
          autoplaySpeed: 0,
          disableKeyboardControls: true,
          disableWheelControls: true,
          disablePanzoom: true,
          hideControlsAfter: true,
          overlayColor: 'rgba(18, 18, 20, 0.9)',
        },
        buttons: {
          showAutoplayButton: false,
          showCloseButton: false,
          showDownloadButton: false,
          showFullscreenButton: false,
          showNextButton: false,
          showPrevButton: false,
          showThumbnailsButton: false,
        },
        thumbnails: {
          showThumbnails: false,
        },
        progressBar: {
          showProgressBar: false,
        },
        caption: {
          showCaption: false,
        },
      }}
    >
      {(ticketLoading || loadingAddTicket) && <Loader isFixed />}

      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar chamado"
          items={[
            { title: 'Chamados', link: '/chamados' },
            { title: 'Visualizar chamado' },
          ]}
        >
          {!['canceled', 'closed'].includes(ticket.status) && (
            <Button
              type="button"
              color="secondary"
              onClick={() => setModalCloseTicket(true)}
            >
              Fechar chamado
            </Button>
          )}
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <h2>{ticket.title}</h2>

              <TicketInfo>
                <li>
                  <MdSchedule />
                  {ticket.created_at}
                </li>
                <li>
                  <MdPersonOutline />
                  <Link
                    to={`/alunos/visualizar/${ticket.student?.id}`}
                    target="blank"
                  >
                    {ticket.student?.show_name}
                  </Link>
                </li>
                <li>
                  <MdLabelOutline />
                  {ticket.category?.name}
                  <button
                    type="button"
                    onClick={() => setModalEditTicket(true)}
                  >
                    <MdEdit />
                    alterar
                  </button>
                </li>
                <li>
                  <MdSentimentSatisfied />
                  {ticket.rating ? (
                    <Rating min={0} max={50} value={ticket.rating} readOnly />
                  ) : (
                    'Não avaliado'
                  )}
                </li>
              </TicketInfo>

              <TicketContent>
                <ReactMarkdown
                  plugins={[[gfm, { singleTilde: false }]]}
                  linkTarget="blank"
                  disallowedTypes={[
                    'heading',
                    'blockquote',
                    'code',
                    'inlineCode',
                  ]}
                  renderers={{
                    image: ({ alt, src, title }) => (
                      <img
                        src={src}
                        alt={alt}
                        title={title}
                        style={{
                          maxHeight: 300,
                          display: 'block',
                          borderRadius: 4,
                          margin: 'auto',
                          cursor: 'pointer',
                          boxShadow: 'rgba(12, 11, 14, 0.27) 0px 4px 12px',
                        }}
                      />
                    ),
                  }}
                  source={ticket.description}
                />
              </TicketContent>

              <TicketStatus>
                {ticket.status && (
                  <Badge
                    title={statusTypes[ticket.status].title}
                    color={statusTypes[ticket.status].color}
                  />
                )}
              </TicketStatus>
            </Card>

            {ticket.answers?.map(answer => {
              const formattedCreated = format(
                parseISO(answer.created_at),
                "dd/MM/yyyy 'às' HH:mm",
                { locale: ptBR },
              );
              const alignment =
                answer.user_id === ticket.student_id ? 'left' : 'right';

              return (
                <TicketAnswer alignment={alignment} key={answer.id}>
                  <header>
                    <img
                      src={answer.user.avatar_url}
                      alt={answer.user.show_name}
                    />

                    <div>
                      <strong>{answer.user.show_name}</strong>
                      <small>{formattedCreated}</small>
                    </div>
                  </header>

                  <div>
                    <TicketContent>
                      <ReactMarkdown
                        plugins={[[gfm, { singleTilde: false }]]}
                        linkTarget="blank"
                        disallowedTypes={[
                          'heading',
                          'blockquote',
                          'code',
                          'inlineCode',
                        ]}
                        renderers={{
                          image: ({ alt, src, title }) => (
                            <img
                              src={src}
                              alt={alt}
                              title={title}
                              style={{
                                maxHeight: 300,
                                display: 'block',
                                borderRadius: 4,
                                margin: 'auto',
                                cursor: 'pointer',
                                boxShadow:
                                  'rgba(12, 11, 14, 0.27) 0px 4px 12px',
                              }}
                            />
                          ),
                        }}
                        source={answer.text}
                      />
                    </TicketContent>
                  </div>
                </TicketAnswer>
              );
            })}

            {!['canceled', 'closed'].includes(ticket.status) && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Editor name="text" />

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginBottom: '40px' }}
                >
                  Responder
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalEditTicket}
        setIsOpen={() => setModalEditTicket(!modalEditTicket)}
      >
        {loadingEditTicket && <Loader />}
        <ModalHeader>
          <div>Atualizar chamado</div>

          <button type="button" onClick={() => setModalEditTicket(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formEditTicketRef} onSubmit={handleEditTicket} noValidate>
          <Select name="category_id" label="Categoria" options={categories} />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar chamado
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Fechar chamado"
        confirmText="Fechar"
        cancelText="Cancelar"
        text="Tem certeza que deseja fechar o chamado?"
        onConfirm={handleCloseTicket}
        isOpen={modalCloseTicket}
        isLoading={loadingCloseTicket}
        setIsOpen={() => setModalCloseTicket(!modalCloseTicket)}
      />
    </SRLWrapper>
  );
};

export default TicketPreview;
