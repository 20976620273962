import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import api from '../../services/api';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import formatDate from '../../utils/formatDate';
import Badge from '../../components/Badge';
import { AchievementItem } from './styles';

interface IAchievement {
  id: string;
  icon_url: string;
  title: string;
  exp: number;
  key: string;
  is_hidden: boolean;
  created_at: string;
}

interface ColumnData {
  label: string;
  field: 'id' | 'title' | 'exp' | 'key' | 'is_hidden' | 'created_at';
  transform?(
    value: string | boolean | number,
    row?: IAchievement,
  ): string | React.ReactElement;
}

const Achievement: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<IAchievement[]>([]);

  const columns = useMemo<ColumnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, "dd/MM/yyyy 'às' HH:mm");
        },
      },
      {
        label: 'Título',
        field: 'title',
        transform: (_, achievement: IAchievement) => {
          return (
            <AchievementItem>
              <img src={achievement.icon_url} alt={achievement.title} />
              {achievement.title}
              <Badge color="success" title={`+${achievement.exp}`} />
            </AchievementItem>
          );
        },
      },
      {
        label: 'Chave',
        field: 'key',
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/conquistas/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/achievements', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Conquistas" items={[{ title: 'Conquistas' }]}>
          <Button
            color="secondary"
            onClick={() => history.push('/conquistas/cadastrar')}
          >
            Cadastrar conquista
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>
                      Nenhuma conquista encontrada
                    </td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Achievement;
