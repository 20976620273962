import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { useDarkMode } from '../../../hooks/theme';

import { darkTheme, lightTheme } from '../../../styles/themes';
import Button from '../../Button';
import Loader from '../../Loader';

import { Header, Content } from './styles';

interface ModalConfirmProps {
  title: string;
  text: string;
  confirmText: string;
  onConfirm: () => void;
  cancelText: string;
  isOpen: boolean;
  setIsOpen: () => void;
  isLoading: boolean;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  title,
  text,
  confirmText,
  onConfirm,
  cancelText,
  isOpen,
  setIsOpen,
  isLoading,
  children,
}) => {
  const { darkMode } = useDarkMode();

  return (
    <ReactModal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={setIsOpen}
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: 'Calc(50% - 16px)',
          right: 'auto',
          bottom: 'auto',
          margin: '0 16px',
          transform: 'translate(-50%, -50%)',
          borderRadius: '8px',
          border: 'none',
          overflow: 'unset',
          maxWidth: '480px',
          width: 'Calc(100% - 32px)',
          background: darkMode
            ? darkTheme.backgroundCard
            : lightTheme.backgroundCard,
        },
        overlay: {
          backgroundColor: '#121214e6',
          zIndex: 9999,
        },
      }}
    >
      {isLoading && <Loader />}

      <Header>
        <div>{title}</div>
      </Header>

      <Content>
        <p>{text}</p>
        {children}

        <footer>
          <Button type="button" color="primary" onClick={onConfirm}>
            {confirmText}
          </Button>

          <Button type="button" color="none" onClick={setIsOpen}>
            {cancelText}
          </Button>
        </footer>
      </Content>
    </ReactModal>
  );
};

export default ModalConfirm;
