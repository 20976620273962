import styled from 'styled-components';
import { shade } from 'polished';

export const Background = styled.footer`
  background: #1d1a1f;
  padding: 40px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #f1e9f5;
  text-align: center;

  p {
    margin-bottom: 16px;
  }

  @media (min-width: 768px) {
    & {
      flex-direction: row;
      text-align: left;

      p {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      display: block;

      @media (min-width: 375px) {
        & {
          display: inline;
        }

        & + li {
          margin-left: 40px;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.2s;

        &:hover {
          color: ${shade(0.2, '#F1E9F5')};
        }
      }
    }
  }
`;
