import styled, { css } from 'styled-components';

interface ContainerProps {
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 16px;

  > p {
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    margin-bottom: 8px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-width: 2px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.input.placeholder};
    border-style: dashed;
    background-color: ${({ theme }) => theme.input.background};
    color: ${({ theme }) => theme.input.placeholder};
    outline: none;
    transition: border 0.24s ease-in-out, opacity 0.2s;
    cursor: pointer;

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    &:hover {
      opacity: 0.8;
    }

    ${props =>
      props.hasError &&
      css`
        border-color: ${({ theme }) => theme.colors.danger};
        color: ${({ theme }) => theme.colors.danger};

        &:focus {
          border-color: ${({ theme }) => theme.colors.danger};
        }
      `}

    &.disabled {
      opacity: 0.6;
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;

  img {
    width: 100%;
    border-radius: 4px;
  }

  svg,
  img {
    margin-bottom: 8px;
  }

  span {
    margin-bottom: 16px;
  }
`;

export const Error = styled.ul`
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 4px;

  li {
    font-size: 14px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    & + li {
      margin-top: 4px;
    }
  }
`;
