import styled from 'styled-components';

export const FilterCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 40px;
  row-gap: 40px;

  @media (min-width: 769px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
