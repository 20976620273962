import { shade } from 'polished';
import styled from 'styled-components';

export const CorrectionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    display: flex;
    align-items: center;

    span:first-child {
      font-size: 18px;
      color: ${({ theme }) => theme.title};
      font-weight: bold;
    }

    span + span {
      margin-left: 16px;
    }
  }
`;

export const WordingInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  > section {
    > div {
      display: flex;
      align-items: center;

      > img {
        width: 112px;
        height: 112px;
        border-radius: 50%;
        border: 4px solid ${({ theme }) => theme.colors.primary};
        padding: 8px;
        margin-right: 16px;
      }

      > ul {
        list-style: none;

        li {
          line-height: 1.8;
          display: flex;
          align-items: center;

          svg {
            color: ${({ theme }) => theme.title};
            margin-right: 4px;
          }

          > a {
            color: ${({ theme }) => theme.colors.primary};
            text-decoration: none;
          }

          > button {
            svg {
              color: ${({ theme }) => theme.colors.primary};
            }

            background: none;
            border: none;
            line-height: 1;
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme.colors.primary};
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
`;

export const WordingTitle = styled.div`
  margin-bottom: 40px;

  h2 {
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      & + li {
        margin-left: 24px;
      }

      a,
      button {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        }

        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }
`;
