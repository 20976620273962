import React from 'react';
import ApexChart from 'react-apexcharts';

import { useDarkMode } from '../../hooks/theme';

import { Container } from './styles';

export interface Series {
  name: string;
  data: number[];
}

interface ChartProps {
  id: string;
  type:
    | 'line'
    | 'area'
    | 'bar'
    | 'radar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick';
  width?: string;
  height: string;
  colors: string[];
  series: Series[];
  categories: string[];
}

const Chart: React.FC<ChartProps> = ({
  id,
  type,
  colors,
  series,
  categories,
  height,
  width = '100%',
}) => {
  const { darkMode } = useDarkMode();

  return (
    <Container>
      <ApexChart
        options={{
          stroke: {
            curve: 'smooth',
          },
          dataLabels: {
            background: {
              foreColor: 'red',
              dropShadow: {
                enabled: true,
              },
            },
          },
          chart: {
            id,
            foreColor: darkMode ? '#F1E9F5' : '#4d4d4d',
          },
          colors,
          legend: {
            position: 'top',
            horizontalAlign: 'left',
          },
          grid: {
            borderColor: darkMode ? '#3f3d3f' : '#dad8d8',
          },
          xaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              color: darkMode ? '#3f3d3f' : '#dad8d8',
            },
            categories,
          },
        }}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    </Container>
  );
};

export default Chart;
