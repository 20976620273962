import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;

  max-width: 920px;
  margin: 0 auto;
  margin-top: 80px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const Welcome = styled.div`
  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  padding: 80px;
  border-radius: 8px 0 0 8px;
  width: 100%;

  h1 {
    color: #fff;
    line-height: 1.2;
    max-width: 290px;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.backgroundCard};
  padding: 80px;
  border-radius: 0 8px 8px 0;
  width: 100%;
  text-align: center;

  img {
    margin-bottom: 40px;
  }

  a {
    margin-top: 40px;
    color: ${({ theme }) => theme.text};
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
`;
