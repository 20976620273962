import styled from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  active: boolean;
  marginTop: number;
}

export const Container = styled.div<ContainerProps>`
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  position: absolute;
  background: ${({ theme }) => theme.background};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  z-index: 9;
  transition: all 0.2s ease;

  width: 100%;

  @media (min-width: 576px) {
    max-width: 510px;
  }

  @media (min-width: 768px) {
    max-width: 690px;
  }

  @media (min-width: 992px) {
    max-width: 930px;
  }

  @media (min-width: 1200px) {
    max-width: 1110px;
  }
`;

export const WordingTitle = styled.div`
  margin-bottom: 40px;

  h2 {
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      & + li {
        margin-left: 24px;
      }

      a,
      button {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        }

        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }
`;
