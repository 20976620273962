import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { debounce } from 'lodash';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Breadcrumbs from '../../containers/Breadcrumbs';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import DropdownButton from '../../components/DropdownButton';
import Modal, { ModalHeader } from '../../components/Modal';
import Loader from '../../components/Loader';
import Input from '../../components/Input';
import AsyncSelect, { Option } from '../../components/Select/Async';

interface ForumRegistrationFormData {
  title: string;
  slug: string;
  course_id: string;
}

interface CourseData {
  id: string;
  title: string;
}

interface ForumData {
  id: string;
  title: string;
  slug: string;
  course: CourseData;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'title' | 'slug' | 'course' | 'created_at';
  transform?(
    value: string | CourseData,
    row?: ForumData,
  ): string | React.ReactElement;
}

const Forum: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const formForumRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [modalAddForum, setModalAddForum] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<ForumData[]>([]);

  const coursesOptions = (
    inputValue: string,
    callback: (options: Option[]) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/courses/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = format(
            parseISO(created_at),
            "dd/MM/yyyy 'às' HH:mm",
            { locale: ptBR },
          );

          return formattedDate;
        },
      },
      {
        label: 'Nome',
        field: 'title',
      },
      {
        label: 'Slug',
        field: 'slug',
      },
      {
        label: 'Curso',
        field: 'course',
        transform: (course: CourseData) => course.title,
      },
      {
        label: '',
        field: 'id',
        transform: id => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/foruns/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/forums', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleAddForum = useCallback(
    async (formData: ForumRegistrationFormData) => {
      try {
        setModalLoading(true);

        formForumRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          slug: Yup.string().required('Slug obrigatório'),
          course_id: Yup.string().required('Curso obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post<ForumData>('forums', {
          title: formData.title,
          slug: formData.slug,
          course_id: formData.course_id,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O fórum foi cadastrado com sucesso!',
        });

        history.push(`/foruns/visualizar/${response.data.id}`);
      } catch (err) {
        setModalLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formForumRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Fóruns" items={[{ title: 'Fóruns' }]}>
          <Button color="secondary" onClick={() => setModalAddForum(true)}>
            Cadastrar fórum
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum fórum encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddForum}
        setIsOpen={() => setModalAddForum(!modalAddForum)}
      >
        {modalLoading && <Loader />}

        <ModalHeader>
          <div>Cadastrar fórum</div>

          <button type="button" onClick={() => setModalAddForum(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formForumRef} onSubmit={handleAddForum} noValidate>
          <Row>
            <Col md={12}>
              <Input name="title" label="Nome" />
            </Col>

            <Col md={6}>
              <AsyncSelect
                name="course_id"
                label="Curso"
                loadOptions={debounce(coursesOptions, 500)}
                isClearable
              />
            </Col>

            <Col md={6}>
              <Input name="slug" label="Slug" />
            </Col>
          </Row>

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Forum;
