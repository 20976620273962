import styled from 'styled-components';

export const AffiliateLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  header {
    font-weight: bold;
  }

  /* TABLET */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 1rem;
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.text};

  > div {
    max-width: 155px;
    border-radius: 4px;
    border: ${({ theme }) => theme.input.border};
    background: ${({ theme }) => theme.input.background};
    width: 100%;
    margin-left: 16px;

    &:hover,
    &:focus-within {
      border-color: ${({ theme }) => theme.input.border};
    }

    input {
      padding: 8px 12px;
      text-align: left;
      flex: 1;
      width: 100%;
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.input.text};
      cursor: pointer;

      &::placeholder {
        color: ${({ theme }) => theme.input.placeholder};
      }

      &:disabled {
        cursor: not-allowed;
        color: ${({ theme }) => `${theme.input.text}60`};
      }
    }
  }

  .react-datepicker-popper {
    top: 21px !important;
    left: -70px !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

export const HeaderCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* TABLET */
  @media (max-width: 768px) {
    > button {
      display: none;
    }
  }
`;

export const FooterCardContainer = styled.footer`
  width: 100%;
  margin-top: 1rem;

  > input {
    width: 100%;
    padding: 8px 12px;
    flex: 1;
    background: transparent;
    border: none;
    color: #5e5e5e;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    font-size: 12px;
  }
`;
