import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  MdModeEdit,
  MdDelete,
  MdLaunch,
  MdClose,
  MdAttachFile,
} from 'react-icons/md';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { SRLWrapper } from 'simple-react-lightbox';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import DragDropContext from './hooks/dragdrop';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import IconButton from '../../../components/IconButton';

import Cluster from './Cluster';
import ClusterItem from './Cluster/ClusterItem';
import Loader from '../../../components/Loader';
import Modal, { ModalHeader } from '../../../components/Modal';
import ModalConfirm from '../../../components/Modal/Confirm';
import TextArea from '../../../components/TextArea';
import Dropzone from '../../../components/Dropzone';

interface LessonData {
  id: string;
  title: string;
  duration: string;
  formatted_duration: string;
  cluster_id: string;
  thumbnail_url: string;
  download_url: string;
  video: string;
}

interface ClusterRegistrationFormData {
  title: string;
  description: string;
  slug: string;
  background: string;
}

interface LessonRegistrationFormData {
  title: string;
  description: string;
  video: string;
  duration: string;
}

interface CourseData {
  id: string;
  title: string;
  slug: string;
}

interface ClusterData {
  id: string;
  title: string;
  description: string;
  thumbnail_url: string;
  lessons: LessonData[];
}

const CoursePreview: React.FC = () => {
  const { course_slug } = useParams<{ course_slug: string }>();

  const history = useHistory();
  const { addToast } = useToast();

  const formAddClusterRef = useRef<FormHandles>(null);
  const formEditClusterRef = useRef<FormHandles>(null);

  const formAddLessonRef = useRef<FormHandles>(null);
  const formEditLessonRef = useRef<FormHandles>(null);

  const [course, setCourse] = useState<CourseData>({} as CourseData);
  const [clusters, setClusters] = useState<ClusterData[]>([] as ClusterData[]);

  const [selectedClusterId, setSelectedClusterId] = useState('');
  const [selectedCluster, setSelectedCluster] = useState<ClusterData>(
    {} as ClusterData,
  );
  const [selectedLesson, setSelectedLesson] = useState<LessonData>(
    {} as LessonData,
  );

  const [clusterLoading, setClusterLoading] = useState(false);

  const [modalLoading, setModalLoading] = useState(false);

  const [modalAddCluster, setModalAddCluster] = useState(false);
  const [modalEditCluster, setModalEditCluster] = useState(false);
  const [modalDelCluster, setModalDelCluster] = useState(false);

  const [modalAddLesson, setModalAddLesson] = useState(false);
  const [modalEditLesson, setModalEditLesson] = useState(false);
  const [modalDelLesson, setModalDelLesson] = useState(false);

  const [thumbnailFile, setThumbnailFile] = useState<Blob | string>('');
  const [materialFile, setMaterialFile] = useState<Blob | string>('');

  useEffect(() => {
    async function loadData() {
      setClusterLoading(true);

      try {
        const [courseResponse, clustersResponse] = await Promise.all([
          api.get(`/courses/${course_slug}`),
          api.get(`/courses/${course_slug}/clusters`),
        ]);

        setCourse(courseResponse.data);
        setClusters(clustersResponse.data);

        setClusterLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O curso não foi encontrado!',
        });

        history.push('/cursos');
      }
    }

    loadData();
  }, [addToast, history, course_slug]);

  const handleAddCluster = useCallback(
    async (data: ClusterRegistrationFormData) => {
      setModalLoading(true);

      formAddClusterRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          slug: Yup.string().required('Slug obrigatório'),
          background: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('slug', data.slug);
        formData.append('background', data.background);
        formData.append('thumbnail', thumbnailFile);

        const response = await api.post<ClusterData>(
          `courses/${course.id}/clusters`,
          formData,
        );

        const cluster = {
          ...response.data,
          lessons: [],
        };

        setClusters(oldClusters => [...oldClusters, cluster]);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O módulo foi cadastrado com sucesso!',
        });

        setModalAddCluster(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddClusterRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setThumbnailFile('');
        setModalLoading(false);
      }
    },
    [addToast, course.id, thumbnailFile],
  );

  const handleEditCluster = useCallback(
    async data => {
      setModalLoading(true);

      formEditClusterRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          slug: Yup.string().required('Slug obrigatório'),
          background: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('slug', data.slug);
        formData.append('background', data.background);
        if (thumbnailFile) formData.append('thumbnail', thumbnailFile);

        const response = await api.put<ClusterData>(
          `courses/${course.id}/clusters/${selectedCluster.id}`,
          formData,
        );

        const cluster = {
          ...selectedCluster,
          ...response.data,
        };

        setClusters(oldClusters =>
          oldClusters.map(oldCluster =>
            oldCluster.id === selectedCluster.id ? cluster : oldCluster,
          ),
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O módulo foi atualizado com sucesso!',
        });

        setModalEditCluster(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditClusterRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setThumbnailFile('');
        setModalLoading(false);
      }
    },
    [addToast, course.id, selectedCluster, thumbnailFile],
  );

  const handleDelCluster = useCallback(async () => {
    setModalLoading(true);

    try {
      await api.delete(`courses/${course.id}/clusters/${selectedClusterId}`);

      setClusters(oldClusters =>
        oldClusters.filter(cluster => cluster.id !== selectedClusterId),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'O módulo foi excluído com sucesso!',
      });

      setModalDelCluster(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setModalLoading(false);
    }
  }, [addToast, course.id, selectedClusterId]);

  const handleAddLesson = useCallback(
    async (data: LessonRegistrationFormData) => {
      setModalLoading(true);

      formAddLessonRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          video: Yup.string().required('Vídeo obrigatório'),
          duration: Yup.string().required('Duração obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const [hours, minutes, seconds] = data.duration.split(':');

        const duration =
          Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('video', data.video);
        formData.append('duration', String(duration));
        if (thumbnailFile) formData.append('thumbnail', thumbnailFile);
        if (materialFile) formData.append('download', materialFile);

        const response = await api.post<LessonData>(
          `clusters/${selectedClusterId}/lessons`,
          formData,
        );

        setClusters(oldClusters =>
          oldClusters.map(oldCluster => {
            if (oldCluster.id === selectedClusterId) {
              return {
                ...oldCluster,
                lessons: [...oldCluster.lessons, response.data],
              };
            }
            return oldCluster;
          }),
        );

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A aula foi cadastrada com sucesso!',
        });

        setModalAddLesson(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddLessonRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setThumbnailFile('');
        setModalLoading(false);
      }
    },
    [addToast, thumbnailFile, materialFile, selectedClusterId],
  );

  const handleEditLesson = useCallback(
    async data => {
      setModalLoading(true);

      formEditLessonRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          video: Yup.string().required('Vídeo obrigatório'),
          duration: Yup.string().required('Duração obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const [hours, minutes, seconds] = data.duration.split(':');

        const duration =
          Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('video', data.video);
        formData.append('duration', String(duration));
        if (thumbnailFile) formData.append('thumbnail', thumbnailFile);
        if (materialFile) formData.append('download', materialFile);

        const response = await api.put<LessonData>(
          `clusters/${selectedLesson.cluster_id}/lessons/${selectedLesson.id}`,
          formData,
        );

        const lesson = response.data;

        setClusters(oldClusters =>
          oldClusters.map(oldCluster => {
            if (oldCluster.id === selectedLesson.cluster_id) {
              return {
                ...oldCluster,
                lessons: oldCluster.lessons.map(oldLesson =>
                  oldLesson.id === lesson.id ? lesson : oldLesson,
                ),
              };
            }
            return oldCluster;
          }),
        );

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A aula foi cadastrada com sucesso!',
        });

        setModalEditLesson(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditLessonRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setThumbnailFile('');
        setModalLoading(false);
      }
    },
    [addToast, thumbnailFile, materialFile, selectedLesson],
  );

  const handleDelLesson = useCallback(async () => {
    setModalLoading(true);

    try {
      await api.delete(`lessons/${selectedLesson.id}`);

      setClusters(oldClusters =>
        oldClusters.map(oldCluster => {
          if (oldCluster.id === selectedLesson.cluster_id) {
            return {
              ...oldCluster,
              lessons: oldCluster.lessons.filter(
                oldLesson => oldLesson.id !== selectedLesson.id,
              ),
            };
          }
          return oldCluster;
        }),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'A aula foi excluída com sucesso!',
      });

      setModalDelLesson(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setModalLoading(false);
    }
  }, [addToast, selectedLesson]);

  function move(from: number, to: number) {
    // console.log(from, to);
  }

  return (
    <>
      <DragDropContext.Provider value={{ clusters, move }}>
        <Header />

        <Container style={{ flex: 1 }}>
          <Breadcrumbs
            title={course.title}
            items={[
              { title: 'Cursos', link: '/cursos' },
              { title: course.title },
            ]}
          >
            <Button color="secondary" onClick={() => setModalAddCluster(true)}>
              Cadastrar módulo
            </Button>
          </Breadcrumbs>

          <Row>
            {clusterLoading && <Loader />}

            <Col md={12}>
              <SRLWrapper
                options={{
                  settings: {
                    autoplaySpeed: 0,
                    disableKeyboardControls: true,
                    disableWheelControls: true,
                    disablePanzoom: true,
                    hideControlsAfter: true,
                    overlayColor: 'rgba(18, 18, 20, 0.9)',
                  },
                  buttons: {
                    showAutoplayButton: false,
                    showCloseButton: false,
                    showDownloadButton: false,
                    showFullscreenButton: false,
                    showNextButton: false,
                    showPrevButton: false,
                    showThumbnailsButton: false,
                  },
                  thumbnails: {
                    showThumbnails: false,
                  },
                  progressBar: {
                    showProgressBar: false,
                  },
                  caption: {
                    showCaption: false,
                  },
                }}
              >
                {clusters.map(cluster => (
                  <Cluster
                    key={cluster.id}
                    title={cluster.title}
                    thumbnail={cluster.thumbnail_url}
                    onCreate={() => {
                      setSelectedClusterId(cluster.id);
                      setModalAddLesson(true);
                    }}
                    onUpdate={() => {
                      setSelectedCluster(cluster);
                      setModalEditCluster(true);
                    }}
                    onDelete={() => {
                      setSelectedClusterId(cluster.id);
                      setModalDelCluster(true);
                    }}
                  >
                    {cluster.lessons.map((lesson, index) => (
                      <ClusterItem key={lesson.id} index={index}>
                        <img
                          src={
                            lesson.thumbnail_url ||
                            `https://content.jwplatform.com/thumbs/${lesson.video}-320.jpg`
                          }
                          alt={lesson.title}
                        />

                        <span>
                          {lesson.title}
                          {lesson.download_url && (
                            <a href={lesson.download_url} target="blank">
                              <MdAttachFile />
                              material
                            </a>
                          )}
                        </span>

                        <aside>
                          <IconButton icon={MdLaunch} />
                          <IconButton
                            icon={MdModeEdit}
                            onClick={() => {
                              setSelectedLesson({
                                ...lesson,
                                duration: lesson.formatted_duration,
                              });
                              setModalEditLesson(true);
                            }}
                          />
                          <IconButton
                            icon={MdDelete}
                            onClick={() => {
                              setSelectedLesson({
                                ...lesson,
                                duration: lesson.formatted_duration,
                              });
                              setModalDelLesson(true);
                            }}
                          />
                        </aside>
                      </ClusterItem>
                    ))}
                  </Cluster>
                ))}
              </SRLWrapper>
            </Col>
          </Row>
        </Container>

        <Footer />
      </DragDropContext.Provider>

      <Modal
        isOpen={modalAddCluster}
        setIsOpen={() => setModalAddCluster(!modalAddCluster)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Cadastrar módulo</div>

          <button type="button" onClick={() => setModalAddCluster(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddClusterRef} onSubmit={handleAddCluster} noValidate>
          <Input type="text" name="title" label="Nome" />

          <Row>
            <Col md={6}>
              <Input type="text" name="slug" label="Slug" />
            </Col>

            <Col md={6}>
              <Input type="text" name="background" label="Background" />
            </Col>
          </Row>

          <Dropzone
            label="Thumbnail"
            accept="image/*"
            maxSize={3000000}
            onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
          />

          <TextArea name="description" label="Descrição" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar módulo
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditCluster}
        setIsOpen={() => setModalAddCluster(!modalEditCluster)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Atualizar módulo</div>

          <button type="button" onClick={() => setModalEditCluster(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          initialData={selectedCluster}
          ref={formEditClusterRef}
          onSubmit={handleEditCluster}
          noValidate
        >
          <Input type="text" name="title" label="Nome" />

          <Row>
            <Col md={6}>
              <Input type="text" name="slug" label="Slug" />
            </Col>

            <Col md={6}>
              <Input type="text" name="background" label="Background" />
            </Col>
          </Row>

          <Dropzone
            label="Thumbnail"
            accept="image/*"
            maxSize={3000000}
            onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
          />

          <TextArea name="description" label="Descrição" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar módulo
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir módulo"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir o módulo? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelCluster}
        isOpen={modalDelCluster}
        isLoading={modalLoading}
        setIsOpen={() => setModalDelCluster(!modalDelCluster)}
      />

      <Modal
        isOpen={modalAddLesson}
        setIsOpen={() => setModalAddLesson(!modalAddLesson)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Cadastrar aula</div>

          <button type="button" onClick={() => setModalAddLesson(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddLessonRef} onSubmit={handleAddLesson} noValidate>
          <Input type="text" name="title" label="Nome" />

          <Row>
            <Col md={6}>
              <Input type="text" name="video" label="Vídeo" />
            </Col>

            <Col md={6}>
              <InputMask
                type="text"
                name="duration"
                label="Duração"
                mask="99:99:99"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Dropzone
                label="Thumbnail"
                accept="image/*"
                maxSize={3000000}
                onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
              />
            </Col>

            <Col md={6}>
              <Dropzone
                label="Material"
                accept=".pdf"
                maxSize={3000000}
                onDrop={acceptedFile => setMaterialFile(acceptedFile)}
              />
            </Col>
          </Row>

          <TextArea name="description" label="Descrição" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar aula
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditLesson}
        setIsOpen={() => setModalEditLesson(!modalEditLesson)}
      >
        {modalLoading && <Loader />}
        <ModalHeader>
          <div>Atualizar aula</div>

          <button type="button" onClick={() => setModalEditLesson(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          initialData={selectedLesson}
          ref={formEditLessonRef}
          onSubmit={handleEditLesson}
          noValidate
        >
          <Input type="text" name="title" label="Nome" />

          <Row>
            <Col md={6}>
              <Input type="text" name="video" label="Vídeo" />
            </Col>

            <Col md={6}>
              <InputMask
                type="text"
                name="duration"
                label="Duração"
                mask="99:99:99"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Dropzone
                label="Thumbnail"
                accept="image/*"
                maxSize={3000000}
                onDrop={acceptedFile => setThumbnailFile(acceptedFile)}
              />
            </Col>

            <Col md={6}>
              <Dropzone
                label="Material"
                accept=".pdf"
                maxSize={3000000}
                onDrop={acceptedFile => setMaterialFile(acceptedFile)}
              />
            </Col>
          </Row>

          <TextArea name="description" label="Descrição" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Atualizar aula
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir aula"
        confirmText="Excluir"
        cancelText="Cancelar"
        text="Tem certeza que deseja excluir a aula? Essa ação não poderá ser
        desfeita."
        onConfirm={handleDelLesson}
        isOpen={modalDelLesson}
        isLoading={modalLoading}
        setIsOpen={() => setModalDelLesson(!modalDelLesson)}
      />
    </>
  );
};

export default CoursePreview;
