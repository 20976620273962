import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { MdThumbUp } from 'react-icons/md';
import { useEffect } from 'react';
import { Container } from 'styled-bootstrap-grid';
import Button from '../../../components/Button';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Footer from '../../../containers/Footer';
import Header from '../../../containers/Header';
import api from '../../../services/api';

import { ArticleContainer, ArticleBox } from './styles';

interface SectionData {
  id: string;
  slug: string;
  name: string;
}

interface ArticleData {
  id: string;
  title: string;
  body: string;
  abstract: string;
  slug: string;
  is_promoted: boolean;
  vote_count: number;
  vote_sum: number;
}

const Article: React.FC = () => {
  const { section_slug } = useParams<{ section_slug: string }>();
  const history = useHistory();

  const [section, setSection] = useState<SectionData>({} as SectionData);
  const [articles, setArticles] = useState<ArticleData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        const [sectionResponse, articlesResponse] = await Promise.all([
          api.get(`/sections/${section_slug}`),
          api.get(`/sections/${section_slug}/articles`),
        ]);

        setSection(sectionResponse.data);
        setArticles(articlesResponse.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
  }, [section_slug]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title={`Artigos de ${section.name}`}
          items={[
            { title: 'Artigos e Seções', link: '/secoes' },
            { title: `Artigos de ${section.name}` },
          ]}
        >
          <Button
            color="secondary"
            onClick={() => history.push(`/secoes/${section_slug}/cadastrar`)}
          >
            Cadastrar artigo
          </Button>
        </Breadcrumbs>

        <ArticleContainer>
          {articles.map((article, index) => (
            <ArticleBox
              isPromoted={article.is_promoted}
              key={article.id}
              to={`/secoes/${section_slug}/${article.slug}`}
            >
              <div>
                {`${article.vote_sum} de ${article.vote_count}`}
                <MdThumbUp size={16} />
              </div>

              <span>{`${index + 1}.`}</span>

              <h4>{article.title}</h4>

              <p>{article.abstract}</p>
            </ArticleBox>
          ))}
        </ArticleContainer>
      </Container>

      <Footer />
    </>
  );
};

export default Article;
