import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background};
  transition: all 0.2s ease;

  width: 100%;

  @media (min-width: 576px) {
    max-width: 510px;
  }

  @media (min-width: 768px) {
    max-width: 690px;
  }

  @media (min-width: 992px) {
    max-width: 930px;
  }

  @media (min-width: 992px) {
    max-width: 1110px;
  }
`;

export const ReportButton = styled.button`
  color: ${({ theme }) => theme.colors.danger};
  background: none;
  border: none;
  font-weight: bold;
  transition: color 0.2s;
  padding: 0 18px;
  height: 44px;
  margin-right: 16px;

  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: ${({ theme }) => shade(0.1, theme.colors.danger)};
  }
`;

export const WordingTitle = styled.div`
  margin-bottom: 40px;

  h2 {
    color: ${({ theme }) => theme.title};
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      & + li {
        margin-left: 24px;
      }

      a,
      button {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        }

        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }
`;
