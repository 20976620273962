import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean;
  disabled: boolean;
  size: 'sm' | 'md';
  pulse: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    margin-bottom: 8px;
  }

  > button {
    height: ${props => (props.size === 'md' ? '28px' : '20px')};
    width: ${props => (props.size === 'md' ? '52px' : '36px')};
    display: inline-block;
    background: ${props =>
      props.active ? props.theme.colors.tertiary : props.theme.text};
    cursor: pointer;
    border-radius: 16px;
    position: relative;
    border: none;

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}

    ${({ pulse }) =>
      pulse &&
      css`
        animation: pulse 2s infinite;
      `}

    div {
      width: ${props => (props.size === 'md' ? '24px' : '16px')};
      height: ${props => (props.size === 'md' ? '24px' : '16px')};
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: 2px;
      transition: transform 0.2s;

      ${props =>
        props.active &&
        css`
          transform: ${props.size === 'md'
            ? 'translateX(24px)'
            : 'translateX(16px)'};
        `};
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${({ theme }) => theme.colors.primary};
    }
    70% {
      box-shadow: 0 0 0 15px rgb(255 255 255 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
    }
  }
`;
