import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Badge from '../../../components/Badge';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Datatable from '../../../components/Datatable';
import DropdownButton from '../../../components/DropdownButton';
import Modal, { ModalHeader } from '../../../components/Modal';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import ModalConfirm from '../../../components/Modal/Confirm';

interface CategoryData {
  id: string;
  name: string;
  active: boolean;
  created_at: string;
}

interface columnData {
  label: string;
  field: 'id' | 'name' | 'active' | 'created_at';
  transform?(
    value: string | boolean,
    row?: CategoryData,
  ): string | React.ReactElement;
}

interface CategoryRegistrationFormData {
  name: string;
}

interface CategoryUpdationFormData {
  name: string;
}

interface Category {
  id: string;
  name: string;
  active: boolean;
}

const TicketCategory: React.FC = () => {
  const { addToast } = useToast();

  const formAddCategoryRef = useRef<FormHandles>(null);
  const formEditCategoryRef = useRef<FormHandles>(null);

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category,
  );

  const [loading, setLoading] = useState(false);
  const [loadingAddCategory, setLoadingAddCategory] = useState(false);
  const [loadingEditCategory, setLoadingEditCategory] = useState(false);
  const [loadingDelCategory, setLoadingDelCategory] = useState(false);
  const [loadingSwitchCategory, setLoadingSwitchCategory] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [modalAddCategory, setModalAddCategory] = useState(false);
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [modalDelCategory, setModalDelCategory] = useState(false);
  const [modalSwitchCategory, setModalSwitchCategory] = useState(false);

  const [data, setData] = useState<CategoryData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/tickets/categories', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (
      !modalAddCategory &&
      !modalEditCategory &&
      !modalDelCategory &&
      !modalSwitchCategory
    ) {
      loadData();
    }
  }, [
    pageIndex,
    search,
    modalAddCategory,
    modalEditCategory,
    modalDelCategory,
    modalSwitchCategory,
  ]);

  const handleAddCategory = useCallback(
    async (formData: CategoryRegistrationFormData) => {
      setLoadingAddCategory(true);

      formAddCategoryRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.post('/tickets/categories', {
          name: formData.name,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A categoria foi cadastrada com sucesso!',
        });

        setModalAddCategory(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddCategoryRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setLoadingAddCategory(false);
      }
    },
    [addToast],
  );

  const handleEditCategory = useCallback(
    async (formData: CategoryUpdationFormData) => {
      setLoadingEditCategory(true);

      formEditCategoryRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(formData, { abortEarly: false });

        await api.put(`/tickets/categories/${selectedCategory.id}`, {
          name: formData.name,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'A categoria foi atualizada com sucesso!',
        });

        setModalEditCategory(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formEditCategoryRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      } finally {
        setLoadingEditCategory(false);
      }
    },
    [addToast, selectedCategory.id],
  );

  const handleDelCategory = useCallback(async () => {
    setLoadingDelCategory(true);

    try {
      await api.delete(`/tickets/categories/${selectedCategory.id}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'A categoria foi excluída com sucesso!',
      });

      setModalDelCategory(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão, verifique os campos.',
      });
    } finally {
      setLoadingDelCategory(false);
    }
  }, [addToast, selectedCategory.id]);

  const handleSwitchCategory = useCallback(async () => {
    setLoadingSwitchCategory(true);

    try {
      await api.put(`/tickets/categories/${selectedCategory.id}/status`);

      addToast({
        type: 'success',
        title: 'Sucesso na atualização',
        description: 'A categoria foi atualizada com sucesso!',
      });

      setModalSwitchCategory(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description: 'Ocorreu um erro na atualização, verifique os campos.',
      });
    } finally {
      setLoadingSwitchCategory(false);
    }
  }, [addToast, selectedCategory.id]);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return new Date(created_at).toLocaleString('pt-BR');
        },
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: '',
        field: 'active',
        transform: (active: boolean) =>
          active ? (
            <Badge color="success" title="Ativa" />
          ) : (
            <Badge color="error" title="Inativa" />
          ),
      },
      {
        label: '',
        field: 'id',
        transform: (id: string, row: CategoryData) => (
          <DropdownButton>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCategory({
                      id,
                      name: row.name,
                      active: row.active,
                    });
                    setModalEditCategory(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Alterar
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCategory({
                      id,
                      name: row.name,
                      active: row.active,
                    });
                    setModalSwitchCategory(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  {row.active ? 'Inativar' : 'Ativar'}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedCategory({
                      id,
                      name: row.name,
                      active: row.active,
                    });
                    setModalDelCategory(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Excluir
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Categorias dos chamados"
          items={[
            {
              title: 'Chamados',
              link: '/chamados',
            },
            { title: 'Categorias' },
          ]}
        >
          <Button color="secondary" onClick={() => setModalAddCategory(true)}>
            Cadastrar categoria
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddCategory}
        setIsOpen={() => setModalAddCategory(!modalAddCategory)}
      >
        {loadingAddCategory && <Loader />}
        <ModalHeader>
          <div>Cadastrar categoria</div>

          <button type="button" onClick={() => setModalAddCategory(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form ref={formAddCategoryRef} onSubmit={handleAddCategory} noValidate>
          <Input label="Nome" name="name" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Cadastrar categoria
          </Button>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditCategory}
        setIsOpen={() => setModalEditCategory(!modalEditCategory)}
      >
        {loadingEditCategory && <Loader />}
        <ModalHeader>
          <div>Alterar categoria</div>

          <button type="button" onClick={() => setModalEditCategory(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formEditCategoryRef}
          onSubmit={handleEditCategory}
          noValidate
          initialData={{ name: selectedCategory.name }}
        >
          <Input label="Nome" name="name" />

          <Button type="submit" color="primary" style={{ marginTop: '16px' }}>
            Alterar
          </Button>
        </Form>
      </Modal>

      <ModalConfirm
        title={`${selectedCategory.active ? 'Inativar' : 'Ativar'} categoria`}
        confirmText={selectedCategory.active ? 'Inativar' : 'Ativar'}
        cancelText="Cancelar"
        text={`Tem certeza que deseja ${
          selectedCategory.active ? 'inativar' : 'ativar'
        } a categoria "${selectedCategory.name}"?`}
        onConfirm={handleSwitchCategory}
        isOpen={modalSwitchCategory}
        isLoading={loadingSwitchCategory}
        setIsOpen={() => setModalSwitchCategory(!modalSwitchCategory)}
      />

      <ModalConfirm
        title="Excluir categoria"
        confirmText="Excluir"
        cancelText="Cancelar"
        text={`Tem certeza que deseja excluir a categoria "${selectedCategory.name}"? Essa ação não poderá ser desfeita.`}
        onConfirm={handleDelCategory}
        isOpen={modalDelCategory}
        isLoading={loadingDelCategory}
        setIsOpen={() => setModalDelCategory(!modalDelCategory)}
      />
    </>
  );
};

export default TicketCategory;
