import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Dropzone from '../../../components/Dropzone';
import TextArea from '../../../components/TextArea';
import AsyncSelect, { Option } from '../../../components/Select/Async';
import Datepicker from '../../../components/Datepicker';

interface AppointmentRegistrationFormData {
  course_id: string;
  start: string;
  end: string;
  title: string;
  link: string;
  description: string;
}

const AppointmentRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [coverFile, setCoverFile] = useState<Blob | string>('');

  const { addToast } = useToast();
  const history = useHistory();

  const coursesOptions = (
    inputValue: string,
    callback: (options: Option[]) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/courses/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  const handleSubmit = useCallback(
    async (data: AppointmentRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          course_id: Yup.string().notRequired(),
          start: Yup.date().nullable().required('Início obrigatório'),
          end: Yup.date().nullable().required('Fim obrigatório'),
          title: Yup.string().required('Título obrigatório'),
          link: Yup.string().url('Link em formato inválido').notRequired(),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();

        data.course_id && formData.append('course_id', data.course_id);
        formData.append('start', data.start);
        formData.append('end', data.end);
        formData.append('title', data.title);
        formData.append('link', data.link);
        formData.append('description', data.description);
        coverFile && formData.append('cover', coverFile);

        await api.post('appointments', formData);

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O evento foi cadastrado com sucesso!',
        });

        history.push('/eventos');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history, coverFile],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar evento"
          items={[
            { title: 'Eventos', link: '/eventos' },
            { title: 'Cadastrar evento' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}
              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={6}>
                    <Input type="text" name="title" label="Título" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="link" label="Link" />
                  </Col>

                  <Col md={4}>
                    <AsyncSelect
                      name="course_id"
                      label="Curso"
                      loadOptions={debounce(coursesOptions, 500)}
                      isClearable
                    />
                  </Col>

                  <Col md={4}>
                    <Datepicker
                      name="start"
                      label="Início"
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </Col>

                  <Col md={4}>
                    <Datepicker
                      name="end"
                      label="Fim"
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </Col>

                  <Col md={12}>
                    <TextArea name="description" label="Descrição" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Dropzone
                      label="Capa"
                      accept="image/*"
                      maxSize={3000000}
                      onDrop={acceptedFile => setCoverFile(acceptedFile)}
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar evento
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default AppointmentRegistration;
