import { Area } from 'react-easy-crop/types';

interface IResizeProps {
  width: number;
  height: number;
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getCroppedImage = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  resize: IResizeProps,
): Promise<File | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  image.width /= 2;
  image.height /= 2;

  const safeArea = Math.max(image.width, image.height) * 2;

  canvas.width = safeArea;
  canvas.height = safeArea;

  if (!ctx) {
    return null;
  }

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(image, safeArea / 2 - image.width, safeArea / 2 - image.height);

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width - pixelCrop.x,
    0 - safeArea / 2 + image.height - pixelCrop.y,
  );

  if (resize) {
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');
    oc.width = resize.width;
    oc.height = resize.height;

    if (!octx) {
      return null;
    }

    octx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      resize.width,
      resize.height,
    );

    return new Promise(resolve => {
      oc.toBlob(blobFile => {
        if (!blobFile) {
          return;
        }

        const file = new File([blobFile], 'croppedImage.jpeg', {
          lastModified: Date.now(),
          type: 'image/jpeg',
        });

        resolve(file);
      }, 'image/jpeg');
    });
  }

  return new Promise(resolve => {
    canvas.toBlob(blobFile => {
      if (!blobFile) {
        return;
      }

      const file = new File([blobFile], 'croppedImage.jpeg', {
        lastModified: Date.now(),
        type: 'image/jpeg',
      });

      resolve(file);
    }, 'image/jpeg');
  });
};

export default getCroppedImage;
