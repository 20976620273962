import styled from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: inline-block !important;

  > button {
    display: flex;
    align-items: center;

    background: ${({ theme }) => theme.colors.primary};
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    padding: 6px 12px;

    > svg {
      margin-left: 4px;
    }
  }

  > div {
    background: ${({ theme }) => theme.backgroundCard};
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadow};
    display: ${props => (props.isOpen ? 'block' : 'none')};
    padding: 8px 0;
    position: absolute;
    right: 0;
    top: Calc(100% + 8px);
    width: 140px;
    z-index: 999;

    > ul {
      list-style: none;

      > li a,
      > li button {
        padding: 8px 16px;
        color: ${({ theme }) => theme.text};
        font-weight: normal;
        display: flex;
        align-items: center;
        transition: padding 0.2s;
        border: none;
        background: none;
        text-decoration: none;
        width: 100%;

        &:hover {
          background: ${({ theme }) =>
            shade(theme.hoverPercentage, theme.backgroundCard)};
          color: ${({ theme }) => theme.colors.primary};
          padding-left: 24px;
        }

        svg {
          margin-right: 8px;
        }

        span {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
`;
