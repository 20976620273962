import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    line-height: 1.2;
    color: ${({ theme }) => theme.title};
  }
`;

export const WelcomeMessage = styled.div`
  @media (max-width: 991px) {
    & {
      margin-bottom: 16px;
    }
  }
`;

export const BreadcrumbsItems = styled.ul`
  margin-top: 4px;

  li {
    display: inline;
    color: ${({ theme }) => theme.text};

    & + li::before {
      content: '/';
      margin: 0 8px;
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }
`;
