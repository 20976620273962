import styled, { css } from 'styled-components';

interface SliderProps {
  color: string;
  disabled: boolean;
}

export const Container = styled.div<SliderProps>`
  width: 100%;
  margin-bottom: 24px;
  display: inline-block;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.45;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    `}

  > div:first-child {
    position: relative;
    padding-top: 34px;
    margin-right: 8px;
    margin-left: 8px;

    > div {
      top: 0;
      position: absolute;
      padding: 5px 10px;
      background: #1d1a1f;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      border-radius: 4px;
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;

        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-top: 4px solid #1d1a1f;
        transform: translateX(-50%);
      }
    }
  }

  input[type='range'] {
    appearance: none;
    width: 100%;
    background: none;

    &::-webkit-slider-runnable-track {
      background: ${props => props.color};
      height: 8px;
      border-radius: 4px;

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        background: red;
      }
    }

    &::-webkit-slider-thumb {
      margin-top: -4px;
      appearance: none;
      cursor: pointer;
      background: #1d1a1f;
      width: 16px;
      height: 16px;
      border-radius: 50%;

      ${props =>
        props.disabled &&
        css`
          cursor: not-allowed;
        `}
    }
  }
`;
