import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import ScrollToTop from './ScrollToTop';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

import Forgot from '../pages/ForgotPassword';
import Reset from '../pages/ResetPassword';

import Student from '../pages/Student';
import StudentRegistration from '../pages/Student/StudentRegistration';
import StudentPreview from '../pages/Student/StudentPreview';

import User from '../pages/User';
import UserRegistration from '../pages/User/UserRegistration';
import UserPreview from '../pages/User/UserPreview';

import Theme from '../pages/Theme';
import ThemeRegistration from '../pages/Theme/ThemeRegistration';
import ThemePreview from '../pages/Theme/ThemePreview';

import Material from '../pages/Material';
import MaterialRegistration from '../pages/Material/MaterialRegistration';
import MaterialPreview from '../pages/Material/MaterialPreview';

import Credit from '../pages/Credit';

import Product from '../pages/Product';
import ProductRegistration from '../pages/Product/ProductRegistration';

import Mark from '../pages/Mark';

import Exam from '../pages/Exam';

import Transaction from '../pages/Transaction';

import Course from '../pages/Course';
import CoursePreview from '../pages/Course/CoursePreview';

import Ticket from '../pages/Ticket';
import TicketPreview from '../pages/Ticket/TicketPreview';
import TicketCategory from '../pages/Ticket/Category';

import Correction from '../pages/Correction';

import Forum from '../pages/Forum';
import ForumPreview from '../pages/Forum/ForumPreview';

import Profile from '../pages/Profile';

import Wording from '../pages/Wording';
import WordingPreview from '../pages/Wording/WordingPreview';

import Appointment from '../pages/Appointment';
import AppointmentRegistration from '../pages/Appointment/AppointmentRegistration';
import AppointmentPreview from '../pages/Appointment/AppointmentPreview';

import Report from '../pages/Report';
import ReportCategory from '../pages/Report/Category';
import ReportPreview from '../pages/Report/ReportPreview';

import Section from '../pages/Section';

import Notification from '../pages/Notification';
import MaterialDownload from '../pages/Material/MaterialDownload';

import Article from '../pages/Section/Article';
import ArticleRegistration from '../pages/Section/Article/ArticleRegistration';
import ArticlePreview from '../pages/Section/Article/ArticlePreview';

import Career from '../pages/Career';
import University from '../pages/University';

import Coupon from '../pages/Coupon';
import CouponRegistration from '../pages/Coupon/CouponRegistration';

import Achievement from '../pages/Achievement';
import AchievementPreview from '../pages/Achievement/AchievementPreview';
import AchievementRegistration from '../pages/Achievement/AchievementRegistration';
import CorrectionPreview from '../pages/Correction/CorrectionPreview';
import CorrectionStatistics from '../pages/CorrectionStatistics';
import Partner from '../pages/Partner';
import PartnerRegistration from '../pages/Partner/PartnerRegistration';
import PartnerPreview from '../pages/Partner/PartnerPreview';
import Affiliate from '../pages/Affiliate';

const Routes: React.FC = () => (
  <ScrollToTop>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route path="/afiliados" exact component={Affiliate} isPrivate />

      <Route path="/alunos" exact component={Student} isPrivate />
      <Route
        path="/alunos/cadastrar"
        exact
        component={StudentRegistration}
        isPrivate
      />
      <Route
        path="/alunos/visualizar/:id"
        exact
        component={StudentPreview}
        isPrivate
      />

      <Route path="/usuarios" exact component={User} isPrivate />
      <Route
        path="/usuarios/cadastrar"
        exact
        component={UserRegistration}
        isPrivate
      />
      <Route
        path="/usuarios/visualizar/:id"
        exact
        component={UserPreview}
        isPrivate
      />

      <Route path="/temas" exact component={Theme} isPrivate />
      <Route
        path="/temas/cadastrar"
        exact
        component={ThemeRegistration}
        isPrivate
      />
      <Route
        path="/temas/visualizar/:id"
        exact
        component={ThemePreview}
        isPrivate
      />

      <Route path="/materiais" exact component={Material} isPrivate />
      <Route
        path="/materiais/cadastrar"
        exact
        component={MaterialRegistration}
        isPrivate
      />
      <Route
        path="/materiais/visualizar/:id"
        exact
        component={MaterialPreview}
        isPrivate
      />
      <Route
        path="/materiais/download/:id"
        exact
        component={MaterialDownload}
        isPrivate
      />

      <Route path="/creditos" exact component={Credit} isPrivate />

      <Route path="/planos" exact component={Product} isPrivate />
      <Route
        path="/planos/cadastrar"
        exact
        component={ProductRegistration}
        isPrivate
      />

      <Route path="/marcacoes" exact component={Mark} isPrivate />

      <Route path="/exames" exact component={Exam} isPrivate />

      <Route path="/cursos" exact component={Course} isPrivate />
      <Route
        path="/cursos/:course_slug"
        exact
        component={CoursePreview}
        isPrivate
      />

      <Route path="/vendas" exact component={Transaction} isPrivate />

      <Route path="/chamados" exact component={Ticket} isPrivate />
      <Route
        path="/chamados/visualizar/:id"
        exact
        component={TicketPreview}
        isPrivate
      />
      <Route
        path="/chamados/categorias"
        exact
        component={TicketCategory}
        isPrivate
      />

      <Route path="/corrigir" exact component={Correction} isPrivate />

      <Route path="/redacoes" exact component={Wording} isPrivate />
      <Route
        path="/redacoes/visualizar/:wording_id"
        exact
        component={WordingPreview}
        isPrivate
      />
      <Route
        path="/correcoes/visualizar/:correction_id"
        exact
        component={CorrectionPreview}
        isPrivate
      />
      <Route
        path="/correcoes/estatisticas"
        exact
        component={CorrectionStatistics}
        isPrivate
      />

      <Route path="/eventos" exact component={Appointment} isPrivate />
      <Route
        path="/eventos/cadastrar"
        exact
        component={AppointmentRegistration}
        isPrivate
      />
      <Route
        path="/eventos/visualizar/:id"
        exact
        component={AppointmentPreview}
        isPrivate
      />

      <Route path="/foruns" exact component={Forum} isPrivate />
      <Route
        path="/foruns/visualizar/:id"
        exact
        component={ForumPreview}
        isPrivate
      />

      <Route path="/denuncias" exact component={Report} isPrivate />
      <Route
        path="/denuncias/visualizar/:id"
        exact
        component={ReportPreview}
        isPrivate
      />
      <Route
        path="/denuncias/categorias"
        exact
        component={ReportCategory}
        isPrivate
      />

      <Route path="/secoes" exact component={Section} isPrivate />

      <Route path="/secoes/:section_slug" exact component={Article} isPrivate />
      <Route
        path="/secoes/:section_slug/cadastrar"
        exact
        component={ArticleRegistration}
        isPrivate
      />
      <Route
        path="/secoes/:section_slug/:article_slug"
        exact
        component={ArticlePreview}
        isPrivate
      />

      <Route path="/carreiras" exact component={Career} isPrivate />
      <Route path="/universidades" exact component={University} isPrivate />

      <Route path="/cupons" exact component={Coupon} isPrivate />
      <Route
        path="/cupons/cadastrar"
        exact
        component={CouponRegistration}
        isPrivate
      />

      <Route path="/conquistas" exact component={Achievement} isPrivate />
      <Route
        path="/conquistas/cadastrar"
        exact
        component={AchievementRegistration}
        isPrivate
      />
      <Route
        path="/conquistas/visualizar/:id"
        exact
        component={AchievementPreview}
        isPrivate
      />

      <Route path="/parceiros" exact component={Partner} isPrivate />
      <Route
        path="/parceiros/cadastrar"
        exact
        component={PartnerRegistration}
        isPrivate
      />
      <Route
        path="/parceiros/visualizar/:id"
        exact
        component={PartnerPreview}
        isPrivate
      />

      <Route path="/notificacoes" exact component={Notification} isPrivate />

      <Route path="/perfil" exact component={Profile} isPrivate />

      <Route path="/esqueci-minha-senha" exact component={Forgot} />
      <Route path="/resetar-senha" exact component={Reset} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
