import React from 'react';

// import { Container } from './styles';

interface EmojiProps {
  label?: string;
  symbol: string;
}

const Emoji: React.FC<EmojiProps> = ({ label, symbol }) => {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label || ''}
      aria-hidden={!label}
      style={{ marginLeft: '4px' }}
    >
      {symbol}
    </span>
  );
};

export default Emoji;
