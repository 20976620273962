import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import api from '../../services/api';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import Badge from '../../components/Badge';
import ModalConfirm from '../../components/Modal/Confirm';

interface AffiliateData {
  id: string;
  created_at: string;
  first_name: string;
  last_name: string;
  email: string;
  cpf: string;
  status: string;
}

interface columnData {
  label: string;
  field:
  | 'id'
  | 'created_at'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'cpf'
  | 'status';
  transform?(
    value: string | boolean | number,
    row?: AffiliateData,
  ): string | React.ReactElement;
}

const Affiliate: React.FC = () => {

  const [currentId, setCurrentId] = useState('');

  const [modalLoading, setModalLoading] = useState(false);
  const [reviewWordingModal, setReviewWordingModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<AffiliateData[]>([]);

  type IStatusProps = {
    [key: string]: {
      status: string;
      color:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'info'
      | 'warning'
      | 'success'
      | 'error';
    };
  };

  const statusVariations: IStatusProps = {
    pending: {
      status: 'aguardando validação',
      color: 'warning',
    },
    approved: {
      status: 'aprovado',
      color: 'success',
    },
    disapproved: {
      status: 'reprovado',
      color: 'error',
    },
  };

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = format(
            parseISO(created_at),
            "dd/MM/yyyy 'às' HH:mm",
            { locale: ptBR },
          );

          return formattedDate;
        },
      },
      {
        label: 'Nome',
        field: 'first_name',
        transform: (first_name: string, row) => (
          <span>{`${first_name} ${row?.last_name}`}</span>
        ),
      },
      {
        label: 'Cpf',
        field: 'cpf',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Status',
        field: 'status',
        transform: (status: string) => (

          <>
            <Badge
              title={statusVariations[status].status}
              color={statusVariations[status].color}
            />
          </>
        ),
      },
      {
        label: '',
        field: 'id',
        transform: (id: string) => (
          <DropdownButton>
            <ul>
              {/*   <li>
                <Link to={`/usuarios/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li> */}
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentId(id);
                    setReviewWordingModal(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Ativar
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [statusVariations],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/affiliates/subscriptions', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleChangeUserStatus = useCallback(async () => {
    try {
      const response = await api.post(`/affiliates/${currentId}`);
      // eslint-disable-next-line no-console
      console.log(response.data);

      if (response.status === 200) {
        setReviewWordingModal(false);
        setSearch(' ');
        setData(oldData =>
          oldData.map(user => {
            if (user.id === response.data.id) {
              return response.data;
            }

            return user;
          }),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [currentId]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Lista de interessados (afiliados)"
          items={[{ title: 'Afiliados' }]}
        />
        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum parceiro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        isOpen={reviewWordingModal}
        setIsOpen={() => setReviewWordingModal(!reviewWordingModal)}
        cancelText="Cancelar"
        confirmText="Confirmar"
        isLoading={modalLoading}
        onConfirm={handleChangeUserStatus}
        title="Ativar cadastro"
        text="Ao confirmar esta ação, o usuário passa a ter acesso à Plataforma de afiliados e a Plataforma de aluno, porém, COM ACESSO LIMITADO. Tem certeza de que deseja prosseguir?"
      />
    </>
  );
};

export default Affiliate;
