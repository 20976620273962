import styled from 'styled-components';

interface StatusBadgeProps {
  color: string;
  background: string;
}

export const StatusBadge = styled.span<StatusBadgeProps>`
  cursor: default;
  background: ${props => props.background};
  color: ${props => props.color};
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 4px 8px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: baseline;
  border-radius: 4px;

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
  }
`;
