import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { MdErrorOutline, MdClear } from 'react-icons/md';
import { useField } from '@unform/core';

import { Container, Error, Color } from './styles';

interface InputColorProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const InputColor: React.FC<InputColorProps> = ({
  label,
  name,
  disabled = false,
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [background, setBackground] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        return background;
      },
      setValue: (_, value) => {
        setBackground(value);
      },
    });
  }, [fieldName, registerField, background]);

  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <div>
          <Color background={background}>
            <MdClear size={16} />
          </Color>

          <input
            id={name}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={e => setBackground(e.target.value)}
            {...rest}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default InputColor;
