import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'styled-bootstrap-grid';

import { useAuth } from '../../hooks/auth';

import { Container, WelcomeMessage, BreadcrumbsItems } from './styles';

interface BreadcrumbsProps {
  title: string;
  items?: Array<{
    title: string;
    link?: string;
  }>;
  hasWelcomeMessage?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  title,
  items,
  hasWelcomeMessage = false,
  children,
}) => {
  const { user } = useAuth();
  return (
    <Container>
      <Row>
        <Col lg={6}>
          <h1>{title}</h1>

          {hasWelcomeMessage && (
            <WelcomeMessage>
              {`Seja bem-vindo, ${user.show_name}!`}
              <span
                role="img"
                aria-label="Olhar apaixonado"
                style={{ marginLeft: '4px' }}
              >
                😍
              </span>
            </WelcomeMessage>
          )}

          {!hasWelcomeMessage && items && (
            <BreadcrumbsItems>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              {items.map(item => (
                <li key={item.title}>
                  {item.link ? (
                    <Link to={item.link}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                </li>
              ))}
            </BreadcrumbsItems>
          )}
        </Col>

        <Col lg={6}>
          <div style={{ textAlign: 'right' }}>{children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Breadcrumbs;
