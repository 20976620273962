import React from 'react';

import { Container } from './styles';

interface CardProps {
  style?: React.CSSProperties;
}

const Card: React.FC<CardProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default Card;
