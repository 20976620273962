import React, { useState, useEffect } from 'react';

import { Container, ModalHeader } from './styles';

export { ModalHeader };

interface ModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, setIsOpen }) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <Container
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: '#121214e6',
          zIndex: 9999,
        },
      }}
    >
      {children}
    </Container>
  );
};

export default Modal;
