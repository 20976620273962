import styled from 'styled-components';

export const Profile = styled.div`
  text-align: center;

  img {
    display: block;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 24px;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    padding: 8px;
  }

  h3 {
    margin-bottom: 16px;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }
`;

export const Actions = styled.div`
  margin-top: 24px;
  text-align: right;

  button + button {
    margin-left: 16px;
  }
`;

export const ListItem = styled.div`
  margin-bottom: 16px;

  span,
  label {
    font-weight: bold;
    color: ${({ theme }) => theme.title};
  }

  blockquote {
    font-style: italic;
  }
`;

export const BreakLine = styled.hr`
  height: 1px;
  opacity: 30%;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.text} 33%,
    rgba(0, 0, 0, 0) 0%
  );
  border: none;
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  margin: 40px 0;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;
