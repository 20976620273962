import styled from 'styled-components';
import { shade } from 'polished';

interface NotificationItemProps {
  read: boolean;
}

export const NotificationContainer = styled.div`
  position: relative;

  > p {
    text-align: center;
  }
`;

export const Pagination = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 40px;
`;

export const NotificationItem = styled.div<NotificationItemProps>`
  width: 100%;
  background: ${({ theme }) => theme.backgroundCard};

  &:hover {
    background: ${({ theme }) =>
      shade(theme.hoverPercentage, theme.backgroundCard)};

    > div svg,
    > a svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & + div {
    margin-top: 4px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  > div {
    cursor: default;
  }

  > div,
  > a {
    border: none;
    background: none;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 24px;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    justify-content: space-between;

    svg {
      margin-right: 8px;
      transition: color 0.2s ease;
    }

    div {
      flex: 1;
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 16px;
        margin-bottom: 4px;
      }

      small {
        font-size: 12px;
      }
    }

    > span {
      width: 8px;
      height: 8px;
      background: ${({ theme }) => theme.colors.tertiary};
      border-radius: 50%;
      z-index: 999;
      visibility: ${props => (props.read ? 'hidden' : 'visible')};
    }
  }
`;
