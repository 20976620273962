import React, { useRef, useState, useEffect, useCallback } from 'react';
import htmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

import Emoji from '../../../components/Emoji';

import { Container, Menu, MenuItem } from './styles';
import { useSocket } from '../../../hooks/socket';

interface Notification {
  id: string;
  created_at: string;
  content: string;
  type?: string;
  link?: string;
  viewed_at?: string;
}

const Notifications: React.FC = () => {
  const { user } = useAuth();
  const { register } = useSocket();

  const notificationsRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    register({
      event: 'notification',
      listener: (notification: any) => {
        if (notification) {
          setNotifications(oldNotifications => [
            notification,
            ...oldNotifications,
          ]);
        }
      },
    });
  }, [register]);

  useEffect(() => {
    async function loadData() {
      try {
        if (!user.id) {
          return;
        }

        setLoading(true);

        const response = await api.get('/notifications', {
          params: {
            recipient_id: user.id,
            page: 1,
            perPage: 4,
            type: 'system',
          },
        });

        setNotifications(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!notificationsRef.current || !event.target) {
        return;
      }

      if (!notificationsRef.current.contains(event.target as HTMLDivElement)) {
        setActive(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMarkAsRead = useCallback(async (id: string) => {
    try {
      const response = await api.put(`/notifications/${id}`);

      setNotifications(oldNotifications => {
        const findIndex = oldNotifications.findIndex(
          oldNotification => oldNotification.id === id,
        );

        const newNotifications = oldNotifications;

        newNotifications[findIndex] = response.data;

        return [...newNotifications];
      });
    } catch (error) {
      // console.log(error)
    }
  }, []);

  const handleMarkAllAsRead = useCallback(async () => {
    try {
      await api.delete(`/notifications`);

      setNotifications(oldNotifications => {
        const newNotifications = oldNotifications.map(oldNotification => ({
          ...oldNotification,
          viewed_at: new Date().toDateString(),
        }));

        return [...newNotifications];
      });
    } catch (error) {
      // console.log(error)
    }
  }, []);

  return (
    <Container
      ref={notificationsRef}
      hasNotifications={
        notifications.filter(findNotification => !findNotification.viewed_at)
          .length > 0
      }
    >
      <button type="button" onClick={() => setActive(!active)}>
        <MdNotificationsNone size={20} />
      </button>

      <Menu active={active}>
        <header>
          <span>Notificações</span>

          <button type="button" onClick={handleMarkAllAsRead}>
            Marcar como lidas
          </button>
        </header>

        {notifications.length === 0 && (
          <p>
            Nenhuma notificação
            <Emoji label="Sleepy face" symbol="😪" />
          </p>
        )}
        {notifications.length > 0 && (
          <ul>
            {notifications.map(notification => {
              const viewed = !!notification.viewed_at;

              const notificationContent = (
                <>
                  <MdInfoOutline size={24} />

                  <div>
                    <span>{htmlParser(notification.content)}</span>

                    <small>
                      {formatDistanceToNow(parseISO(notification.created_at), {
                        locale: ptBR,
                        addSuffix: true,
                        includeSeconds: false,
                      })}
                    </small>
                  </div>

                  <span />
                </>
              );

              return (
                <MenuItem read={viewed} key={notification.id}>
                  {notification.link ? (
                    <Link
                      to={notification.link}
                      onClick={() => {
                        return (
                          !notification.viewed_at &&
                          handleMarkAsRead(notification.id)
                        );
                      }}
                    >
                      {notificationContent}
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        return (
                          !notification.viewed_at &&
                          handleMarkAsRead(notification.id)
                        );
                      }}
                    >
                      {notificationContent}
                    </button>
                  )}
                </MenuItem>
              );
            })}
          </ul>
        )}

        <footer>
          <Link to="/notificacoes">Ver todas as notificações</Link>
        </footer>
      </Menu>
    </Container>
  );
};

export default Notifications;
