import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    & {
      margin-bottom: 16px;
    }
  }

  p {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 12px;

    span:first-child {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.title};
    }
  }

  > div {
    background: ${({ theme }) => theme.backgroundCard};
    box-shadow: ${({ theme }) => theme.shadow};
    height: 16px;
    border-radius: 4px;
    width: 100%;
    margin-top: 8px;
    overflow: hidden;
    position: relative;

    > div {
      border-radius: 0 4px 4px 0;
      position: absolute;
      top: 0;
      left: 0;
      background: ${({ theme }) => theme.colors.tertiary};
      width: 0%;
      height: 16px;
      transition: width 1s ease-in-out;
    }
  }
`;
