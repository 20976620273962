import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  MdAccessTime,
  MdContentCopy,
  MdFileDownload,
  MdPersonOutline,
  MdTimer,
} from 'react-icons/md';
import { Col, Row, Container } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import formatDate from '../../../utils/formatDate';
import convertSecondsToTime from '../../../utils/convertSecondsToTime';

import api from '../../../services/api';

import { GradesProvider } from '../../../hooks/grades';
import { MarkPhotoProvider } from '../../../hooks/mark-photo';
import { useToast } from '../../../hooks/toast';
import { MarkProvider } from '../../../hooks/mark';

import Loader from '../../../components/Loader';
import Footer from '../../../containers/Footer';
import Header from '../../../containers/Header';

import Badge from '../../../components/Badge';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import { CorrectionInfo, WordingInfo, WordingTitle } from './styles';
import Card from '../../../components/Card';
import CorrectionGrades from '../../../containers/CorrectionGrades';
import TextArea from '../../../components/TextArea';
import TextContent from './TextContent';
import PhotoContent from './PhotoContent';

interface CorrectionData {
  id: string;
  created_at: string;
  corrected_in: number;
  note: string;
  status: boolean;
  disapprove_reason?: string;
  final_grade?: number;
  rating?: number;
  rating_reason?: string;
  user?: {
    id: string;
    show_name: string;
    avatar_url: string;
  };
  wording: {
    id: string;
    created_at: string;
    end_time_in_seconds?: number;
    text: string;
    is_photo: boolean;
    photo_url: string;
    redemption_date?: string;
    status: string;
    font_size: number;
    status_info: {
      name: string;
      color:
        | 'error'
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'info'
        | 'warning'
        | 'success';
    };
    corrections?: CorrectionData[];
    student?: {
      id: string;
      show_name: string;
      avatar_url: string;
    };
    theme?: {
      id: string;
      name: string;
      thumbnail_url: string;
      material_url: string;
    };
  };
}

interface CorrectionGrade {
  name:
    | 'competence_1'
    | 'competence_2'
    | 'competence_3'
    | 'competence_4'
    | 'competence_5';
  value: number;
}

interface Grades {
  competence_1: number;
  competence_2: number;
  competence_3: number;
  competence_4: number;
  competence_5: number;
}

const CorrectionPreview: React.FC = () => {
  const { correction_id } = useParams<{ correction_id: string }>();
  const history = useHistory();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [correction, setCorrection] = useState<CorrectionData>(
    {} as CorrectionData,
  );

  const [grades, setGrades] = useState<Grades>({
    competence_1: 0,
    competence_2: 0,
    competence_3: 0,
    competence_4: 0,
    competence_5: 0,
  });

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const [correctionResponse, gradesResponse] = await Promise.all([
          api.get(`/corrections/${correction_id}`),
          api.get<CorrectionGrade[]>(`/corrections/${correction_id}/grades`),
        ]);

        const newGrades: Grades = {} as Grades;

        gradesResponse.data.forEach((data: CorrectionGrade) => {
          newGrades[data.name] = data.value;
        });

        formRef.current?.setData({
          ...newGrades,
        });

        setGrades(newGrades);

        setCorrection(correctionResponse.data);

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'A correção não foi encontrada!',
        });

        history.push('/dashboard');
      }
    }

    loadData();
  }, [addToast, history, correction_id]);

  const handleCopyWording = useCallback(() => {
    setLoading(true);

    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        navigator.clipboard.writeText(correction.wording?.text).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'A redação foi copiada com sucesso.',
              type: 'success',
            });

            setLoading(false);
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar a redação.',
              type: 'error',
            });

            setLoading(false);
          },
        );
      }
    });
  }, [correction.wording, addToast]);

  return (
    <>
      <Header />
      {loading && <Loader isFixed />}

      <Container>
        <Breadcrumbs
          title="Visualizar correção"
          items={[{ title: 'Visualizar correção' }]}
        />

        <CorrectionInfo>
          <div>
            <span>Modelo ENEM</span>
          </div>
          <Badge
            title={correction.status ? 'Ativa' : 'Inativa'}
            color={correction.status ? 'success' : 'error'}
            tooltip={correction.disapprove_reason}
          />
        </CorrectionInfo>

        <Card>
          <WordingInfo>
            <section>
              <h2>Informações do aluno</h2>

              <div>
                <img
                  src={correction.wording?.student?.avatar_url}
                  alt={correction.wording?.student?.show_name}
                />

                <ul>
                  <li>
                    <MdPersonOutline />
                    {correction.wording?.student?.show_name}
                  </li>
                  <li>
                    <MdAccessTime />
                    {correction.wording &&
                      formatDate(
                        correction.wording?.created_at,
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                  </li>
                  <li>
                    <MdTimer />
                    {correction.wording?.end_time_in_seconds
                      ? convertSecondsToTime(
                          correction.wording.end_time_in_seconds,
                        )
                      : 'indefinido'}
                  </li>
                </ul>
              </div>
            </section>

            <section>
              <h2>Informações da correção</h2>

              <div>
                <img
                  src={correction.user?.avatar_url}
                  alt={correction.user?.show_name}
                />

                <ul>
                  <li>
                    <MdPersonOutline />
                    {correction.user?.show_name}
                  </li>
                  <li>
                    <MdAccessTime />
                    {correction.created_at &&
                      formatDate(
                        correction.created_at,
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                  </li>
                  <li>
                    <MdTimer />
                    {correction.corrected_in
                      ? convertSecondsToTime(correction.corrected_in)
                      : 'indefinido'}
                  </li>
                </ul>
              </div>
            </section>
          </WordingInfo>
        </Card>

        <Row>
          <Col md={12} style={{ position: 'static' }}>
            <Card style={{ position: 'static' }}>
              <WordingTitle>
                <h2>{correction.wording?.theme?.name}</h2>

                <ul>
                  <li>
                    <a
                      href={correction.wording?.theme?.material_url}
                      target="blank"
                    >
                      <MdFileDownload size={16} />
                      Material de apoio
                    </a>
                  </li>

                  {!correction.wording?.is_photo && (
                    <li>
                      <button type="button" onClick={handleCopyWording}>
                        <MdContentCopy size={16} />
                        Copiar redação
                      </button>
                    </li>
                  )}
                </ul>
              </WordingTitle>

              {correction.wording &&
                (correction.wording.is_photo ? (
                  <MarkPhotoProvider>
                    <PhotoContent
                      correction_id={correction.id}
                      photo_url={correction.wording?.photo_url}
                    />
                  </MarkPhotoProvider>
                ) : (
                  <MarkProvider>
                    <TextContent
                      correction_id={correction.id}
                      text={correction.wording?.text}
                      font_size={correction.wording?.font_size}
                    />
                  </MarkProvider>
                ))}
            </Card>
          </Col>

          <Col md={12}>
            <Form
              ref={formRef}
              id="correctionForm"
              initialData={correction}
              onSubmit={() => false}
              noValidate
            >
              <GradesProvider
                initialFinalGrade={correction.final_grade}
                initialGrades={grades}
              >
                <CorrectionGrades disabled />
              </GradesProvider>

              <TextArea
                name="note"
                label="Comentários"
                required
                placeholder="Insira um breve comentário final sobre a redação e o que pode ser melhorado."
                rows={8}
                disabled
              />
            </Form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default CorrectionPreview;
