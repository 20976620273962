import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface CorrectionPaginationItemProps {
  isActive: boolean;
}

interface RatingReasonProps {
  content: string;
  isActive: boolean;
}

export const CorrectionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    display: flex;
    align-items: center;

    span:first-child {
      font-size: 18px;
      color: ${({ theme }) => theme.title};
      font-weight: bold;
    }

    span + span {
      margin-left: 16px;
    }
  }
`;

export const CorrectionPagination = styled.ul`
  display: flex;
  list-style: none;
`;

export const CorrectionPaginationItem = styled.li<
  CorrectionPaginationItemProps
>`
  & + li {
    margin-left: 4px;
  }

  button {
    border: none;
    background: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 14px;
    color: ${({ theme }) => theme.text};

    &:hover {
      color: ${({ theme }) => theme.title};
      background: ${({ theme }) => theme.backgroundCard};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        background: ${({ theme }) => theme.colors.primary};
        color: #ffffff;

        &:hover {
          background: ${({ theme }) => theme.colors.primary};
          color: #ffffff;
        }
      `}
  }
`;

export const RatingReason = styled.button<RatingReasonProps>`
  position: relative;

  &::before {
    content: '${({ content }) => content}';
  color: white;
    width: 240px;
    position: absolute;
    border-radius: 4px;
    line-height: 1.4;
    padding: 16px;
    width: 240px;
    font-size: 14px;
    font-weight: normal;
    transform: translate(-50%, -100%);
    top: -12px;
    left: 50%;
    background: black;
    transition: all 0.2s;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
    transform: translate(-50%, -100%);
    transition: all 0.2s;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }
`;

export const WordingTitle = styled.div`
  margin-bottom: 40px;

  h2 {
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      & + li {
        margin-left: 24px;
      }

      a,
      button {
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        }

        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }
`;

export const WordingInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  > section {
    > div {
      display: flex;
      align-items: center;

      > img {
        width: 112px;
        height: 112px;
        border-radius: 50%;
        border: 4px solid ${({ theme }) => theme.colors.primary};
        padding: 8px;
        margin-right: 16px;
      }

      > ul {
        list-style: none;

        li {
          line-height: 1.8;
          display: flex;
          align-items: center;

          svg {
            color: ${({ theme }) => theme.title};
            margin-right: 4px;
          }

          > a {
            color: ${({ theme }) => theme.colors.primary};
            text-decoration: none;
          }

          > button {
            svg {
              color: ${({ theme }) => theme.colors.primary};
            }

            background: none;
            border: none;
            line-height: 1;
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme.colors.primary};
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
`;

export const ReportButton = styled.button`
  color: ${({ theme }) => theme.colors.danger};
  background: none;
  border: none;
  font-weight: bold;
  transition: color 0.2s;
  padding: 0 18px;
  height: 44px;
  margin-right: 16px;

  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: ${({ theme }) => shade(0.1, theme.colors.danger)};
  }
`;
