import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block !important;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  > div {
    color: white;
    position: absolute;
    border-radius: 4px;
    line-height: 1;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transform: translateX(-50%);
    top: -30px;
    left: 50%;
    background: black;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      transform: translateX(-50%);
    }
  }
`;
