import styled from 'styled-components';

export const Profile = styled.div`
  text-align: center;

  img {
    display: block;
    width: 152px;
    height: 152px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 24px;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    padding: 8px;
  }

  span {
    font-size: 12px;
  }
`;

export const DataBox = styled.div`
  position: relative;
  background: ${({ theme }) => theme.backgroundCard};
  border-radius: 8px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadow};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;

  span {
    color: ${({ theme }) => theme.title};
    font-size: 32px;
    font-weight: bold;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.text};

  > div {
    max-width: 111px;
    border-radius: 4px;
    border: ${({ theme }) => theme.input.border};
    background: ${({ theme }) => theme.input.background};
    width: 100%;
    margin-left: 16px;

    &:hover,
    &:focus-within {
      border-color: ${({ theme }) => theme.input.border};
    }

    input {
      padding: 8px 12px;
      text-align: left;
      flex: 1;
      width: 100%;
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.input.text};

      &::placeholder {
        color: ${({ theme }) => theme.input.placeholder};
      }

      &:disabled {
        cursor: not-allowed;
        color: ${({ theme }) => `${theme.input.text}60`};
      }
    }
  }

  .react-datepicker-popper {
    top: 21px !important;
    left: -70px !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;
