import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import htmlParser from 'react-html-parser';
import { MdInfoOutline } from 'react-icons/md';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import Button from '../../components/Button';
import Loader from '../../components/Loader';

import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';
import Header from '../../containers/Header';

import { NotificationContainer, NotificationItem, Pagination } from './styles';

interface Notification {
  id: string;
  created_at: string;
  content: string;
  type?: string;
  link?: string;
  viewed_at?: string;
}

const Notification: React.FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/notifications', {
          params: { recipient_id: user.id, page, perPage: 8 },
        });

        setLastPage(response.data.lastPage);
        setNotifications(oldNotifications => [
          ...oldNotifications,
          ...response.data.records,
        ]);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.id, page]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Notificações" items={[{ title: 'Notificações' }]} />

        <Row>
          <Col>
            <NotificationContainer>
              {loading && <Loader />}

              {notifications.length === 0 && (
                <p>Parece que você ainda não possui nenhuma notificação.</p>
              )}
              {notifications.map(notification => {
                const notificationContent = (
                  <>
                    <MdInfoOutline size={32} />

                    <div>
                      <span>{htmlParser(notification.content)}</span>

                      <small>
                        {formatDistanceToNow(
                          parseISO(notification.created_at),
                          {
                            locale: ptBR,
                            addSuffix: true,
                            includeSeconds: false,
                          },
                        )}
                      </small>
                    </div>

                    <span />
                  </>
                );

                return (
                  <NotificationItem
                    key={notification.id}
                    read={!!notification.viewed_at}
                  >
                    {notification.link ? (
                      <Link to={notification.link}>{notificationContent}</Link>
                    ) : (
                      <div>{notificationContent}</div>
                    )}
                  </NotificationItem>
                );
              })}
            </NotificationContainer>

            <Pagination>
              <Button
                color="primary"
                onClick={() => setPage(oldPage => oldPage + 1)}
                disabled={lastPage <= page}
              >
                Carregar mais
              </Button>
            </Pagination>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Notification;
