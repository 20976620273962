import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  parseISO,
  format,
  subYears,
  subMonths,
  setDate,
  addMonths,
} from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { MdAttachMoney, MdEvent } from 'react-icons/md';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';
import formatDate from '../../../utils/formatDate';

import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import Breadcrumbs from '../../../containers/Breadcrumbs';

import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';

import { Profile, DataBox, Filter } from './styles';
import DatePicker from '../../../components/Datepicker';
import Datatable from '../../../components/Datatable';
import Badge from '../../../components/Badge';
import UserLogs from './UserLogs';
import Rating from '../../../components/Rating';

interface UserData {
  created_at: string;
  show_name: string;
  avatar_url: string;
}

interface UserInfo {
  countCorrections: number;
  averageRating: number;
  averageGrade: number;
  correctionsPerDay: number;
}

interface UserPreviewFormData {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  cpf: string;
  birthday: Date;
  role: string;
  price_per_wording: number;
  affiliate_coupon: string;
  bank: string;
  agency: string;
  account: string;
  account_type: string;
}

interface WordingData {
  theme: {
    id: string;
    name: string;
    thumbnail_url: string;
  };
}

interface CorrectionData {
  id: string;
  wording: WordingData;
  status: boolean;
  rating: number;
  disapprove_reason: string;
  created_at: string;
}

interface columnData {
  label: string;
  field:
    | 'id'
    | 'wording'
    | 'rating'
    | 'status'
    | 'created_at'
    | 'disapprove_reason';
  transform?(
    value: string | WordingData | boolean | number,
    row?: CorrectionData,
  ): string | React.ReactElement;
}

const StudentPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [userLoading, setStudentLoading] = useState(false);
  const [correctionsLoading, setCorrectionsLoading] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [user, setStudent] = useState<UserData>({} as UserData);
  const [corrections, setCorrections] = useState<CorrectionData[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);

  const [startDate, setStartDate] = useState<Date>(
    subMonths(setDate(new Date(), 27), 1),
  );
  const [endDate, setEndDate] = useState<Date>(setDate(new Date(), 26));
  const [filter, setFilter] = useState('');

  const { addToast } = useToast();

  useEffect(() => {
    const formattedDate = formatDate(endDate.toISOString(), "LLLL 'de' yyyy");

    setFilter(formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1));
  }, [endDate]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setStudentLoading(true);

      try {
        const response = await api.get(`/users/${id}`);

        const created_at = format(
          parseISO(response.data.created_at),
          " MMMM 'de' yyyy",
          { locale: ptBR },
        );

        const price_per_wording = response.data.price_per_wording
          ? response.data.price_per_wording / 100
          : null;

        setStudent({ ...response.data, created_at, price_per_wording });

        formRef.current?.setData({
          birthday: response.data.birthday,
          cpf: response.data.cpf,
          role: response.data.role,
          account_type: response.data.account_type,
        });

        setStudentLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'O usuário não foi encontrado!',
        });

        history.push('/usuarios');
      }
    }

    loadData();
  }, [id, addToast, history]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setStatisticsLoading(true);

      try {
        const response = await api.get(`/users/${id}/info`);

        setUserInfo(response.data);

        setStatisticsLoading(false);
      } catch (error) {
        // console.log(error);
      }
    }

    loadData();
  }, [id, addToast, history]);

  useEffect(() => {
    async function loadData() {
      try {
        setCorrectionsLoading(true);

        const response = await api.get(`users/${id}/corrections`, {
          params: { pageIndex, search, from: startDate, to: endDate },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setCorrections(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setCorrectionsLoading(false);
      }
    }

    loadData();
  }, [id, pageIndex, search, startDate, endDate]);

  const handleSubmit = useCallback(
    async (data: UserPreviewFormData) => {
      setStudentLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          phone_number: Yup.string()
            .min(14, 'Telefone inválido')
            .max(15, 'Telefone inválido')
            .required('Telefone obrigatório'),
          gender: Yup.string().nullable(),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('CPF obrigatório'),
          birthday: Yup.date()
            .max(subYears(new Date(), 18), 'Mínimo de 18 anos')
            .nullable(),
          role: Yup.string().required('Função obrigatória'),
          price_per_wording: Yup.number()
            .nullable()
            .when('role', {
              is: 'corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            })
            .when('role', {
              is: 'super-corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            })
            .when('role', {
              is: 'hyper-corrector',
              then: Yup.number()
                .required('Valor por Correção obrigatório')
                .positive('Valor mínimo de R$0,50'),
            }),
          bank: Yup.string(),
          agency: Yup.string(),
          account: Yup.string(),
          account_type: Yup.string().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        const price_per_wording = data.price_per_wording
          ? data.price_per_wording * 100
          : null;

        const affiliate_coupon = data.affiliate_coupon || null;

        await api.put(`users/${id}`, {
          price_per_wording,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          affiliate_coupon,
          cpf: data.cpf,
          birthday: data.birthday,
          role: data.role,
          bank: data.bank,
          agency: data.agency,
          account: data.account,
          account_type: data.account_type,
        });

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'O usuário foi atualizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      } finally {
        setStudentLoading(false);
      }
    },
    [addToast, id],
  );

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          return formatDate(created_at, 'dd/MM/yyyy');
        },
      },
      {
        label: 'Tema',
        field: 'wording',
        transform: (wording: WordingData, row: CorrectionData) => {
          return (
            <Link to={`/correcoes/visualizar/${row.id}`}>
              {wording.theme.name}
            </Link>
          );
        },
      },
      {
        label: 'Avaliação',
        field: 'rating',
        transform: (rating: number) => {
          return rating ? (
            <Rating
              min={5}
              max={50}
              value={rating}
              readOnly
              numberOfStars={5}
            />
          ) : (
            'Não avaliado'
          );
        },
      },
      {
        label: '',
        field: 'status',
        transform: (status: boolean, row: CorrectionData) => {
          return (
            <Badge
              title={status ? 'Ativo' : 'Inativo'}
              color={status ? 'success' : 'error'}
              tooltip={row.disapprove_reason}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Visualizar usuário"
          items={[
            { title: 'Usuário', link: '/usuarios' },
            { title: 'Visualizar usuário' },
          ]}
        />

        <Row>
          <Col md={4}>
            <Card>
              {userLoading && <Loader />}

              <Profile>
                <img src={user.avatar_url} alt={user.show_name} />

                <h2>{user.show_name}</h2>
                <span>
                  Membro desde
                  {user.created_at}
                </span>
              </Profile>
            </Card>

            <Row>
              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Redações corrigidas
                  <span>{userInfo.countCorrections}</span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Avaliação dos alunos
                  <span>
                    {new Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 1,
                    }).format((userInfo.averageRating / 10) * 2 || 0)}
                  </span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Nota média
                  <span>
                    {new Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 1,
                    }).format(userInfo.averageGrade || 0)}
                  </span>
                </DataBox>
              </Col>

              <Col md={12}>
                <DataBox>
                  {statisticsLoading && <Loader />}
                  Correções por dia
                  <span>
                    {new Intl.NumberFormat('pt-BR', {
                      maximumFractionDigits: 1,
                    }).format(userInfo.correctionsPerDay || 0)}
                  </span>
                </DataBox>
              </Col>
            </Row>
          </Col>

          <Col md={8}>
            <Card>
              {userLoading && <Loader />}
              <h2>Dados pessoais</h2>

              <Form
                ref={formRef}
                initialData={user}
                onSubmit={handleSubmit}
                noValidate
              >
                <Row>
                  <Col md={6}>
                    <Input type="text" name="first_name" label="Nome" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="last_name" label="Sobrenome" />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Input type="email" name="email" label="E-mail" />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      mask=""
                      maskChar={null}
                      type="text"
                      name="phone_number"
                      label="Telefone"
                      isPhone
                    />
                  </Col>

                  <Col md={6}>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      maskChar={null}
                      name="cpf"
                      label="CPF"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <DatePicker
                      icon={MdEvent}
                      name="birthday"
                      label="Data de Nascimento"
                      maxDate={subYears(new Date(), 18)}
                    />
                  </Col>

                  <Col md={4}>
                    <Select
                      name="role"
                      label="Função"
                      options={[
                        { value: 'administrator', label: 'Administrador' },
                        { value: 'coordinator', label: 'Coordenador' },
                        { value: 'affiliate', label: 'Afiliado' },
                        { value: 'corrector', label: 'Corretor N1' },
                        { value: 'super-corrector', label: 'Corretor N2' },
                        { value: 'hyper-corrector', label: 'Corretor N3' },
                      ]}
                    />
                  </Col>

                  <Col md={4}>
                    <Input
                      icon={MdAttachMoney}
                      type="number"
                      name="price_per_wording"
                      label="Valor por Correção"
                      step="0.5"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      name="affiliate_coupon"
                      label="Cupom de Afiliado"
                    />
                  </Col>
                </Row>

                <h2>Dados bancários</h2>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="bank" label="Banco" />
                  </Col>

                  <Col md={6}>
                    <Select
                      name="account_type"
                      label="Tipo da Conta"
                      options={[
                        { value: 'Conta Corrente', label: 'Conta Corrente' },
                        { value: 'Conta Poupança', label: 'Conta Poupança' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input type="text" name="agency" label="Agência" />
                  </Col>

                  <Col md={6}>
                    <Input type="text" name="account" label="Conta" />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Atualizar usuário
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              {correctionsLoading && <Loader />}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '28px',
                }}
              >
                <h2 style={{ flex: 1, marginBottom: 0 }}>Correções</h2>

                <Filter>
                  {filter}

                  <div>
                    <ReactDatePicker
                      name="filterDate"
                      selected={startDate}
                      onChange={(date: Date) => {
                        setStartDate(subMonths(setDate(date, 27), 1));
                        setEndDate(setDate(date, 26));
                      }}
                      dateFormat="dd/MM/yyyy"
                      maxDate={addMonths(new Date(), 1)}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      locale={ptBR}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={formatDate(endDate.toISOString(), 'dd/MM/yyyy')}
                      disabled
                    />
                  </div>
                </Filter>
              </div>

              <Datatable
                loading={correctionsLoading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {corrections.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!corrections.length && (
                  <tr>
                    <td colSpan={columns.length}>
                      Nenhuma correção encontrada
                    </td>
                  </tr>
                )}
              </Datatable>
            </Card>

            <Card>
              <UserLogs user_id={id} />
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default StudentPreview;
