import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import Breadcrumbs from '../../containers/Breadcrumbs';

import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import Badge from '../../components/Badge';
import ModalConfirm from '../../components/Modal/Confirm';

interface UserData {
  id: string;
  created_at: string;
  full_name: string;
  email: string;
  role_name: string;
  avatar_url: string;
  active: boolean;
}

interface columnData {
  label: string;
  field: 'id' | 'created_at' | 'full_name' | 'role_name' | 'email' | 'active';
  transform?(
    value: string | boolean,
    row?: UserData,
  ): string | React.ReactElement;
}

const User: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [toggleActiveConfirm, setToggleActiveConfirm] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [users, setUsers] = useState<UserData[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserData>({} as UserData);

  const columns = useMemo<columnData[]>(
    () => [
      {
        label: 'Cadastro',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = format(
            parseISO(created_at),
            "dd/MM/yyyy 'às' HH:mm",
            { locale: ptBR },
          );

          return formattedDate;
        },
      },
      {
        label: 'Nome',
        field: 'full_name',
        transform: (full_name: string, row) => {
          return (
            <div>
              <img src={row?.avatar_url} alt={full_name} />
              <span>{full_name}</span>
            </div>
          );
        },
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Função',
        field: 'role_name',
      },
      {
        label: 'Status',
        field: 'active',
        transform: (active: boolean) =>
          active ? (
            <Badge title="Ativo" color="success" />
          ) : (
            <Badge title="Inativo" color="error" />
          ),
      },
      {
        label: '',
        field: 'id',
        transform: (id, row: UserData) => (
          <DropdownButton>
            <ul>
              <li>
                <Link to={`/usuarios/visualizar/${id}`}>
                  <MdKeyboardArrowRight size={18} />
                  Visualizar
                </Link>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedUser(row);
                    setToggleActiveConfirm(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  {row?.active ? 'Inativar' : 'Reativar'}
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/users', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setUsers(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleToggleActive = useCallback(async () => {
    setModalLoading(true);

    try {
      const response = await api.put<UserData>(
        `users/${selectedUser.id}/active`,
      );

      setUsers(oldUsers =>
        oldUsers.map(oldUser =>
          oldUser.id === selectedUser.id ? response.data : oldUser,
        ),
      );

      addToast({
        type: 'success',
        title: 'Sucesso na atualização',
        description: 'O usuário foi atualizado com sucesso!',
      });

      setToggleActiveConfirm(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description: 'Ocorreu um erro na atualização, verifique os campos.',
      });
    } finally {
      setModalLoading(false);
    }
  }, [addToast, selectedUser.id]);

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Usuários" items={[{ title: 'Usuários' }]}>
          <Button
            color="secondary"
            onClick={() => history.push('/usuarios/cadastrar')}
          >
            Cadastrar usuário
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {users.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!users.length && (
                  <tr>
                    <td colSpan={columns.length}>Nenhum registro encontrado</td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <ModalConfirm
        title={selectedUser.active ? 'Inativar usuário' : 'Reativar usuário'}
        confirmText={selectedUser.active ? 'Inativar' : 'Reativar'}
        cancelText="Cancelar"
        text={`Tem certeza que deseja ${
          selectedUser.active ? 'inativar' : 'reativar'
        } o usuário?`}
        onConfirm={handleToggleActive}
        isLoading={modalLoading}
        isOpen={toggleActiveConfirm}
        setIsOpen={() => setToggleActiveConfirm(!toggleActiveConfirm)}
      />
    </>
  );
};

export default User;
