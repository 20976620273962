import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import formatDate from '../../utils/formatDate';
import getValidationErrors from '../../utils/getValidationErrors';

import Breadcrumbs from '../../containers/Breadcrumbs';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

import Button from '../../components/Button';
import DropdownButton from '../../components/DropdownButton';
import Card from '../../components/Card';
import Datatable from '../../components/Datatable';
import Modal, { ModalHeader } from '../../components/Modal';
import ModalConfirm from '../../components/Modal/Confirm';
import Loader from '../../components/Loader';
import Input from '../../components/Input';

interface UniversityData {
  id: string;
  name: string;
  acronym: string;
  created_at: string;
  updated_at: string;
}

interface ColumnData {
  label: string;
  field: 'id' | 'acronym' | 'name' | 'created_at' | 'updated_at';
  transform?(value: string, row?: UniversityData): string | React.ReactElement;
}

interface UniversityFormData {
  name: string;
  acronym: string;
}

const University: React.FC = () => {
  const { addToast } = useToast();

  const formAddUniversityRef = useRef<FormHandles>(null);
  const formEditUniversityRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [modalAddUniversity, setModalAddUniversity] = useState(false);
  const [modalEditUniversity, setModalEditUniversity] = useState(false);
  const [modalDelUniversity, setModalDelUniversity] = useState(false);

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState<UniversityData[]>([]);
  const [currentUniversity, setCurrentUniversity] = useState<UniversityData>(
    {} as UniversityData,
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/universities', {
          params: { pageIndex, search },
        });

        setMaxPages(response.data.maxPages);
        setShowingFrom(response.data.showingFrom);
        setShowingTo(response.data.showingTo);
        setTotalRecords(response.data.totalRecords);
        setData(response.data.records);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search]);

  const handleAddUniversity = useCallback(
    async (formData: UniversityFormData) => {
      setLoadingModal(true);

      formAddUniversityRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          acronym: Yup.string().required('Sigla obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.post('/universities', {
          name: formData.name,
          acronym: formData.acronym,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'A universidade foi cadastrada com sucesso!',
        });

        setData(oldData => [response.data, ...oldData]);

        setModalAddUniversity(false);
        setLoadingModal(false);
      } catch (err) {
        setLoadingModal(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddUniversityRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast],
  );

  const handleDeleteUniversity = useCallback(async () => {
    try {
      setLoadingModal(true);

      await api.delete(`universities/${currentUniversity.id}`);

      addToast({
        type: 'success',
        title: 'Sucesso na exclusão',
        description: 'A universidade foi excluída com sucesso!',
      });

      setModalDelUniversity(false);
      setData(oldUniversity =>
        oldUniversity.filter(
          university => university.id !== currentUniversity.id,
        ),
      );
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na exclusão',
        description: 'Ocorreu um erro na exclusão.',
      });
    } finally {
      setLoadingModal(false);
    }
  }, [addToast, currentUniversity.id]);

  const handleEditUniversity = useCallback(
    async (formData: UniversityFormData) => {
      setLoadingModal(true);

      formEditUniversityRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          acronym: Yup.string().required('Sigla obrigatória'),
        });

        await schema.validate(formData, { abortEarly: false });

        const response = await api.put(
          `/universities/${currentUniversity.id}`,
          {
            name: formData.name,
            acronym: formData.acronym,
          },
        );

        addToast({
          type: 'success',
          title: 'Sucesso na atualização',
          description: 'A universidade foi atualizada com sucesso!',
        });

        setData(oldData =>
          oldData.map(university =>
            university.id === currentUniversity.id ? response.data : university,
          ),
        );

        setModalEditUniversity(false);
        setLoadingModal(false);
      } catch (err) {
        setLoadingModal(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formAddUniversityRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro na atualização, verifique os campos.',
        });
      }
    },
    [addToast, currentUniversity.id],
  );

  const columns = useMemo<ColumnData[]>(
    () => [
      {
        label: 'Sigla',
        field: 'acronym',
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Criado em',
        field: 'created_at',
        transform: (created_at: string) => {
          const formattedDate = formatDate(created_at, "dd/MM/yyyy 'às' HH:mm");

          return formattedDate;
        },
      },
      {
        label: 'Atualizado em',
        field: 'updated_at',
        transform: (updated_at: string) => {
          const formattedDate = formatDate(updated_at, "dd/MM/yyyy 'às' HH:mm");

          return formattedDate;
        },
      },
      {
        label: '',
        field: 'id',
        transform: (id: string, row: UniversityData) => (
          <DropdownButton>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentUniversity(row);
                    setModalEditUniversity(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Alterar
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentUniversity(row);
                    setModalDelUniversity(true);
                  }}
                >
                  <MdKeyboardArrowRight size={18} />
                  Excluir
                </button>
              </li>
            </ul>
          </DropdownButton>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs title="Universidades" items={[{ title: 'Universidades' }]}>
          <Button color="secondary" onClick={() => setModalAddUniversity(true)}>
            Cadastrar universidade
          </Button>
        </Breadcrumbs>

        <Row>
          <Col md={12}>
            <Card>
              <Datatable
                loading={loading}
                columns={columns}
                showingTo={showingTo}
                showingFrom={showingFrom}
                totalRecords={totalRecords}
                pageIndex={pageIndex}
                maxPages={maxPages}
                setPageIndex={(value: number) => setPageIndex(value)}
                setSearch={(value: string) => setSearch(value)}
              >
                {data.map(row => (
                  <tr key={row.id}>
                    {columns.map(column => (
                      <td key={column.field}>
                        {column.transform
                          ? column.transform(row[column.field], row)
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
                {!data.length && (
                  <tr>
                    <td colSpan={columns.length}>
                      Nenhuma universidade encontrada
                    </td>
                  </tr>
                )}
              </Datatable>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal
        isOpen={modalAddUniversity}
        setIsOpen={() => setModalAddUniversity(!modalAddUniversity)}
      >
        {loadingModal && <Loader />}

        <ModalHeader>
          <div>Adicionar Universidade</div>

          <button type="button" onClick={() => setModalAddUniversity(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formAddUniversityRef}
          onSubmit={handleAddUniversity}
          noValidate
        >
          <Input name="acronym" label="Sigla" />

          <Input name="name" label="Nome" />

          <footer>
            <Button type="submit" color="primary">
              Adicionar universidade
            </Button>
          </footer>
        </Form>
      </Modal>

      <Modal
        isOpen={modalEditUniversity}
        setIsOpen={() => setModalEditUniversity(!modalEditUniversity)}
      >
        {loadingModal && <Loader />}

        <ModalHeader>
          <div>Alterar Universidade</div>

          <button type="button" onClick={() => setModalEditUniversity(false)}>
            <MdClose size={20} />
          </button>
        </ModalHeader>

        <Form
          ref={formEditUniversityRef}
          initialData={currentUniversity}
          onSubmit={handleEditUniversity}
          noValidate
        >
          <Input name="acronym" label="Sigla" />

          <Input name="name" label="Nome" />

          <footer>
            <Button type="submit" color="primary">
              Alterar universidade
            </Button>
          </footer>
        </Form>
      </Modal>

      <ModalConfirm
        title="Excluir universidade"
        confirmText="Excluir"
        cancelText="Cancelar"
        text={`Tem certeza que deseja excluir a universidade "${currentUniversity.name}"? Essa ação não poderá ser
        desfeita.`}
        onConfirm={handleDeleteUniversity}
        isOpen={modalDelUniversity}
        isLoading={loadingModal}
        setIsOpen={() => setModalDelUniversity(!modalDelUniversity)}
      />
    </>
  );
};

export default University;
