import React, { useCallback, useRef, useState, useEffect } from 'react';

import { useMark } from '../../../hooks/mark-photo';

import { Container } from './styles';

interface MarkProps {
  id: string;
  background: string;
  color: string;
  readOnly?: boolean;
}

const Mark: React.FC<MarkProps> = ({
  id,
  background,
  color,
  children,
  readOnly = false,
}) => {
  const markRef = useRef<HTMLDivElement>(null);

  const [mouseIsPressed, setMouseIsPressed] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const { showMark, hideMark, moveMark, isActive, getCoordinates } = useMark();

  const { height, left, width, top } = getCoordinates(id);

  const getMousePosition = (e: MouseEvent) => {
    const eventTarget = e.currentTarget as Element;

    const parentOffset = eventTarget.getBoundingClientRect();

    const x = e.clientX - parentOffset.left;
    const y = e.clientY - parentOffset.top;

    return { x, y };
  };

  const handleClick = useCallback(() => {
    const currentMark = markRef.current;

    if (!currentMark || isMoving) {
      return;
    }

    if (!isActive(id)) {
      const {
        left: cLeft,
        top: cTop,
        width: cWidth,
      } = currentMark.getBoundingClientRect();

      const offsetX = cLeft + cWidth / 2;
      const offsetY = cTop + window.scrollY - 14;

      showMark({ id, offsetX, offsetY });
    } else {
      hideMark(id);
    }
  }, [id, isMoving, isActive, showMark, hideMark]);

  const handleMouseDown = useCallback(e => {
    const { x, y } = getMousePosition(e);
    setOffset({ x, y });
    setMouseIsPressed(true);
  }, []);

  const handleMouseUp = useCallback(e => {
    setMouseIsPressed(false);
  }, []);

  const handleMouseMove = useCallback(
    e => {
      setIsMoving(mouseIsPressed);

      if (mouseIsPressed) {
        const { x, y } = getMousePosition(e);

        moveMark({ id, x: x - offset.x, y: y - offset.y });
      }
    },
    [mouseIsPressed, offset, moveMark, id],
  );

  return (
    <Container
      ref={markRef}
      background={background}
      color={color}
      onClick={handleClick}
      onMouseDown={e => !readOnly && handleMouseDown(e)}
      onMouseUp={e => !readOnly && handleMouseUp(e)}
      onMouseMove={e => !readOnly && handleMouseMove(e)}
      isMoving={isMoving}
      readOnly={readOnly}
      style={{
        width,
        height,
        top,
        left,
      }}
    >
      {children}
    </Container>
  );
};

export default Mark;
