import React from 'react';

import { Container } from './styles';

interface CheckboxProps {
  name: string;
  label: string;
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'success'
    | 'danger';
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  color,
  checked,
  onChange,
}) => {
  return (
    <Container color={color}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div>{label}</div>
    </Container>
  );
};

export default Checkbox;
