import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { MdEvent } from 'react-icons/md';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import AsyncSelect from '../../../components/Select/Async';

import Breadcrumbs from '../../../containers/Breadcrumbs';
import Header from '../../../containers/Header';
import Footer from '../../../containers/Footer';
import DatePicker from '../../../components/Datepicker';

// import { Container } from './styles';

interface CouponRegistrationFormData {
  code: string;
  discount: number;
  expires_at: Date | null;
  product_ids: string[];
}

const CouponRegistration: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const productsOptions = (
    inputValue: string,
    callback: (options: any) => void,
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    api
      .get('/products/list', {
        params: { search: inputValue },
      })
      .then(response => callback(response.data));
  };

  const handleSubmit = useCallback(
    async (data: CouponRegistrationFormData) => {
      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          code: Yup.string().required('Código obrigatório'),
          discount: Yup.number().required('Desconto obrigatório').nullable(),
          product_ids: Yup.array()
            .of(Yup.string())
            .min(1, 'Selecione pelo menos um plano'),
          expires_at: Yup.date().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('coupons', {
          code: data.code,
          discount: data.discount,
          expires_at: data.expires_at,
          product_ids: data.product_ids,
        });

        addToast({
          type: 'success',
          title: 'Sucesso no cadastro',
          description: 'O cupom foi cadastrado com sucesso!',
        });

        history.push('/cupons');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro no cadastro, verifique os campos.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Header />

      <Container style={{ flex: 1 }}>
        <Breadcrumbs
          title="Cadastrar cupom"
          items={[
            { title: 'Cupons', link: '/cupons' },
            { title: 'Cadastrar cupom' },
          ]}
        />

        <Row>
          <Col md={12}>
            <Card>
              {loading && <Loader />}

              <Form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={4}>
                    <Input type="text" name="code" label="Código" />
                  </Col>

                  <Col md={4}>
                    <Input type="number" name="discount" label="Desconto" />
                  </Col>

                  <Col md={4}>
                    <DatePicker
                      icon={MdEvent}
                      name="expires_at"
                      label="Desconto"
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <AsyncSelect
                      name="product_ids"
                      label="Planos"
                      loadOptions={debounce(productsOptions, 500)}
                      isMulti
                    />
                  </Col>
                </Row>

                <Button
                  type="submit"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Cadastrar cupom
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default CouponRegistration;
